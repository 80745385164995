export default {
    root: {
        display: 'flex',
        flexDirection: 'column',
        background: '#E0E0E0',
        width: '100%',
        maxWidth: 'calc(100% - 40px)',
        margin: '5px auto 0px 20px',
        padding: '11px 15px',

        '& .widgetNamespace': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginBottom: '20px',

            '& .widgetNamespaceTitle': {
                display: 'flex',
                alignItems: 'center',
                fontSize: '22px',
                width: '100%',
                flexGrow: 1,
                fontWeight: 600,

                '& .widgetNamespaceLine': {
                    display: 'flex',
                    height: '1px',
                    borderBottom: '1px solid #ccc',
                    width: '95%',
                    marginLeft: '10px',
                    boxShadow: '1px 1px 0px 1px #9f9d9d',
                    borderRadius: '20px',
                },
            },

            '& .widgetNamespaceDataIntervalField': {
                display: 'flex',
                minWidth: '260px',
                alignItems: 'center',
                justifyContent: 'flex-end',

                '& .widgetFieldTitle': {
                    fontSize: '14px',
                    marginRight: '8px',
                },
            },

            '& .MuiSelect-outlined': {},
            '& .MuiOutlinedInput-root': {
                margin: '0px',
                paddingTop: '0px',
                paddingBottom: '0px',
                fontSize: '14px',
                borderRadius: '4px !important',
                height: '30px !important',
                lineHeight: '30px !important',
                border: '1px solid #acacac',
                minWidth: '150px',

                '& .MuiSvgIcon-root': {
                    marginTop: '-2px',
                },
            },

            '& .MuiFormHelperText-root': {
                display: 'none',
            },

            '& .widgetNamespaceUsageType': {
                minWidth: '185px',

                '& .MuiOutlinedInput-root': {
                    minWidth: '120px',
                },
            },
        },
    },
    widgetsWrapper: {
        display: 'grid',
        width: '100%',
        height: 'auto',
        gridTemplateColumns: 'auto auto auto auto auto auto',
        overflowX: 'auto',

        '& .appUsageWidget': {
            float: 'left',
            // marginBottom: '20px',
            marginBottom: '10px',
        },

        '& .appUsageWidget:not(:last-child)': {
            marginRight: '20px',
        },
    },
};
