import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import { logError } from '../../../utils/envUtils';
import { uploadImageToServer } from '../../../utils/fileUploadUtils';
import { USER_PROFILE_IMAGE_MAX_FILE_SIZE_KB } from '../../../config/constants';
import { showSnackBarErrorNotification } from '../../../utils/snackBarNotificationUtils';

const useStyles = makeStyles(() => ({
    imageIcon: {
        width: 20,
        height: 20,
    },
}));

const UploadUserProfileImageButton = ({ disabled = false, isSubmittingForm = false }) => {
    const classes = useStyles();

    /**
     * Upload profile image to server.
     * @param {MouseEvent}
     */
    const handleUploadProfileImage = (e) => {
        const file = e.target.files?.[0],
            fileSize = file?.size,
            fileSizeKB = fileSize ? Math.round(fileSize / 1024) : false;

        // Check if uploaded file has a content
        if (!fileSizeKB) {
            showSnackBarErrorNotification('Uploaded file is invalid');
            return;
        }

        // Check uploaded file size
        if (fileSizeKB > USER_PROFILE_IMAGE_MAX_FILE_SIZE_KB) {
            const fileSizeMB = Math.round(fileSizeKB / USER_PROFILE_IMAGE_MAX_FILE_SIZE_KB);

            showSnackBarErrorNotification(`Please upload an image file not greater than ${fileSizeMB || 1}MB`);
            return;
        }

        uploadImageToServer(e.target.files)
            .then((response) => {
                logError({ response });
            })
            .catch((err) => {
                logError(err);
            });

        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            // TODO: set profile image
            // setProfileImg(target.result);
        };
    };

    return (
        <IconButton variant="contained" component="label" color="primary" disabled={disabled || isSubmittingForm}>
            <AddAPhotoIcon className={classes.imageIcon} />
            <input type="file" hidden onChange={handleUploadProfileImage} name="profileImage" accept="image/*" />
        </IconButton>
    );
};

UploadUserProfileImageButton.propTypes = {
    disabled: PropTypes.bool,
    isSubmittingForm: PropTypes.bool,
};

export default UploadUserProfileImageButton;
