import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

const HomeIcon = (props) => {
    const fill = props.fill || '#3A70D4';

    return (
        <SvgIcon width="19" height="16" viewBox="0 0 19 16" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.8474 6.7319L10.1135 0.375734C9.8317 0.125245 9.42466 0 9.01761 0C8.57926 0 8.17221 0.125245 7.89041 0.375734L0.156556 6.7319C0.0626223 6.82583 0 6.95108 0 7.10763C0 7.23288 0.0313112 7.35812 0.0939335 7.42074L0.438356 7.82779C0.500978 7.92172 0.657534 7.98434 0.81409 7.98434C0.939335 7.98434 1.03327 7.95303 1.1272 7.89041L2.00391 7.17025V8.9863H1.9726V15.499C1.9726 15.7808 2.19178 16 2.47358 16H15.5303C15.7808 16 16.0313 15.7808 16.0313 15.499V7.17025L16.8767 7.89041C16.9706 7.95303 17.0646 8.01566 17.1898 8.01566C17.3464 8.01566 17.5029 7.92172 17.5969 7.82779L17.91 7.42074C17.9726 7.35812 18.0352 7.23288 18.0352 7.10763C18.0352 6.95108 17.9413 6.82583 17.8474 6.7319ZM14.5284 6.98239H14.4971V14.4971H11.0215V9.98826C11.0215 9.45597 10.5519 8.9863 10.0196 8.9863H8.01566C7.45205 8.9863 7.0137 9.45597 7.0137 9.98826V14.4971H3.47554V6.07436H3.50685V5.94912L9.01761 1.409L14.5284 5.94912V6.98239Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

HomeIcon.propTypes = {
    fill: PropTypes.string,
};

export default HomeIcon;
