import { useEffect } from 'react';
import Box from '@mui/material/Box';

import styles from './styles';
import LoggingContent from './LoggingContent';
import LoggingSidebar from './LoggingSidebar';
import ViewLogRequestModal from 'components/Modals/ViewLogRequestModal';
import { useSidebarMenuValue } from 'hooks/useSidebarMenuHook';
import { useFilterLogVisibility } from 'hooks/useOrganizationLoggingHook';

const Logging = () => {
    const sidebarMenu = useSidebarMenuValue(),
        isLogFilterVisible = useFilterLogVisibility();

    useEffect(() => {
        const appRootWrapper = document.querySelector('.appRoutesContainer');
        appRootWrapper.classList.add('noScrollContainer');

        return () => {
            appRootWrapper.classList.remove('noScrollContainer');
        };
    }, []);

    return (
        <>
            <Box sx={styles.root({ isLogFilterVisible })}>
                <div className={`loggingWrapper${sidebarMenu?.collapseMenu ? ' loggingWrapperExpanded' : ''}`}>
                    <LoggingSidebar />
                    <LoggingContent />
                </div>
            </Box>

            <ViewLogRequestModal />
        </>
    );
};

export default Logging;
