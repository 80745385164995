import { setStoreState, useStoreValue } from './useStoreStateHook';
import { getAppUsageAggregate } from 'utils/appUsageUtils';

export const appUsageDefaultState = {
    appUsage: {
        isLoaded: false,
        avgRunTime: 0,
        successRate: 0,
        failureRate: 0,
        totalRequest: 0,
        avgRequestSize: 0,
        avgResponseSize: 0,
        totalSuccessRequest: 0,
        totalFailureRequest: 0,
    },
};

/**
 * Set app usage state
 * @param {{ isLoaded: boolean, totalRequest: number, totalSuccessRequest: number, totalFailureRequest: number, successRate: number, failureRate: number, avgRunTime: number,avgRequestSize: number, avgResponseSize: number, appState: {usageType: string, dataInterval: string} }} state
 */
export const setAppUsageData = (state) => {
    setStoreState('appUsage')(state);
};

/**
 * Get app usage state and subscribe to changes
 * @return {{ isLoaded: boolean, totalRequest: number, totalSuccessRequest: number, totalFailureRequest: number, successRate: number, failureRate: number, avgRunTime: number,avgRequestSize: number, avgResponseSize: number, appState: {usageType: string, dataInterval: string} }}
 */
export const useAppUsageValue = () => {
    return useStoreValue('appUsage')(appUsageDefaultState.appUsage);
};

/**
 * Evaluate app usage data
 * @param {object} data
 * @return {{ totalRequest: number, totalSuccessRequest: number, totalFailureRequest: number, successRate: number, failureRate: number, avgRunTime: number,avgRequestSize: number, avgResponseSize: number, appState: {usageType: string, dataInterval: string}  }}
 */
export const evaluateAppUsageData = (data = {}) => {
    return {
        avgRunTime: 0,
        successRate: 0,
        failureRate: 0,
        totalRequest: 0,
        avgRequestSize: 0,
        avgResponseSize: 0,
        totalSuccessRequest: 0,
        totalFailureRequest: 0,
        ...data,
    };
};

/**
 * Send app usage requests
 * @param {{ type: string, organizationId: string, startDate: undefined|string, endDate: string }} args
 * @return {{ isLoaded: boolean, totalRequest: number, totalSuccessRequest: number, totalFailureRequest: number, successRate: number, failureRate: number, avgRunTime: number,avgRequestSize: number, avgResponseSize: number, appState: {usageType: string, dataInterval: string} }}
 */
export const setupAppUsageData = async ({ type, startDate, endDate, organizationId, appState = {} } = {}) => {
    getAppUsageAggregate({
        type,
        endDate,
        startDate,
        organizationId,
        successCallback: (res) => {
            setAppUsageData({
                appState,
                isLoaded: true,
                ...evaluateAppUsageData(res.data || {}),
            });
        },
        errorCallback: () => {
            setAppUsageData({
                appState,
                isLoaded: true,
                ...evaluateAppUsageData(),
            });
        },
    });
};
