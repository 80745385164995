const dataStyle = {
    fontSize: '13px',
    lineHeight: '15px',
    color: '#333',
    wordBreak: 'break-word',
};

export const headingStyle = {
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '15px',
    textTransform: 'capitalize',
    color: '#333',
    marginBottom: '5px',
};

export default {
    paperRoot: {
        width: '100%',
        borderRadius: '5px',

        '& .MuiPaper-root': {
            maxWidth: '800px',
            width: '100%',
            // borderRadius: '5px',
        },

        '& .requestItem': {
            width: '100%',
            height: 'auto',
            margin: '0px auto 40px auto',

            '& div[name=container]': {
                borderRadius: '3px',
            },
        },

        '& .requestItemResponse': {
            marginTop: '20px',
        },
    },
    gridContainerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
    },
    contentWrapper: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        padding: '20px 0px 5px 0px',
        marginLeft: '10px',
    },
    section: {
        marginTop: '15px',
        width: '100%',
    },
    statusCode: {
        display: 'flex',
        fontSize: '13px',
        marginBottom: '15px',
        marginTop: '-15px',
        fontWeight: 600,

        '& .responseStatusText': {
            marginLeft: '10px',
            display: 'flex',
            alignItems: 'center',
            color: '#fff',
            padding: '2px 5px',
            borderRadius: '4px',
            fontSize: '12px',
        },

        '& .responseStatusTextError': {
            backgroundColor: '#d54551',
        },
        '& .responseStatusTextSuccess': {
            backgroundColor: '#139a48',
        },
    },
    gridContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        borderRadius: '3px',
        border: '1px solid #E0E0E0',
        margin: 0,
        paddingBottom: '20px',
    },
    heading: headingStyle,
    headingQueryParams: {
        ...headingStyle,
        margin: '-15px 0px 10px 0px',
        display: 'flex',
        alignItems: 'center',
    },
    data: dataStyle,
    gridItem: {
        display: 'flex',
        flex: 0.5,
        flexDirection: 'column',
    },
    descriptionWrapper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: '100%',
        padding: '0px 15px',
        marginBottom: '20px',
    },
    jsonInput: {
        borderRadius: '3px',
    },
    intelyToken: {
        ...dataStyle,
        height: '100px',
        textOverflow: 'wrap',
        overflow: 'auto',
        width: '100%',
        display: 'flex',
        overflowWrap: 'wrap',
    },
    processLog: {
        ...dataStyle,
        height: '150px',
        textOverflow: 'wrap',
        overflow: 'auto',
        width: '100%',
        display: 'flex',
        overflowWrap: 'wrap',
    },
    copyResponseBtn: {
        border: '1px solid rgb(58, 112, 212)',
    },
    closeModalBtn: {
        color: 'rgb(58, 112, 212) !important',
        border: '1px solid rgb(58, 112, 212)',
    },
};
