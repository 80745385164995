import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const NoTableDataContent = ({
    text,
    color = '#888',
    fontSize = '13px',
    borderTop = 0,
    border = true,
    margin = '0',
    height = '6vh',
    rootStyle = {},
    textStyle = {},
    borderColor = '#E0E0E0',
    alignItems = 'center',
    justifyContent = 'center',
}) => {
    const rootStyles = {
            height,
            margin,
            alignItems,
            justifyContent,
            display: 'flex',
            width: '100%',
            ...rootStyle,
        },
        textStyles = {
            color,
            fontSize,
            display: 'flex',
            ...textStyle,
        };

    if (border) {
        rootStyles.borderWidth = `${borderTop ? '1px' : '0px'} 1px 1px 1px`;
        rootStyles.borderColor = borderColor;
        rootStyles.borderStyle = 'solid';
    }

    return (
        <div style={rootStyles}>
            <Typography component="div" style={textStyles}>
                {text}
            </Typography>
        </div>
    );
};

NoTableDataContent.propTypes = {
    text: PropTypes.string.isRequired,
    height: PropTypes.number,
    border: PropTypes.bool,
    borderTop: PropTypes.bool,
    rootStyle: PropTypes.object,
    textStyle: PropTypes.object,
    color: PropTypes.string,
    margin: PropTypes.string,
    fontSize: PropTypes.string,
    borderColor: PropTypes.string,
    alignItems: PropTypes.string,
    justifyContent: PropTypes.string,
};

export default NoTableDataContent;
