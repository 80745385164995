export default {
    root: {
        width: '183px',
        minHeight: '160px',
        display: 'flex',
        flexDirection: 'column',
        padding: '25px 30px',
        border: '1px solid #E0E0E0',
        backgroundColor: '#fff',
        borderRadius: '4px',
        alignSelf: 'stretch',
        filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15))',

        '& .widgetIcon': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '13px',
            width: '30px',
            height: '30px',
            backgroundColor: '#F2F2F2',
            borderRadius: '100%',
        },
        '& .widgetTitle': {
            display: 'flex',
            marginBottom: '7px',
            color: '#BDBDBD',
            fontSize: '13px',
        },
        '& .widgetValue': {
            display: 'flex',
            fontSize: '24px',
            fontWeight: 600,
            marginBottom: '4px',
        },
        '& .widgetDescription': {
            display: 'flex',
            fontSize: '13px',
            backgroundColor: '#fff',
            color: '#000',
        },
        '& .widgetDescriptionSuccess': {
            color: '#6FCF97',
        },
        '& .widgetDescriptionError': {
            color: '#EB5757',
        },
    },
    loader: {
        marginTop: '15px',
        width: '30px',
        height: '30px',
    },
};
