import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import InputLabelMui from '@mui/material/InputLabel';

export const RequiredLabelFlag = ({ requiredLabel = ' *', ...props }) => {
    const requiredStyle = {
        top: '2px',
        color: 'red',
        lineHeight: 'inherit',
        position: 'relative',
        marginLeft: '2px',
    };
    return (
        <Typography sx={requiredStyle} variant="subtitle1" component="span" {...props}>
            {requiredLabel}
        </Typography>
    );
};

RequiredLabelFlag.propTypes = {
    requiredLabel: PropTypes.node,
};

const InputLabel = ({ inputId, required = false, requiredLabel, label, sx }) => {
    if (!label) return null;
    const rootStyle = {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
    };

    return (
        <InputLabelMui sx={sx} htmlFor={inputId} style={rootStyle}>
            {label}
            {required && requiredLabel && <RequiredLabelFlag requiredLabel={requiredLabel} />}
        </InputLabelMui>
    );
};

InputLabel.propTypes = {
    label: PropTypes.node,
    inputId: PropTypes.string,
    required: PropTypes.bool,
    requiredLabel: PropTypes.node,
    sx: PropTypes.object,
};

export default InputLabel;
