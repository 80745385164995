import { ThreeDots } from 'react-loader-spinner';
import PropTypes from 'prop-types';

const RequestLoader = (props) => {
    const style = {
        height: 'inherit',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };
    return (
        <ThreeDots
            ariaLabel="loading-indicator"
            style={style}
            type="MutatingDots"
            color="#3a70d4"
            secondaryColor="#85C446"
            height={100}
            width={100}
            {...props}
        />
    );
};

export const RequestLoaderPropTypes = {
    type: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string,
    strokeWidth: PropTypes.number,
    secondaryColor: PropTypes.string,
};

RequestLoader.propTypes = RequestLoaderPropTypes;

export default RequestLoader;
