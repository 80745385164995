import SvgIcon from '@mui/material/SvgIcon';

const FolderIcon = (props) => {
    return (
        <SvgIcon width="18" height="19" viewBox="0 0 18 19" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.64375 15.5C2.34375 15.5 2.08125 15.3844 1.85625 15.1531C1.63125 14.9219 1.51875 14.6625 1.51875 14.375V4.625C1.51875 4.3375 1.63125 4.07813 1.85625 3.84688C2.08125 3.61563 2.34375 3.5 2.64375 3.5H7.89375L9.01875 4.625H15.3938C15.6813 4.625 15.9406 4.74063 16.1719 4.97188C16.4031 5.20313 16.5188 5.4625 16.5188 5.75V14.375C16.5188 14.6625 16.4031 14.9219 16.1719 15.1531C15.9406 15.3844 15.6813 15.5 15.3938 15.5H2.64375Z"
                fill="#FFB518"
            />
        </SvgIcon>
    );
};

export default FolderIcon;
