import PrimaryButton from 'components/Buttons/PrimaryButton';
import FilterButton from 'components/Buttons/FilterButton';
import SearchBar from 'components/SearchBar';
import CustomBreadCrumbs from 'components/Breadcrumbs/CustomBreadcrumbs';
import Filter from './Filter';

import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

import styles from './styles';

import {
    openFileUploadModal,
    openNewFolderModal,
    refreshSelectedFolder,
    setSelectedFolderAndGetFiles,
} from 'hooks/useMyFilesHook';

import {
    toggleFilter,
    useFilterState,
    useIntelyStorageSelectedFolder,
    resetFilteredFiles,
    filterFilesBySearchTerm,
    setFileFilters,
    useFileFilters,
} from 'hooks/useMyFilesHook';

const Header = () => {
    const selectedFolder = useIntelyStorageSelectedFolder();
    const isFilterOpen = useFilterState();
    const fileFilters = useFileFilters();

    const resetSearch = () => {
        setFileFilters('searchTerm', '');
        resetFilteredFiles();
    };

    const breadcrumbItems = selectedFolder.path === '/' ? [] : selectedFolder.path.split('/');
    const handleBreadcrumbClick = (_, index) => {
        const path = breadcrumbItems.slice(0, index + 1).join('/');
        if (path !== selectedFolder.path) {
            setSelectedFolderAndGetFiles(path);
        }
    };

    return (
        <div style={styles.headerRootContainer}>
            <div style={styles.headerContainer}>
                <div style={{ flexGrow: 1 }}>
                    <CustomBreadCrumbs items={breadcrumbItems} separator="/" onClick={handleBreadcrumbClick} />
                </div>
                <PrimaryButton
                    height="32px"
                    width="auto"
                    fontSize="14px"
                    fontWeight={400}
                    padding="0px 10px"
                    borderRadius="3px"
                    backgroundColor="#3A71D5"
                    onClick={refreshSelectedFolder}
                >
                    <RefreshIcon />
                </PrimaryButton>
                <PrimaryButton
                    height="32px"
                    width="auto"
                    fontSize="14px"
                    fontWeight={400}
                    padding="0px 10px"
                    borderRadius="3px"
                    backgroundColor="#3A71D5"
                    onClick={openNewFolderModal}
                >
                    <CreateNewFolderIcon />
                </PrimaryButton>
                <PrimaryButton
                    height="32px"
                    width="auto"
                    fontSize="14px"
                    fontWeight={400}
                    padding="0px 10px"
                    borderRadius="3px"
                    backgroundColor="#3A71D5"
                    onClick={openFileUploadModal}
                >
                    <FileUploadIcon />
                </PrimaryButton>
                <SearchBar
                    value={fileFilters.searchTerm}
                    onChange={(value) => setFileFilters('searchTerm', value)}
                    onEnter={filterFilesBySearchTerm}
                    onSearch={filterFilesBySearchTerm}
                    endIcon={fileFilters.searchTerm.length > 0 ? <CloseIcon style={{ fontSize: '20px' }} /> : null}
                    endIconAction={resetSearch}
                />
                <PrimaryButton
                    height="32px"
                    width="auto"
                    fontSize="14px"
                    fontWeight={400}
                    padding="0px 10px"
                    borderRadius="3px"
                    backgroundColor="#3A71D5"
                    onClick={filterFilesBySearchTerm}
                >
                    Search
                </PrimaryButton>
                <FilterButton
                    onClick={toggleFilter}
                    isButtonActive={isFilterOpen}
                    styleProps={{ alignSelf: 'flex-end' }}
                />
            </div>
            {isFilterOpen && <Filter />}
        </div>
    );
};

export default Header;
