import PropTypes from 'prop-types';
import MuiLoadingButton from '@mui/lab/LoadingButton';

const LoadingButton = (props) => {
    const { label, loading = false, onClick, buttonProps = {} } = props,
        style = { mb: 2, height: '40px', minWidth: 180 };

    return (
        <MuiLoadingButton
            type="submit"
            variant="contained"
            sx={style}
            className="secondary"
            onClick={onClick}
            loading={loading}
            {...buttonProps}
        >
            {label}
        </MuiLoadingButton>
    );
};

LoadingButton.propTypes = {
    label: PropTypes.node.isRequired,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    buttonProps: PropTypes.object,
};

export default LoadingButton;
