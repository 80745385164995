export const convertScopeText = (text) => {
    switch (text) {
        case 'org.read':
            return 'Read Organization Data';
        case 'app.read':
            return 'Read App Instances';
        case 'app.execute':
            return 'Execute App Requests';
        case 'integration.read':
            return 'Read Integrations';
        case 'integration.execute':
            return 'Execute Integrations';
        default:
            return null;
    }
};
