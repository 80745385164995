import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import PrimaryButton from 'components/Buttons/PrimaryButton';

import styles from './styles';

const FilterButton = ({ onClick: handleOnClick, isButtonActive, styleProps }) => {
    return (
        <PrimaryButton
            height="32px"
            width="auto"
            fontSize="14px"
            fontWeight={400}
            padding="0px 10px"
            borderRadius="3px"
            sx={styles.root(isButtonActive)}
            onClick={handleOnClick}
            style={styleProps}
        >
            <span>Filter</span>
            <ExpandMoreIcon />
        </PrimaryButton>
    );
};

FilterButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    isButtonActive: PropTypes.bool.isRequired,
    styleProps: PropTypes.object,
};

export default FilterButton;
