export default {
    root: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    headingWrapper: {
        flexGrow: 1,
    },
    heading: {
        margin: '18px 0px',
        fontFamily: 'Roboto',
        fontSize: '18px',
        lineHeight: '21px',
    },
    createMappingBtn: {
        width: 'auto',
        height: '38px',
        padding: '8px 18px',
        borderRadius: '5px',
        backgroundColor: '#3A70D4',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '16px',
        textTransform: 'capitalize',
        color: '#fff',
    },
    infoBtn: {
        borderRadius: '5px',
        backgroundColor: '#F2F2F2',
        width: 'auto',
        height: '32px',
        marginLeft: '8px',
    },
};
