import { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';

import * as constants from 'config/constants';
import { getStoreState, setStoreState, useStoreState, useStoreValue } from './useStoreStateHook';
import { getCookie, setCookie } from 'utils/cookiesUtils';

export const sidebarMenuDefaultState = {
    width: constants.SIDEBAR_WIDTH,
    collapseMenu: false,
    collapseMenuState: undefined,
};

/**
 * Set sidebar menu state
 * @param {{ collapseMenu: true|false, width: number, collapseMenuState: undefined|false|true }} state
 */
export const setSidebarMenu = (state) => {
    setStoreState('sidebarMenu')(state);
};

/**
 * Get sidebar menu state and subscribe to changes
 * @returns {{ collapseMenu: true|false, width: number, collapseMenuState: undefined|false|true  }} state
 */
export const useSidebarMenuValue = () => {
    return useStoreValue('sidebarMenu')(sidebarMenuDefaultState);
};

/**
 * Get sidebar menu state
 * @returns {{ collapseMenu: true|false, width: number, collapseMenuState: undefined|false|true  }} state
 */
export const getSidebarMenuState = () => getStoreState('sidebarMenu')(sidebarMenuDefaultState);

/**
 * Is menu state cache collapsed
 * @return {Bool}
 */
export const isMenuStateCacheCollapsed = () => getCookie('collapseMenu') === '1';

/**
 * Set the menu state cache
 * @param {Bool} isCollapsed
 */
export const setMenuStateCache = (isCollapsed) => {
    setCookie({
        key: 'collapseMenu',
        value: isCollapsed ? '1' : '0',
        expires: '31 Dec, 2038',
    });
};

/**
 * Sidebar menu state hook
 * @returns {{ state: { collapseMenu: true|false, width: number, collapseMenuState: undefined|false|true  }, setSidebarMenu: (state: { collapseMenu: true|false, width: number, collapseMenuState: undefined|false|true  }) => {}}}
 */
export const useSidebarMenu = () => {
    const collapseMenu = useMediaQuery((theme) => theme.breakpoints.down('collapseMenu')),
        [sidebarMenu, setSidebarMenuState] = useStoreState('sidebarMenu')(sidebarMenuDefaultState),
        collapseMenuState = sidebarMenu.collapseMenuState;

    useEffect(() => {
        const isMenuCollapsedUnset = collapseMenuState === undefined,
            isMenuCollapsed = isMenuCollapsedUnset ? collapseMenu : collapseMenuState;

        const menuStates = {
            ...sidebarMenu,
            width: isMenuCollapsed ? constants.SIDEBAR_COLLAPSE_WIDTH : constants.SIDEBAR_WIDTH,
            collapseMenu: isMenuCollapsed,
        };

        if (isMenuCollapsedUnset) {
            menuStates.collapseMenuState = collapseMenu;
        }

        setSidebarMenuState(menuStates);
    }, [collapseMenu, collapseMenuState]);

    useEffect(() => {
        let menuStates;
        const collapsedMenuState = {
            width: constants.SIDEBAR_COLLAPSE_WIDTH,
            collapseMenu: true,
            collapseMenuState: true,
        };

        // Look if menu state cache is set
        if (isMenuStateCacheCollapsed()) {
            menuStates = { ...sidebarMenu, ...collapsedMenuState };
        } else {
            menuStates = {
                ...sidebarMenu,
                collapseMenu: false,
                collapseMenuState: false,
                width: constants.SIDEBAR_WIDTH,
            };
        }

        setSidebarMenuState(menuStates);
    }, []);

    return { state: sidebarMenu, setSidebarMenu: setSidebarMenuState };
};

export default useSidebarMenu;
