import OrganizationUserNameColumn from './OrganizationUserNameColumn';
import OrganizationUserAccessColumn from './OrganizationUserAccessColumn';
import OrganizationUserTitleColumn from './OrganizationUserTitleColumn';
import OrganizationUserEmailColumn from './OrganizationUserEmailColumn';
import OrganizationUserStatusColumn from './OrganizationUserStatusColumn';
import OrganizationUserTableDropdownActions from '../OrganizationUsersTableDropdownActions';
import useSelectedOrganization from 'hooks/useSelectedOrganizationHook';

/**
 * Organization user table columns
 * @returns {Array<React.Element>} List of organization user table columns
 */
const OrganizationUsersTableColumns = () => {
    const renderOrganizationUserName = (row) => <OrganizationUserNameColumn organizationUser={row} />;

    const renderOrganizationUserAccess = (row) => <OrganizationUserAccessColumn organizationUser={row} />;

    const renderOrganizationUserTitle = (row) => <OrganizationUserTitleColumn organizationUser={row} />;

    const renderOrganizationUserEmail = (row) => <OrganizationUserEmailColumn organizationUser={row} />;

    const renderOrganizationUserStatus = (row) => <OrganizationUserStatusColumn organizationUser={row} />;

    const renderOrganizationUserTableActions = (row) => <OrganizationUserTableDropdownActions organizationUser={row} />;
    const [currentSelectedOrganization] = useSelectedOrganization();

    let actionColumn = null;
    if (currentSelectedOrganization.isAdmin) {
        actionColumn = {
            name: 'Actions',
            cell: renderOrganizationUserTableActions,
            button: true,
            sortable: false,
            allowOverflow: true,
            ignoreRowClick: true,
            width: '200px',
        };
    }

    return [
        {
            name: 'Name',
            grow: 3,
            cell: renderOrganizationUserName,
            selector: (row) => row.firstName || '',
            sortable: true,
        },
        {
            name: 'Email',
            cell: renderOrganizationUserEmail,
            sortable: true,
            width: '300px',
            selector: (row) => row.email || '',
        },
        {
            name: 'User Access',
            grow: 3,
            cell: renderOrganizationUserAccess,
            sortable: true,
            selector: (row) => row.isAdmin || '',
        },
        {
            name: 'Job Title',
            grow: 3,
            cell: renderOrganizationUserTitle,
            sortable: true,
            selector: (row) => row.jobTitle || '',
        },
        {
            name: 'Status',
            cell: renderOrganizationUserStatus,
            sortable: true,
            width: '150px',
            selector: (row) => row.activatedAt || '',
        },
        actionColumn,
    ];
};

export default OrganizationUsersTableColumns;
