import PropTypes from 'prop-types';

import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';

const CustomBreadCrumbs = (props) => {
    const { items, separator, textProps, onClick, ...other } = props;

    const breadcrumbs = items.map((item, index) => {
        const handleOnClick = () => {
            onClick && onClick(item, index);
        };

        return (
            <Link
                key={index}
                sx={{ fontSize: '14px', color: 'black', cursor: 'pointer' }}
                {...textProps}
                onClick={handleOnClick}
            >
                {item}
            </Link>
        );
    });

    if (breadcrumbs.length < 1) {
        return null;
    } else {
        return (
            <Breadcrumbs separator={separator} {...other}>
                {breadcrumbs}
            </Breadcrumbs>
        );
    }
};

CustomBreadCrumbs.propTypes = {
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
    separator: PropTypes.string,
    textProps: PropTypes.object,
    onClick: PropTypes.func,
};

export default CustomBreadCrumbs;
