import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

const PrimaryButton = ({
    children,
    sx = {},
    style = {},
    fontWeight = 400,
    width = 'auto',
    color = '#fff',
    height = '32px',
    margin = '0px',
    padding = '8px 25px',
    fontSize = '14px',
    borderRadius = '5px',
    textTransform = 'capitalize',
    backgroundColor = '#3A70D4',
    ...props
}) => {
    const buttonStyle = {
            color,
            width,
            height,
            margin,
            padding,
            fontSize,
            fontWeight,
            borderRadius,
            textTransform,
            backgroundColor,
            ...style,
        },
        styleStates = {
            ...sx,
            '&:disabled': {
                opacity: 0.7,
            },
        };

    return (
        <Button {...props} style={buttonStyle} sx={styleStates}>
            {children}
        </Button>
    );
};

PrimaryButton.propTypes = {
    sx: PropTypes.object,
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    margin: PropTypes.string,
    padding: PropTypes.string,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.number,
    borderRadius: PropTypes.string,
    textTransform: PropTypes.string,
    backgroundColor: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node.isRequired,
};

export default PrimaryButton;
