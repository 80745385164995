import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const ConfirmDeleteAccessTokenModal = (props) => {
    const { open, handleCancelAction, handleApprovalAction } = props;

    return (
        <Dialog
            keepMounted
            open={open}
            onClose={handleCancelAction}
            aria-labelledby="delete-access-token-alert-dialog"
            aria-describedby="delete-access-token-alert-dialog"
        >
            <DialogTitle>Delete Token</DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    This action is permanent and cannot be undone. <br />
                    Deleting this token will revoke all access to this token. <br />
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={handleCancelAction}>
                    Cancel
                </Button>
                <Button variant="contained" color="secondary" onClick={handleApprovalAction}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConfirmDeleteAccessTokenModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleCancelAction: PropTypes.func.isRequired,
    handleApprovalAction: PropTypes.func.isRequired,
};

export default ConfirmDeleteAccessTokenModal;
