export default {
    dialogRoot: (width, height) => ({
        '& .MuiBackdrop-root': {
            background: 'rgba(22, 28, 36, 0.6)',
        },

        '& .MuiPaper-root': {
            maxWidth: `${width}px`,
            height: height ? `${height}px` : 'auto',
        },

        '& .MuiDialogContent-root': {
            overflowY: 'hidden',
        },
    }),
    input: {
        '& .MuiInputBase-input': {
            backgroundColor: 'white !important',
            fontSize: '14px',
            padding: '10px',
        },
    },
    title: {
        fontSize: '14px',
        fontWeight: '500',
    },
    inputLabel: (isError) => ({
        fontSize: '14px',
        marginBottom: '8px',
        color: isError ? 'red' : 'black',
    }),
    newFolderButton: {
        border: '1px solid #3A70D4',
        marginLeft: 'auto',
        padding: '4px',
    },
    submit: {
        backgroundColor: '#3A70D4',
        color: 'white',

        '&:hover': {
            backgroundColor: '#6490e1',
        },
    },
    cancel: {
        backgroundColor: '#BDBDBD',
        color: 'white',

        '&:hover': {
            backgroundColor: '#cecdcd',
        },
    },
};
