import { useState, useEffect, memo } from 'react';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';

import { inputRadioButtonValueType } from '../../../utils/typeUtils';
import { useInputFieldRef, useInputFieldDefaultValue } from '../../../hooks/useInputFieldHook';

const RadioButtonField = (props) => {
    const {
        value,
        inputRef,
        defaultValue,
        name = '',
        label = '',
        disabled = false,
        required = false,
        gridProps = {},
        inputProps = {},
        handleChange = undefined,
        valueUnChecked = '',

        /**
         * A higher order component that implement the control props for MUI
         * FormControlLabel component.
         * @param {object} args
         * @param {object} args.props Props pass to this component
         * @param {boolean} args.isChecked Specify input field checked state
         * @param {React.MutableRefObject} args.inputRef Input field ref
         * @param {function} args.handleChange On click event handler
         */
        control = null,
    } = props;

    const inputFieldRef = useInputFieldRef(inputRef),
        formControlLabelStyle = { fontSize: '12px' },
        [radioFieldValue, setRadioFieldValue] = useState(defaultValue),
        isChecked = value === radioFieldValue;

    // Keep field value in sync if default value is changed in props
    useInputFieldDefaultValue(defaultValue, inputFieldRef);

    // Ensure the radio field is checked correctly
    useEffect(() => {
        setRadioFieldValue(defaultValue);
    }, [defaultValue]);

    /**
     * Handle field value change.
     * @param {MouseEvent} e
     */
    const handleFieldValueChange = (e) => {
        setRadioFieldValue(radioFieldValue === value ? valueUnChecked : value);

        // Fire click event handler if defined
        handleChange && handleChange(e, { inputFieldRef });
    };

    const renderRadioButton = control ? (
        control({ props, inputRef: inputFieldRef, isChecked, handleChange: handleFieldValueChange })
    ) : (
        <Radio name={name} required={required} onClick={handleFieldValueChange} />
    );

    return (
        <Grid item xs={12} sm={6} {...gridProps}>
            <FormControlLabel
                sx={formControlLabelStyle}
                label={label}
                value={radioFieldValue}
                control={renderRadioButton}
                checked={isChecked}
                disabled={disabled}
                inputRef={inputFieldRef}
                {...inputProps}
            />
        </Grid>
    );
};

RadioButtonField.propTypes = {
    name: PropTypes.string,
    value: inputRadioButtonValueType,
    label: PropTypes.node,
    control: PropTypes.func,
    checked: PropTypes.bool,
    required: PropTypes.bool,
    inputRef: PropTypes.object,
    disabled: PropTypes.bool,
    gridProps: PropTypes.object,
    inputProps: PropTypes.object,
    handleChange: PropTypes.func,
    defaultValue: inputRadioButtonValueType,
    valueUnChecked: inputRadioButtonValueType,
    reRenderCounter: PropTypes.number,
};

export default memo(RadioButtonField);
