import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import CustomBootstrapDialog from '../BootstrapModal';
import { BootstrapModalTitle } from '../BootstrapModal';
import LastNameField from 'components/InputFields/LastNameField';
import FirstNameField from 'components/InputFields/FirstNameField';
import CompanyNameField from 'components/InputFields/CompanyNameField';
import PhoneNumberField from 'components/InputFields/PhoneNumberField';
import EmailAddressField from 'components/InputFields/EmailAddressField';
import CompanyPositionField from 'components/InputFields/CompanyPositionField';

import { useConfirmUserDetailChangesModal } from 'hooks/useModalHook';

const ConfirmUserDetailChangesModal = () => {
    const modalSettings = useConfirmUserDetailChangesModal(),
        isOpen = modalSettings?.open || false,
        userDetails = modalSettings?.userDetails || {},
        onConfirm = modalSettings?.onConfirm || (() => {}),
        onClose = modalSettings?.onClose || (() => {});

    const titleStyle = {
            fontSize: '16px',
            fontWeight: '500',
        },
        bodyTextStyle = {
            fontSize: '14px',
            marginBottom: '20px',
        },
        buttonStyle = (type) => ({
            background: type === 'cancel' ? '#C4C4C4' : '#3A70D4',
            color: type === 'cancel' ? 'black' : 'white',
            '&:hover': {
                backgroundColor: type === 'cancel' ? '#C4C4C4' : '#3A70D4',
            },
            fontWeight: 700,
            padding: '6px 16px',
        });

    return (
        <CustomBootstrapDialog open={isOpen}>
            <BootstrapModalTitle sx={titleStyle} onClose={onClose}>
                Confirm User Information
            </BootstrapModalTitle>
            <DialogContent>
                <Typography style={bodyTextStyle}>
                    Please confirm the information provided on the previous screen. If anything appears incorrect,
                    please select &apos;Cancel&apos; to return and update the information. If the information is
                    correct, select &apos;Save&apos;.
                </Typography>
                <Grid container justifyContent="center" spacing={2}>
                    <FirstNameField disabled={true} value={userDetails?.firstName || ''} required={false} />
                    <LastNameField disabled={true} value={userDetails?.lastName || ''} required={false} />
                    <EmailAddressField disabled={true} value={userDetails?.email || ''} required={false} />
                    <PhoneNumberField disabled={true} value={userDetails?.phoneNumber || ''} required={false} />
                    <CompanyNameField disabled={true} value={userDetails?.organizationName || ''} required={false} />
                    <CompanyPositionField disabled={true} value={userDetails?.jobTitle || ''} required={false} />
                </Grid>
            </DialogContent>
            <DialogActions style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Button style={buttonStyle('cancel')} onClick={onClose}>
                    Cancel
                </Button>
                <Button style={buttonStyle('save')} onClick={onConfirm}>
                    Save
                </Button>
            </DialogActions>
        </CustomBootstrapDialog>
    );
};

export default ConfirmUserDetailChangesModal;
