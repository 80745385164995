import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers';

import SelectField from 'components/InputFields/SelectField';
import PrimaryButton from 'components/Buttons/PrimaryButton';

import styles from './styles';

import { getOrganizationUsersData } from 'hooks/useOrganizationUsersHook';
import { useFileFilters, setFileFilters, resetFileFilters, applyFileFilters } from 'hooks/useMyFilesHook';

const Filter = () => {
    const fileFilters = useFileFilters();

    const organizationUsers = getOrganizationUsersData().map((user) => ({
        label: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
        value: user.userId,
    }));

    const handleFilterFieldChange = (state) => (value) => {
        const valueToSet = state === 'modifiedBy' ? value.target.value : value;
        setFileFilters(state, valueToSet);
        setFileFilters('applied', false);
    };

    const renderDateInput = (props) => <TextField {...props} />;

    return (
        <Box sx={styles.filterRoot}>
            <div className="filterHeader">
                <Typography className="filterTitle">Filter</Typography>
                <span className="filterLine" />
                <PrimaryButton
                    height="32px"
                    width="auto"
                    fontSize="14px"
                    fontWeight={400}
                    padding="0px 10px"
                    borderRadius="3px"
                    backgroundColor="#3A71D5"
                    onClick={applyFileFilters}
                >
                    Apply Filters
                </PrimaryButton>
            </div>
            <div className="filterSelectors">
                <div className="selectContainer">
                    <Typography className="label">Modified Since</Typography>
                    <div>
                        <DatePicker
                            disableFuture={true}
                            renderInput={renderDateInput}
                            value={fileFilters.modifiedDate}
                            onChange={handleFilterFieldChange('modifiedDate')}
                        />
                    </div>
                </div>

                <div className="selectContainer">
                    <Typography className="label">Modified By</Typography>
                    <SelectField
                        menuWidth={220}
                        name="modifiedBy"
                        label={null}
                        value={fileFilters.modifiedBy}
                        placeholder="Select Modified By"
                        renderWithGrid={false}
                        required={false}
                        items={organizationUsers}
                        onChange={handleFilterFieldChange('modifiedBy')}
                    />
                </div>

                {fileFilters.applied && (
                    <PrimaryButton
                        height="32px"
                        width="auto"
                        fontSize="14px"
                        fontWeight={400}
                        padding="0px 10px"
                        borderRadius="3px"
                        backgroundColor="#ff6464"
                        onClick={resetFileFilters}
                        style={{ marginTop: 'auto', marginBottom: '20px' }}
                    >
                        Clear Filters
                    </PrimaryButton>
                )}
            </div>
        </Box>
    );
};

export default Filter;
