export default {
    root({ isLogFilterVisible }) {
        return {
            display: 'flex',
            width: '100%',
            minHeight: '100%',
            height: 'auto',

            '& input::placeholder': {
                padding: '0px !important',
                color: '#999999a8 !important',
            },
            '& select::placeholder': {
                color: '#999999a8 !important',
            },

            '& .searchInputWrapper': {
                display: 'flex',
                width: '100%',
                alignItems: 'center',

                '& .clearSearchBtn': {
                    border: '1px solid #3a70d4',
                },
            },

            '& .loggingWrapper': {
                display: 'flex',
                width: '100%',
                minHeight: '100%',
                // height: 'auto',
                border: '1px solid #E0E0E0',

                overflow: 'auto',
                height: '77vh',
            },

            [isLogFilterVisible && '& .reactDataTable']: {
                height: 'calc(63vh - 120px) !important',
            },

            '& .rdt_TableBody': {
                minWidth: '1100px',
            },

            '& .loggingWrapperExpanded': {
                marginRight: '70px',
            },

            '& .loggingSidebar': {
                width: '138px',
                minWidth: '138px',
                minHeight: '100%',
                // borderRight: '1px solid #E0E0E0',
                padding: '14px',

                '& .loggingSidebarBtn': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    transition: 'all 300ms',

                    '&:hover': {
                        backgroundColor: '#F2F2F2 !important',
                        border: '1px solid #eee',
                        color: '#888 !important',
                    },
                },

                '& .loggingSidebarBtnActive': {
                    color: '#3A70D4 !important',

                    '&:hover': {
                        color: '#3A70D4 !important',
                    },
                },
            },

            '& .loggingContent': {
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minHeight: '100%',

                //
                // marginLeft: '138px',
                overflowX: 'auto',
                overflowY: 'hidden',
                borderLeft: '1px solid #E0E0E0',
            },

            '& .logContentHeader': {
                display: 'flex',
                width: '100%',
                height: 'auto',
                minHeight: '60px',
                alignItems: 'center',
                padding: '0px 20px',
            },

            '& .logContentHeaderTitle': {
                flexGrow: 1,
                display: 'flex',
                fontSize: '16px',
                fontFamily: 'Roboto',
                fontWeight: 600,
            },

            '& .logContentHeaderSearchBar': {
                display: 'flex',
                alignItems: 'center',
            },

            '& .filterLogContent': {
                display: 'none',
                flexDirection: 'column',
                margin: '-5px auto 5px auto',
                width: '100%',
                padding: '0px 20px',
            },

            '& .filterLogContentVisible': {
                display: 'flex',
            },

            '& .filterLogBtnVisible': {
                color: '#fff !important',
                backgroundColor: '#3A71D5 !important',
            },

            '& .filterLogTitle': {
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                fontSize: '13px',
                fontWeight: 600,

                '& button:not(.canApplyFiltersDisabled)': {
                    border: '1px solid #3A71D5',
                },
                '& .canApplyFiltersDisabled': {
                    border: '1px solid #ccc',
                },

                '& .filterLogTitleInner': {
                    display: 'flex',
                    alignItems: 'center',
                    flexGrow: 1,
                },

                '& .filterLogTitleLine': {
                    marginLeft: '15px',
                    flexGrow: 1,
                    borderTop: '1px solid #E0E0E0',
                },
            },

            '& .expandFilterLogContentFields': {
                '& .filterLogContentField': {
                    flex: '0.35 !important',
                },
            },

            '& .filterLogContentFields': {
                display: 'flex',
                marginTop: '10px',
                justifyContent: 'space-between',

                '& .filterLogContentFieldLabel': {
                    width: '100%',
                    marginBottom: '8px',
                    fontSize: '14px',
                },

                '& .filterLogContentField': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    flex: 0.25,
                    marginRight: '15px',

                    '& .MuiFormControl-root': {
                        marginTop: '1px',
                    },

                    '& .MuiOutlinedInput-root': {
                        height: '35px !important',
                        borderRadius: '4px !important',
                        marginTop: '1px',

                        '& input': {
                            padding: '10px !important',
                            borderRadius: '0px',
                            transition: 'all 300ms',
                            fontSize: '14px',
                            backgroundColor: '#fff',
                        },
                    },
                },

                '& .filterLogContentDateField': {
                    '& .MuiOutlinedInput-root': {
                        '& svg': {
                            fontSize: '18px',
                        },
                    },
                },

                '& > div:last-child': {
                    marginRight: '0px !important',
                },

                '& .filterLogContentDateInput': {
                    flexGrow: 1,
                    marginRight: '5px',
                },

                '& .filterLogContentTimeInput': {
                    width: '135px',

                    '& .MuiOutlinedInput-root': {
                        // width: '120px',
                        '& button': {
                            padding: '0px 5px 0px 0px',
                        },
                    },
                },

                '& .filterLogContentDateFieldWrapper': {
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                },
            },
        };
    },
};
