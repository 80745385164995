import { authorizedAxiosInstance } from '../axiosInstances';
import { getGatewayUrl } from './envUtils';

/**
 * Upload image to server
 * @param {Files} files
 * @returns {Promise<*>} Promise resolved on success or rejected on failure.
 */
export const uploadImageToServer = (files) => {
    return new Promise((resolve, reject) => {
        const form = new FormData(),
            file = files[0];

        form.append('file', files[0]);

        // TODO this URL path did not exist in API so I just put one on it, will need to be revisited
        authorizedAxiosInstance
            .post(getGatewayUrl(`/upload-user-image`), {
                type: 'form_content',
                formId: '610cfad9f9b6cf00096d68da',
                expire_time: 5,
                file_name: files[0].name,
                contentType: files[0].type,
            })
            .then((res) => {
                const urlData = res.data.payload.split('?')[0];
                authorizedAxiosInstance
                    .put(res.data.payload, file, { headers: { 'Content-Type': files[0].type } })
                    .then(() => {
                        resolve({ data: { Location: urlData.split('com/')[1] } });
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
};
