import DataTable from 'react-data-table-component';
import PropTypes from 'prop-types';

import { tableCustomStyles, conditionalRowStyles } from './styles';

import RequestLoader from '../../Loaders/RequestLoader';
import NoTableDataContent from '../../NoTableDataContent';

const TableContainer = ({
    data,
    loading,
    columns,
    updatedRowId = '',
    emptyDataText = 'No data found',
    style = {},
    containerProps = {},
    idOverride,
    ...props
} = {}) => {
    const hasData = data?.length && data.length > 0,
        hasPagination = !loading && hasData;

    const rootStyle = {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: `${hasData || loading ? '1' : '0'}px solid #828282`,
        color: 'transparent',
        ...style,
    };

    const renderLoader = <RequestLoader width={75} height={80} />;

    let renderTable = null;
    if (loading && containerProps.unmountTable) {
        const loadingTableStyle = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: 'auto',
            minHeight: '100px',
            margin: '0px auto',
        };

        renderTable = <div style={loadingTableStyle}>{renderLoader}</div>;
    } else {
        renderTable = (
            <DataTable
                noHeader
                fixedHeader
                highlightOnHover
                persistTableHead
                data={data}
                keyField="_id"
                className="reactDataTable"
                columns={columns}
                defaultSortAsc={false}
                defaultSortFieldId={1}
                pagination={hasPagination}
                customStyles={tableCustomStyles}
                progressPending={loading}
                progressComponent={renderLoader}
                conditionalRowStyles={conditionalRowStyles(updatedRowId, idOverride)}
                noDataComponent={<NoTableDataContent text={emptyDataText} />}
                {...props}
            />
        );
    }

    return <div style={rootStyle}>{renderTable}</div>;
};

TableContainer.propTypes = {
    loading: PropTypes.bool,
    style: PropTypes.object,
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    containerProps: PropTypes.object,
    emptyDataText: PropTypes.string,
    updatedRowId: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
    idOverride: PropTypes.string,
};

export default TableContainer;
