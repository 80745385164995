import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = (maxWidth = 300, rootStyle = {}) =>
    styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth,
            color: theme.palette.common.white,
            backgroundColor: 'rgb(87, 87, 87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
            padding: '7px 10px',
            marginTop: '-50px',
            zIndex: 99999,
            ...rootStyle,
        },
        '& .MuiTypography-root': {
            color: '#fff',
            fontSize: '11px',
            lineHeight: '13px',
        },
        '& .descriptionText': {
            color: '#fff',
            fontSize: '11px',
            lineHeight: '14px',
        },
        '& .MuiTooltip-arrow': {
            color: 'rgb(87, 87, 87)',
        },
    }));

const getTrimmedTitle = (title, length) => {
    if (!length) {
        return title;
    }

    if (title.length < length) {
        return title;
    }

    return `${title.substring(0, length)}...`;
};

const DescriptionTooltip = ({
    text,
    title = '',
    maxWidth = 520,
    maxLength,
    style = {},
    wrapText = true,
    tooltipProps = {},
    rootStyle = {},
}) => {
    if (!text) return '';

    const tooltipTitle = title || text;
    const trimmedText = getTrimmedTitle(text || title, maxLength);

    const textStyle = {
        color: '#333',
        fontSize: '11px',
        lineHeight: '13px',
        ...style,
    };

    const TooltipComponent = LightTooltip(maxWidth, rootStyle);

    return (
        <TooltipComponent
            arrow={true}
            title={
                <Typography className="descriptionText" component="div">
                    {tooltipTitle}
                </Typography>
            }
            {...tooltipProps}
        >
            <Typography component="div" noWrap={!wrapText} style={textStyle}>
                {trimmedText}
            </Typography>
        </TooltipComponent>
    );
};

DescriptionTooltip.propTypes = {
    text: PropTypes.node.isRequired,
    title: PropTypes.node,
    style: PropTypes.object,
    maxWidth: PropTypes.number,
    maxLength: PropTypes.number,
    wrapText: PropTypes.bool,
    rootStyle: PropTypes.object,
    tooltipProps: PropTypes.object,
};

export default DescriptionTooltip;
