import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import Typography from '@mui/material/Typography';

import styles from './styles';
import HomeIcon from '../../assets/icons/HomeIcon';

const NavigationIndicator = ({ activePageTitle, routes, textTransform = '', style = {} }) => {
    const titleStyle = { ...styles.homeText },
        activeNavText = { ...styles.homeText, ...styles.activeNavText },
        rootStyle = { ...styles.root, ...style };

    if (textTransform) {
        titleStyle.textTransform = textTransform;
        activeNavText.textTransform = textTransform;
    }

    const renderPageRoutes =
        routes &&
        routes?.map((route) => (
            <Fragment key={route.route}>
                <Link to={route.route} style={styles.link}>
                    <span style={titleStyle}>{route.title}</span>
                </Link>
                <div style={styles.separator}>/</div>
            </Fragment>
        ));

    return (
        <Typography component="div" style={rootStyle}>
            <Link to="/dashboard" style={styles.link}>
                <span style={styles.homeIconWrapper}>
                    <HomeIcon style={styles.homeIcon} />
                </span>
                <span style={styles.homeText}>Home</span>
            </Link>

            <div style={styles.separator}>/</div>

            {renderPageRoutes}

            {activePageTitle && <div style={activeNavText}>{activePageTitle}</div>}
        </Typography>
    );
};

NavigationIndicator.propTypes = {
    style: PropTypes.object,
    routes: PropTypes.array,
    textTransform: PropTypes.string,
    activePageTitle: PropTypes.node.isRequired,
};

export default NavigationIndicator;
