import { useStoreValue, useStoreState, getStoreState, setStoreState } from './useStoreStateHook';

const confirmationModalDefaultState = {
        open: false,
        action: '',
        approved: false,
    },
    storageAccessModalDefaultState = {
        open: false,
        lastSubmittedStorageAccessRequestDate: null,
    };

export const confirmUserDetailChangesModalDefaultState = {
    open: false,
    userDetails: {},
    onConfirm: () => {},
};

/**
 * Hook to subscribe and update confirmation modal state.
 *
 * @return {Array<object, function>}
 */
export const useConfirmationModal = () => useStoreState('confirmationModal')(confirmationModalDefaultState);

/**
 * Hook to subscribe and update confirmation modal title state.
 *
 * @return {Array<object, function>}
 */
export const useConfirmationModalTitle = () => useStoreState('confirmationModalTitle')('');

/**
 * Subscribe to confirmation modal state changes.
 *
 * @returns {object} state Confirmation modal state.
 * @returns {boolean} state.open Whether or not to show the confirmation modal.
 * @returns {string} state.action Confirmation modal action.
 * @returns {boolean} state.approved Whether modal action is approved.
 */
export const useConfirmationModalValue = () => useStoreValue('confirmationModal')(confirmationModalDefaultState);

/**
 * Subscribe to confirmation modal title state changes.
 *
 * @returns {React.ReactNode} Confirmation modal title.
 */
export const useConfirmationModalTitleValue = () => useStoreValue('confirmationModalTitle')('');

/**
 * Toggle confirmation modal state.
 *
 * @param {object} state Confirmation modal state.
 * @param {boolean} state.open Whether or not to show the confirmation modal.
 * @param {string} state.action Confirmation modal action.
 * @param {boolean} state.approved Whether modal action is approved.
 *
 * @returns {void}
 */
export const toggleConfirmationModal = (state) => setStoreState('confirmationModal')(state);

/**
 * Set confirmation modal state.
 *
 * @param {React.ReactNode} modalTitle Confirmation modal title.
 *
 * @returns {void}
 */
export const setConfirmationModalTitle = (modalTitle) => setStoreState('confirmationModalTitle')(modalTitle);

/**
 * Get the confirmation modal button state toggler
 * @returns {HTMLButtonElement}
 */
const getConfirmationModalButton = () => document?.querySelector('.confirmationModalBtn');

/**
 * Toggle the confirmation modal state lazily.
 * This technique is used to improve re-rendering performance.
 *
 * @param {object} state Confirmation modal state.
 * @param {boolean} state.open Whether or not to show the confirmation modal.
 * @param {string} state.action Confirmation modal action.
 */
export const lazyToggleConfirmationModal = (state) => {
    const confirmationModalBtn = getConfirmationModalButton();
    if (confirmationModalBtn) {
        confirmationModalBtn.setAttribute('data-modal-action', state.action);

        state.open && confirmationModalBtn.click();
    }
};

/**
 * Clear the confirmation modal state
 *
 * @param {boolean} isApproved Whether the modal action is approved.
 * Default: false
 */
export const clearConfirmationModalState = (isApproved = false) => {
    const confirmationModalState = getStoreState('confirmationModal')();
    toggleConfirmationModal({
        ...confirmationModalDefaultState,
        action: isApproved ? confirmationModalState.action || '' : '',
        approved: isApproved,
    });

    setConfirmationModalTitle('');

    lazyToggleConfirmationModal({
        open: false,
        action: '',
    });
};

/**
 * Open logout confirmation modal state.
 */
export const openLogoutConfirmationModal = () => {
    setConfirmationModalTitle('Are you sure you want to logout?');
    lazyToggleConfirmationModal({
        open: true,
        action: 'logout',
    });
};

/**
 * Check if confirmation modal action is 'logout' and action was approved.
 */
export const useIsLogoutConfirmationModalApproved = () => {
    const state = useConfirmationModalValue();
    return state?.approved && state.action === 'logout';
};

/**
 * Open delete user modal state.
 */
export const openDeleteUserConfirmationModal = () => {
    lazyToggleConfirmationModal({
        open: true,
        action: 'delete-user',
    });
};

/**
 * Check if confirmation modal action is 'logout' and action was approved.
 */
export const useIsDeleteUserConfirmationModalApproved = () => {
    const state = useConfirmationModalValue();
    return state?.approved && state.action === 'delete-user' ? true : false;
};

/**
 * Get confirm user detail changes modal state and subscribe to changes.
 */
export const useConfirmUserDetailChangesModal = () =>
    useStoreState('confirmUserDetailChangesModal')(confirmUserDetailChangesModalDefaultState)[0];

/**
 * Open confirm user detail changes modal
 * @param {Object} userDetails
 * @param {Function} onConfirm
 */
export const openConfirmUserDetailChangesModal = ({ userDetails, onConfirm, onClose }) => {
    setStoreState('confirmUserDetailChangesModal')({
        open: true,
        userDetails,
        onConfirm,
        onClose,
    });
};

/**
 * Close confirm user detail changes modal
 * and reset the state
 */
export const closeConfirmUserDetailChangesModal = () => {
    setStoreState('confirmUserDetailChangesModal')(confirmUserDetailChangesModalDefaultState);
};

/**
 * Get storage access modal state and subscribe to changes.
 */
export const useStorageAccessModal = () => useStoreState('storageAccessModal')(storageAccessModalDefaultState)[0];

/**
 * Open storage access modal
 * @param {Date} lastSubmittedStorageAccessRequestDate
 */
export const openStorageAccessModal = (lastSubmittedStorageAccessRequestDate) => {
    setStoreState('storageAccessModal')({
        open: true,
        lastSubmittedStorageAccessRequestDate,
    });
};

/**
 * Close storage access modal
 */
export const closeStorageAccessModal = () => {
    setStoreState('storageAccessModal')({
        open: false,
    });
};
