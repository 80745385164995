const INIT_STATE = {
    loading: false,
    uploading: false,
};

const StartLoading = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'START_LOADING':
            return { ...state, ...action.payload };

        default:
            return state;
    }
};

export default StartLoading;
