import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import OrganizationUsersTable from 'components/Tables/OrganizationUsersTable';
import OrganizationUsersModals from 'components/OrganizationUsersModals';

const OrganizationUsers = () => {
    const boxStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'start',
    };

    return (
        <>
            <CssBaseline />
            <Box sx={boxStyle}>
                <OrganizationUsersTable />
            </Box>
            <OrganizationUsersModals />
        </>
    );
};

export default OrganizationUsers;
