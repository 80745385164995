/* eslint default-param-last: 0 */
import { MODAL_ACTIONS } from '../../hooks/useModalStateHook';
import reduxReducerHelper from '../../utils/reduxReducerHelperUtils';
import { confirmUserDetailChangesModalDefaultState } from 'hooks/useModalHook';

const INIT_STATE = {
    [MODAL_ACTIONS?.UPDATE_ORGANIZATION_USER_MODAL]: false,
    [MODAL_ACTIONS?.UPDATE_INVITE_ORGANIZATION_USER_MODAL]: false,
    [MODAL_ACTIONS?.UPDATE_GENERATE_VISUAL_MODAL]: false,
    [MODAL_ACTIONS?.UPDATE_GENERATE_VISUAL_MODAL_COUNTER]: 0,
    [MODAL_ACTIONS?.UPDATE_CONFIRM_USER_DETAIL_CHANGES_MODAL]: confirmUserDetailChangesModalDefaultState,
};

export default reduxReducerHelper(MODAL_ACTIONS, INIT_STATE);
