import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

const EnvelopeSquareIcon = (props) => {
    const fill = props.fill || '#9B51E0';

    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.8438 12.1562C11.0938 12.3438 11.625 12.7812 12 12.75C12.3438 12.7812 12.875 12.3438 13.125 12.1562C15.4062 10.5938 15.375 10.5938 16 10.125V9.75C16 9.34375 15.6562 9 15.25 9H8.75C8.3125 9 8 9.34375 8 9.75V10.125C8.59375 10.5938 8.5625 10.5938 10.8438 12.1562ZM13.6875 12.9688C13.3438 13.2188 12.7188 13.7812 12 13.75C11.25 13.7812 10.625 13.2188 10.2812 12.9688C8.875 12 8.34375 11.625 8 11.375V14.25C8 14.6875 8.3125 15 8.75 15H15.25C15.6562 15 16 14.6875 16 14.25V11.375C15.625 11.625 15.0938 12 13.6875 12.9688ZM19 6.5C19 5.6875 18.3125 5 17.5 5H6.5C5.65625 5 5 5.6875 5 6.5V17.5C5 18.3438 5.65625 19 6.5 19H17.5C18.3125 19 19 18.3438 19 17.5V6.5ZM17.5 17.3125C17.5 17.4375 17.4062 17.5 17.3125 17.5H6.6875C6.5625 17.5 6.5 17.4375 6.5 17.3125V6.6875C6.5 6.59375 6.5625 6.5 6.6875 6.5H17.3125C17.4062 6.5 17.5 6.59375 17.5 6.6875V17.3125Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

EnvelopeSquareIcon.propTypes = {
    fill: PropTypes.string,
};

export default EnvelopeSquareIcon;
