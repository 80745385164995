export default {
    root: ({ defaultWidth, minWidth, maxWidth }) => ({
        minWidth: minWidth ?? '100px',
        maxWidth: maxWidth ?? 'none',
        width: `${defaultWidth}px`,
        height: '100%',
        display: 'flex',
        flexDirection: 'row',

        '.container': {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            overflow: 'auto',
        },

        '.fileTreeHeader': {
            width: '100%',
            backgroundColor: '#4F4F4F',
            color: 'white',
            padding: '6px 10px',
            cursor: 'pointer',
            userSelect: 'none',
            WebkitUserSelect: 'none',

            '&:hover': {
                textDecoration: 'underline',
            },
        },

        '.treeContainer': {
            padding: '8px',
            height: '100%',
        },

        '.treeItem': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: '4px',
            userSelect: 'none',
            WebkitUserSelect: 'none',
        },

        '.dragHandle': {
            height: '100%',
            width: '1px',
            backgroundColor: '#E0E0E0',
            cursor: 'col-resize',
        },
    }),
};
