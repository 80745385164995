import { useState, useEffect, useRef } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormHelperText from '@mui/material/FormHelperText';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import CustomBootstrapDialog, { BootstrapModalTitle } from '../../BootstrapModal';

import styles from '../styles';

import {
    useFileUploadModalState,
    closeFileUploadModal,
    getCurrentSelectedFolderPath,
    refreshSelectedFolder,
} from 'hooks/useMyFilesHook';
import {
    uploadFileUsingURL,
    uploadFile,
    startMultipartUpload,
    uploadMultipartFile,
    completeMultipartUpload,
} from 'utils/intelyStorageUtils';
import { getCurrentOrganizationId } from 'utils/loginUtils';

const FileUploadModal = () => {
    const { open } = useFileUploadModalState();
    const [isLoading, setIsLoading] = useState(false),
        [uploadType, setUploadType] = useState('url'),
        [file, setFile] = useState(null),
        [errors, setErrors] = useState({
            url: '',
            fileName: '',
            file: '',
        }),
        urlInputRef = useRef(),
        fileNameInputRef = useRef();

    useEffect(() => {
        if (open) {
            setIsLoading(false);
            setUploadType('url');
            setFile(null);
            setErrors({});
            if (urlInputRef.current) {
                urlInputRef.current.value = '';
            }

            if (fileNameInputRef.current) {
                fileNameInputRef.current.value = '';
            }
        }
    }, [open]);

    const handleClose = () => {
        closeFileUploadModal();
    };

    const multipartUpload = () => {
        // Multipart upload
        const fileChunks = [];
        // 2 MB chunk size
        const chunkSize = 1024 * 1024 * 2;
        let start = 0;

        while (start < file.size) {
            fileChunks.push(file.slice(start, start + chunkSize));
            start += chunkSize;
        }

        startMultipartUpload(getCurrentOrganizationId())
            .then((response) => {
                const uploadId = response.uploadId,
                    promises = [];

                for (let i = 0; i < fileChunks.length; i++) {
                    promises.push(uploadMultipartFile(getCurrentOrganizationId(), uploadId, fileChunks[i], i + 1));
                }

                const currentFilePath = getCurrentSelectedFolderPath();

                Promise.all(promises)
                    .then(() => {
                        completeMultipartUpload(
                            getCurrentOrganizationId(),
                            uploadId,
                            fileChunks.length,
                            currentFilePath === '/' ? '' : currentFilePath,
                            fileNameInputRef.current.value.length ? fileNameInputRef.current.value : file.name,
                        )
                            .then(() => {
                                refreshSelectedFolder();
                                handleClose();
                            })
                            .catch(() => {})
                            .finally(() => {
                                setIsLoading(false);
                            });
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const handleUpload = () => {
        const errors = {};
        let hasError = false;

        if (uploadType === 'url') {
            if (!urlInputRef.current.value) {
                errors.url = 'File URL is required';
                hasError = true;
            }

            if (!fileNameInputRef.current.value) {
                errors.fileName = 'File Name is required';
                hasError = true;
            }
        } else {
            if (!file) {
                errors.file = 'File is required';
                hasError = true;
            }
        }

        if (hasError) {
            setErrors(errors);
            return;
        }

        setIsLoading(true);

        const currentFilePath = getCurrentSelectedFolderPath(),
            filePathForUpload = currentFilePath === '/' ? '' : currentFilePath;

        if (uploadType === 'url') {
            uploadFileUsingURL(
                getCurrentOrganizationId(),
                filePathForUpload,
                urlInputRef.current.value,
                fileNameInputRef.current.value,
            )
                .then(() => {
                    refreshSelectedFolder();
                    handleClose();
                })
                .catch(() => {})
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            if (file.size > 1024 * 1024 * 2) {
                multipartUpload();
            } else {
                uploadFile(getCurrentOrganizationId(), filePathForUpload, file, fileNameInputRef.current.value)
                    .then(() => {
                        refreshSelectedFolder();
                        handleClose();
                    })
                    .catch(() => {})
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        }
    };

    const toggleUploadType = (type) => {
        setUploadType(type);
        setErrors({});
        fileNameInputRef.current.value = '';
    };

    const buttonStyle = (side, isActive) => ({
            borderRadius: side === 'left' ? '25px 0 0 25px' : '0 25px 25px 0',
            backgroundColor: isActive ? '#3A70D4' : 'white',
            color: isActive ? 'white' : 'black',
            fontWeight: 500,
            border: isActive ? 'none' : '1px solid #E3E3E3',

            '&:hover': {
                backgroundColor: isActive ? '#3A70D4' : '#E3E3E3',
            },
        }),
        hiddenInputStyle = {
            clip: 'rect(0 0 0 0)',
            clipPath: 'inset(50%)',
            height: 1,
            overflow: 'hidden',
            position: 'absolute',
            bottom: 0,
            left: 0,
            whiteSpace: 'nowrap',
            width: 1,
        };

    const toggleButton = (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button onClick={() => toggleUploadType('url')} sx={buttonStyle('left', uploadType === 'url')}>
                URL
            </Button>
            <Button onClick={() => toggleUploadType('file')} sx={buttonStyle('right', uploadType === 'file')}>
                File
            </Button>
        </div>
    );

    const urlUpload = (
        <div style={{ marginTop: '24px', width: '100%' }}>
            <Typography sx={styles.inputLabel(Boolean(errors.url))}>
                File URL <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
                inputRef={urlInputRef}
                autoFocus
                id="url"
                type="text"
                fullWidth
                sx={styles.input}
                onChange={() => {
                    if (errors.url) setErrors((prev) => ({ ...prev, url: '' }));
                }}
                placeholder="File URL"
                error={Boolean(errors.url)}
            />
            {errors.url ? <FormHelperText error>{errors.url}</FormHelperText> : null}
            <Typography sx={styles.inputLabel(errors.fileName)} style={{ marginTop: '16px' }}>
                File Name <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
                inputRef={fileNameInputRef}
                autoFocus
                id="file-name"
                type="text"
                fullWidth
                sx={styles.input}
                onChange={() => {
                    if (errors.fileName) setErrors((prev) => ({ ...prev, fileName: '' }));
                }}
                placeholder="File Name"
                error={Boolean(errors.fileName)}
            />
            {errors.fileName ? <FormHelperText error>{errors.fileName}</FormHelperText> : null}
        </div>
    );

    const fileUpload = (
        <>
            <div style={{ marginTop: '24px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Typography sx={styles.inputLabel(errors.file)}>
                    File <span style={{ color: 'red' }}>*</span>
                </Typography>
                {file ? <Typography sx={styles.inputLabel()}>{file.name}</Typography> : null}
                <Button component="label" tabIndex={-1} startIcon={<CloudUploadIcon />}>
                    Upload File
                    <input
                        type="file"
                        style={hiddenInputStyle}
                        onChange={(e) => {
                            if (errors.file) setErrors((prev) => ({ ...prev, file: '' }));
                            setFile(e.target.files[0]);
                        }}
                    />
                </Button>
            </div>
            {errors.file ? <FormHelperText error>{errors.file}</FormHelperText> : null}
            <Typography sx={styles.inputLabel()} style={{ marginTop: '16px', width: '100%' }}>
                File Name (Optional)
            </Typography>
            <TextField
                inputRef={fileNameInputRef}
                autoFocus
                id="file-name"
                type="text"
                fullWidth
                sx={styles.input}
                placeholder="File Name"
            />
        </>
    );

    return (
        <CustomBootstrapDialog open={open} sx={styles.dialogRoot(700, 400)}>
            <BootstrapModalTitle sx={styles.title} onClose={handleClose}>
                Upload File
            </BootstrapModalTitle>
            <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{toggleButton}</div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {uploadType === 'url' ? urlUpload : fileUpload}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} sx={styles.cancel}>
                    Cancel
                </Button>
                <Button disabled={isLoading} onClick={handleUpload} sx={styles.submit}>
                    Upload
                </Button>
            </DialogActions>
        </CustomBootstrapDialog>
    );
};

export default FileUploadModal;
