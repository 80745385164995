import { fetchLogs } from 'utils/loggingUtils';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { resetLogFilterData, setSelectedLogNameItem, useSelectedLogNameItem } from 'hooks/useOrganizationLoggingHook';

const LoggingSidebar = () => {
    const selectedLogNameItem = useSelectedLogNameItem();

    const menus = [
        {
            name: 'api',
            title: 'API',
        },
        {
            name: 'app-request',
            title: 'APP Request',
        },
        {
            name: 'comparison',
            title: 'Comparisons',
        },
        {
            name: 'mappings',
            title: 'Mappings',
        },
        {
            name: 'integrations',
            title: 'Integrations',
        },
    ];

    /**
     * Set and Fetch the selected log type
     * @param {{ name: string, title: string}} item
     * @returns {() => {}}
     */
    const handleSetSelectedLogNameItem = (item) => async () => {
        if (item.name === selectedLogNameItem.name) return;

        setSelectedLogNameItem(item);
        resetLogFilterData();

        // Get the logs
        fetchLogs();
    };

    const renderMenus = menus.map((menu) => {
        const isMenuActive = menu.name === selectedLogNameItem?.name;

        return (
            <PrimaryButton
                key={menu.name}
                backgroundColor={isMenuActive ? '#F2F2F2' : 'transparent'}
                height="30px"
                padding="0px 0px 0px 15px"
                width="100%"
                color="rgb(162 161 161)"
                borderRadius="0px"
                fontSize="13px"
                margin="0px 0px 10px 0px"
                className={`loggingSidebarBtn${isMenuActive ? ' loggingSidebarBtnActive' : ''}`}
                onClick={handleSetSelectedLogNameItem(menu)}
            >
                {menu.title}
            </PrimaryButton>
        );
    });

    return <div className="loggingSidebar">{renderMenus}</div>;
};

export default LoggingSidebar;
