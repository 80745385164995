const INIT_STATE = {
    user: {},
};

const AuthReducer = (state = INIT_STATE, action = {}) => {
    switch (action.type) {
        case 'SET_CURRENT_USER_DATA':
            return { ...state, user: action.payload };
        default:
            return state;
    }
};

export default AuthReducer;
