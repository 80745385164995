import TextField from '../TextField';
import { REGEX_PATTERNS } from '../../../config/constants';

const LastNameField = (props) => {
    const validate = {
        regex: REGEX_PATTERNS.name,
        emptyLabel: 'Please enter your last name.',
        invalidLabel: 'Last name should be alphabets.',
    };

    return (
        <TextField
            placeholder="Your Last Name"
            autoComplete="lastName"
            label="Last Name"
            name="lastName"
            validate={validate}
            {...props}
        />
    );
};

export default LastNameField;
