import TextField from '../TextField';
import { REGEX_PATTERNS } from '../../../config/constants';

const FirstNameField = (props) => {
    const validate = {
        regex: REGEX_PATTERNS.name,
        emptyLabel: 'Please enter your first name.',
        invalidLabel: 'First name should be alphabets.',
    };

    return (
        <TextField
            placeholder="Your First Name"
            autoComplete="firstName"
            label="First Name"
            name="firstName"
            validate={validate}
            {...props}
        />
    );
};

export default FirstNameField;
