import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import UserIcon from '../../../assets/icons/User';

import { useUserProp } from '../../../hooks/useUserHook';
// import OnlineRippleBadge from '../../Badges/OnlineRippleBadge';

const useStyles = makeStyles(() => ({
    userIcon: {
        marginLeft: 10,
        marginRight: 5,
        width: 36,
        height: 36,
        borderRadius: '50%',
        color: '#C4C4C4',
    },
}));

const UserAvatar = ({ imageProps = {}, iconStyle = {}, avatarStyle = {} }) => {
    const classes = useStyles(),
        profileImage = useUserProp('profileImg');

    const userAvatarStyle = {
            width: '36px',
            height: '36px',
            fontSize: '15px',
            color: '#fff',
            border: '1px solid #6198FF',
            borderRadius: '50%',
            background: '#E0E0E0',
            ...avatarStyle,
        },
        userIconStyle = {
            width: '20px',
            height: '20px',
            ...iconStyle,
        };

    const renderAvatar = profileImage ? (
        <img alt="avatar" src={profileImage} className={classes.userIcon} {...imageProps} />
    ) : (
        <Avatar sx={userAvatarStyle}>
            <UserIcon sx={userIconStyle} />
        </Avatar>
    );

    return renderAvatar;
};

UserAvatar.propTypes = {
    iconStyle: PropTypes.object,
    imageProps: PropTypes.object,
    avatarStyle: PropTypes.object,
};

export default UserAvatar;
