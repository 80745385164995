import { memo } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Logo from '../Images/Logo';
import { HEADER_HEIGHT } from 'config/constants';

const UserHeaderContent = loadable(() => import('./UserHeaderContent'));

const Header = ({ isUserLoggedIn }) => {
    return (
        <Box sx={{ height: { HEADER_HEIGHT } }}>
            <AppBar elevation={0} sx={{ background: '#FFFFFF' }}>
                <Toolbar sx={{ boxShadow: '0px 1px 6px rgba(123, 133, 160, 0.25) !important' }}>
                    <Logo isUserLoggedIn={isUserLoggedIn} />
                    <UserHeaderContent isUserLoggedIn={isUserLoggedIn} />
                    {/* <UserHeaderRightContent isUserLoggedIn={isUserLoggedIn} /> */}
                </Toolbar>
            </AppBar>
        </Box>
    );
};

Header.propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
};

export default memo(Header);
