import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

const SettingIcon = (props) => {
    const fill = props.fill || 'white';

    return (
        <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.89166 19.1666L7.43333 16.2791C7.14305 16.1721 6.83749 16.027 6.51666 15.8437C6.19583 15.6603 5.91319 15.4694 5.66875 15.2708L2.96458 16.5083L0.833328 12.7499L3.30833 10.9395C3.27777 10.802 3.25868 10.6454 3.25104 10.4697C3.2434 10.294 3.23958 10.1374 3.23958 9.99992C3.23958 9.86242 3.2434 9.70582 3.25104 9.53013C3.25868 9.35443 3.27777 9.19784 3.30833 9.06034L0.833328 7.24992L2.96458 3.49159L5.66875 4.72909C5.91319 4.53047 6.19583 4.3395 6.51666 4.15617C6.83749 3.97284 7.14305 3.83534 7.43333 3.74367L7.89166 0.833252H12.1083L12.5667 3.72075C12.8569 3.8277 13.1663 3.96902 13.4948 4.14471C13.8233 4.32041 14.1021 4.5152 14.3312 4.72909L17.0354 3.49159L19.1667 7.24992L16.6917 9.0145C16.7222 9.16728 16.7413 9.33152 16.749 9.50721C16.7566 9.6829 16.7604 9.84714 16.7604 9.99992C16.7604 10.1527 16.7566 10.3131 16.749 10.4812C16.7413 10.6492 16.7222 10.8096 16.6917 10.9624L19.1667 12.7499L17.0354 16.5083L14.3312 15.2708C14.0868 15.4694 13.808 15.6642 13.4948 15.8551C13.1816 16.0461 12.8722 16.1874 12.5667 16.2791L12.1083 19.1666H7.89166ZM10 12.9791C10.825 12.9791 11.5278 12.6888 12.1083 12.1083C12.6889 11.5277 12.9792 10.8249 12.9792 9.99992C12.9792 9.17492 12.6889 8.47214 12.1083 7.89159C11.5278 7.31103 10.825 7.02075 10 7.02075C9.175 7.02075 8.47222 7.31103 7.89166 7.89159C7.31111 8.47214 7.02083 9.17492 7.02083 9.99992C7.02083 10.8249 7.31111 11.5277 7.89166 12.1083C8.47222 12.6888 9.175 12.9791 10 12.9791Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

SettingIcon.propTypes = {
    fill: PropTypes.string,
};

export default SettingIcon;
