import { useNavigate } from 'react-router-dom';
import { memo, useState, useEffect } from 'react';

import TableContainer from 'components/Containers/TableContainer';
import useSelectedOrganization from 'hooks/useSelectedOrganizationHook';
import { getOrganizationUsersData } from 'utils/organizationUtils';
import OrganizationUsersTableColumns from './OrganizationUsersTableColumns';
import { showSnackBarErrorNotification } from 'utils/snackBarNotificationUtils';
import {
    useOrganizationUsersData,
    setOrganizationUsersData,
    useRecentlyUpdatedOrganizationUserId,
} from 'hooks/useOrganizationUsersHook';

const OrganizationUsersTable = () => {
    const organizationUsers = useOrganizationUsersData(),
        navigate = useNavigate(),
        [loading, setLoading] = useState(true),
        [currentSelectedOrganization] = useSelectedOrganization(),
        recentlyUpdatedOrganizationUserId = useRecentlyUpdatedOrganizationUserId();

    useEffect(() => {
        async function getOrganizationUserData() {
            getOrganizationUsersData(currentSelectedOrganization.organizationId)
                .then((data) => {
                    setLoading(false);
                    const organizationUsersData = data?.length ? data : [];
                    setOrganizationUsersData(organizationUsersData);
                })
                .catch((err) => {
                    setLoading(false);
                    navigate('/dashboard');
                    showSnackBarErrorNotification(err.response.data.message);
                });
        }

        if (currentSelectedOrganization.organizationId) {
            getOrganizationUserData();
        }
    }, [currentSelectedOrganization.organizationId]);

    const organizationUserTableColumnsRenderer = OrganizationUsersTableColumns({});

    return (
        <TableContainer
            data={organizationUsers}
            loading={loading}
            columns={organizationUserTableColumnsRenderer}
            updatedRowId={recentlyUpdatedOrganizationUserId}
            emptyDataText="No organization user found!"
        />
    );
};

export default memo(OrganizationUsersTable);
