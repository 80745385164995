import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

const DocumentationIcon = (props) => {
    const fill = props.fill || 'white';

    return (
        <SvgIcon width="18" height="18" viewBox="0 0 18 18" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.125 17.25C1.75833 17.25 1.4375 17.1125 1.1625 16.8375C0.8875 16.5625 0.75 16.2417 0.75 15.875V2.125C0.75 1.75833 0.8875 1.4375 1.1625 1.1625C1.4375 0.8875 1.75833 0.75 2.125 0.75H12.7125L17.25 5.2875V15.875C17.25 16.2417 17.1125 16.5625 16.8375 16.8375C16.5625 17.1125 16.2417 17.25 15.875 17.25H2.125ZM5.08125 13.6062C5.26458 13.6062 5.425 13.5375 5.5625 13.4C5.7 13.2625 5.76875 13.1021 5.76875 12.9187C5.76875 12.7354 5.7 12.575 5.5625 12.4375C5.425 12.3 5.26458 12.2312 5.08125 12.2312C4.89792 12.2312 4.7375 12.3 4.6 12.4375C4.4625 12.575 4.39375 12.7354 4.39375 12.9187C4.39375 13.1021 4.4625 13.2625 4.6 13.4C4.7375 13.5375 4.89792 13.6062 5.08125 13.6062ZM5.08125 9.6875C5.26458 9.6875 5.425 9.61875 5.5625 9.48125C5.7 9.34375 5.76875 9.18333 5.76875 9C5.76875 8.81667 5.7 8.65625 5.5625 8.51875C5.425 8.38125 5.26458 8.3125 5.08125 8.3125C4.89792 8.3125 4.7375 8.38125 4.6 8.51875C4.4625 8.65625 4.39375 8.81667 4.39375 9C4.39375 9.18333 4.4625 9.34375 4.6 9.48125C4.7375 9.61875 4.89792 9.6875 5.08125 9.6875ZM5.08125 5.76875C5.26458 5.76875 5.425 5.7 5.5625 5.5625C5.7 5.425 5.76875 5.26458 5.76875 5.08125C5.76875 4.89792 5.7 4.7375 5.5625 4.6C5.425 4.4625 5.26458 4.39375 5.08125 4.39375C4.89792 4.39375 4.7375 4.4625 4.6 4.6C4.4625 4.7375 4.39375 4.89792 4.39375 5.08125C4.39375 5.26458 4.4625 5.425 4.6 5.5625C4.7375 5.7 4.89792 5.76875 5.08125 5.76875ZM11.9563 6.04375H15.875L11.9563 2.125V6.04375Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

DocumentationIcon.propTypes = {
    fill: PropTypes.string,
};

export default DocumentationIcon;
