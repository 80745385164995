import { authorizedAxiosInstance } from '../axiosInstances';
import HttpStatusCodes from '../classes/HttpStatusCodes';
import * as constants from '../config/constants';
import { getGatewayUrl } from './envUtils';
import { processResponse, sendGetRequest, sendPatchRequest, sendPostRequest } from './dataRequestUtils';

/**
 * Process the request response object
 * @param {Object} response
 * @param {Function|null} callback Success callback
 * @returns {any}
 */
const processRequestResponse = (response, callback = null) => {
    if (response?.status === HttpStatusCodes.SUCCESS) {
        if (callback) {
            callback(response.data);
        }

        return response.data;
    } else {
        throw response.errors;
    }
};

/**
 *
 * @param {String} organizationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getOrganizationData = async (organizationId) => {
    return authorizedAxiosInstance
        .get(getGatewayUrl(`/organization/${organizationId}`))
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 *
 * @param {String} organizationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getOrganizationUsersData = async (organizationId) => {
    return authorizedAxiosInstance
        .get(getGatewayUrl(`/organization/${organizationId}/users`))
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 *
 * @param {String} organizationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getOrganizationUsersById = async (organizationId, userId) => {
    return authorizedAxiosInstance
        .get(getGatewayUrl(`/organization/${organizationId}/users/${userId}`))
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 * Check whether the integration is disabled
 * @param {number} status Integration status
 * @returns {boolean} True if the integration is disabled. Otherwise false.
 */
export const isOrganizationUserDisabled = (status) => status === constants.ORGANIZATION_USER_STATUS_DISABLED;

/**
 * Check whether the integration is active
 * @param {number} status Integration status
 * @returns {boolean} True if the integration is active. Otherwise false.
 */
export const isOrganizationUserActive = (status) => status === constants.ORGANIZATION_USER_STATUS_ACTIVE;

/**
 * Check whether the organization is disabled
 * @param {number} status organization status
 * @returns {boolean} True if the organization is disabled. Otherwise false.
 */
export const isOrganizationAdmin = (status) => status === constants.ORGANIZATION_USER_STATUS_ADMIN;

/**
 * Check whether the organization is viewer
 * @param {number} status organization status
 * @returns {boolean} True if the organization is active. Otherwise false.
 */
export const isOrganizationViewer = (status) => status === constants.ORGANIZATION_USER_STATUS_VIEWER;

/**
 * Check whether the organization is viewer
 * @param {number} status organization status
 * @returns {boolean} True if the organization is viewer . Otherwise false.
 */
export const isOrganizationUser = (status) => status === constants.ORGANIZATION_USER_STATUS_USER;

/**
 * Check whether the organization storage is enabled or not
 * @returns {Promise<AxiosResponse<any>>} True if the organization storage is enabled. Otherwise false.
 */
export const isOrganizationStorageEnabled = async (organizationId) => {
    return authorizedAxiosInstance
        .get(getGatewayUrl(`/organization/${organizationId}/storage/enabled`))
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 * Update organization user
 * @param {String} organizationId
 *  * @param {String} userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateOrganizationUserData = async (organizationId, userId, data) => {
    return authorizedAxiosInstance
        .patch(getGatewayUrl(`/organization/${organizationId}/user/${userId}`), data)
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 * Update organization user
 * @param {String} organizationId
 * @param {String} userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const inviteOrganizationUserData = async (organizationId, data) => {
    return authorizedAxiosInstance
        .post(getGatewayUrl(`/organization/${organizationId}/inviteUser`), data)
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 * Update organization details
 * @param {String} organizationId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateOrganizationData = async (organizationId, data) => {
    return authorizedAxiosInstance
        .patch(getGatewayUrl(`/organization/${organizationId}`), data)
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 * Delete organization user
 * @param {String} organizationId
 * @param {String} userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteOrganizationUser = async (organizationId, userId) => {
    return authorizedAxiosInstance
        .delete(getGatewayUrl(`/organization/${organizationId}/user/${userId}`))
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 *
 * @param {String} organizationId
 * @param {String} userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const disableOrganizationUser = async (organizationId, userId) => {
    return authorizedAxiosInstance
        .post(getGatewayUrl(`/organization/${organizationId}/user/${userId}/disable`))
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 *
 * @param {String} organizationId
 * @param {String} userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const enableOrganizationUser = async (organizationId, userId) => {
    return authorizedAxiosInstance
        .post(getGatewayUrl(`/organization/${organizationId}/user/${userId}/enable`))
        .then(processRequestResponse)
        .catch((error) => {
            throw error;
        });
};

/**
 * Query whether an organization has VPN Management enabled
 * @param {string} organizationId
 * @param {Object} callbacks
 * @returns {Promise<*>}
 */
export const getVpnManagementStatus = async (
    organizationId,
    { successCallback, errorCallback, finallyCallback } = {},
) => {
    return sendGetRequest({
        url: `/organization/${organizationId}/vpn/status`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
        }),
    });
};

/**
 * Handles submitting a request for access to the VPN management interface.
 * @param {string} organizationId
 * @param {number} requestedQuota
 * @param {Object} callbacks
 * @returns {Promise<*>}
 */
export const requestVpnManagement = async (
    organizationId,
    requestedQuota,
    { successCallback, errorCallback, finallyCallback } = {},
) => {
    return sendPostRequest({
        url: `/organization/${organizationId}/vpn/request`,
        data: {
            requestedQuota: requestedQuota,
        },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
        }),
    });
};

/**
 * Handles submitting a request for access to the organization's storage interface.
 * @param {string} organizationId
 * @param {Object} callbacks
 * @returns {Promise<*>}
 */
export const requestStorageAccess = async (
    organizationId,
    { successCallback, errorCallback, finallyCallback } = {},
) => {
    return sendPatchRequest({
        url: `/organization/${organizationId}/storage/request`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
        }),
    });
};
