import { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import LoadingButton from '@mui/lab/LoadingButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import styles from './styles';

import { convertScopeText } from '../../../utils/convertScopeUtils';
import { ACCESS_TOKEN_SCOPES } from '../../../config/constants';
import { getDateString, isDateAfterToday } from '../../../utils/dateUtils';
import { addAccessToken, updateAccessToken } from '../../../utils/accessTokenUtils';

import { logError } from '../../../utils/envUtils';
import DisplayAccessTokenApiKeyModal from '../../Modals/DisplayAccessTokenApiKeyModal';

const CreateAccessTokenModal = ({ dialogType, handleClose, token, setAccessTokens, userId: _userId }) => {
    const [note, setNote] = useState(''),
        [userId, setUserId] = useState(''),
        [expiration, setExpiration] = useState(5),
        [customExpiration, setCustomExpiration] = useState(new Date()),
        [tokenScopes, setScopes] = useState([]),
        [apiKey, setKey] = useState(''),
        [saving, setSaving] = useState(false),
        [errors, setErrors] = useState({
            note: false,
        });

    useEffect(() => {
        setUserId(_userId);
    }, [_userId]);

    useEffect(() => {
        if (token) {
            setNote(token.note);
            setCustomExpiration(token.expiration);
            setScopes(token.scopes);
        }
    }, [token]);

    const EXPIRATION_VALUES = [5, 7, 30, 60, 90], // days
        isEditDialogType = dialogType === 'edit';

    const handleTokenChange = (field, { target }) => {
        const value = target.value;

        switch (field) {
            case 'note':
                if (errors.note) {
                    setErrors((prevState) => ({
                        ...prevState,
                        note: false,
                    }));
                }
                if (note.length <= 100) {
                    setNote(value);
                }
                return;

            case 'expiration':
                setExpiration(value);
                return;

            case 'scopes':
                if (value === 'all') {
                    setScopes(target.checked ? ACCESS_TOKEN_SCOPES : []);
                } else {
                    if (target.checked) {
                        setScopes((prevState) => [...prevState, value]);
                    } else {
                        setScopes((prevState) => prevState.filter((scope) => scope !== value));
                    }
                }
                return;

            default:
                return;
        }
    };

    const handleSaveToken = async () => {
        // Check and set errors
        if (errors.note) return;

        if (note.length === 0) {
            setErrors((prevState) => ({
                ...prevState,
                note: true,
            }));
            return;
        }

        setSaving(true);

        if (dialogType === 'new') {
            const newToken = {
                note: note,
                expiration: '',
                scopes: tokenScopes,
            };

            // Custom Expiration
            if (expiration === 0) {
                if (customExpiration.$d) {
                    newToken.expiration = customExpiration.$d.toUTCString();
                } else {
                    newToken.expiration = customExpiration.toUTCString();
                }
            } else {
                const date = new Date();
                date.setDate(date.getDate() + expiration);
                newToken.expiration = date.toUTCString();
            }

            try {
                const response = await addAccessToken(newToken, userId);
                setAccessTokens(response.data.accessTokens);
                setKey(response.data.key);
            } catch (e) {
                logError('handleSaveToken: ', { dialogType }, e);
            } finally {
                setSaving(false);
            }
        } else if (isEditDialogType) {
            if (note === token.note) {
                setSaving(false);
                return;
            }

            try {
                const response = await updateAccessToken(token._id, { note }, userId);
                setAccessTokens(response.data.accessTokens);
            } catch (e) {
                logError('handleSaveToken: ', { dialogType }, e);
            } finally {
                setSaving(false);
                handleClose();
            }
        }
    };

    const handleApiModalClose = () => {
        setKey('');
        handleClose();
    };

    const renderAccessTokenExpiration = (value) => {
        if (expiration === '') return 'Expiration';

        return value > 0 ? `${value} Days` : 'Custom';
    };

    const ScopeContainer = () => (
        <Box sx={styles.scopeContainer}>
            <FormControlLabel
                label="All"
                control={
                    <Checkbox
                        key="all"
                        value="all"
                        checked={tokenScopes.length === ACCESS_TOKEN_SCOPES.length}
                        onClick={(e) => handleTokenChange('scopes', e)}
                        disabled={isEditDialogType}
                    />
                }
            />
            {ACCESS_TOKEN_SCOPES.map((scope) => (
                <FormControlLabel
                    key={scope}
                    label={convertScopeText(scope)}
                    control={
                        <Checkbox
                            key={scope}
                            value={scope ?? ''}
                            checked={tokenScopes.includes(scope)}
                            onClick={(e) => handleTokenChange('scopes', e)}
                            disabled={isEditDialogType}
                        />
                    }
                />
            ))}
        </Box>
    );

    return (
        <>
            <Dialog
                keepMounted
                open={dialogType !== 'none' && apiKey === ''}
                onClose={handleClose}
                maxWidth="md"
                aria-labelledby="create-access-token-modal"
            >
                <Box sx={styles.mainContainer}>
                    <DialogTitle sx={styles.headingContainer}>
                        <Typography
                            sx={{
                                fontSize: 24,
                            }}
                        >
                            {dialogType === 'new' ? 'New Access Token' : 'Edit Access Token'}
                        </Typography>
                        <Typography
                            sx={{
                                color: 'text.secondary',
                                fontSize: 16,
                            }}
                        >
                            Access Tokens are used to access our APIs.
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container sx={styles.newEditTokenContainer}>
                            <Grid item xs={3} sx={styles.componentNameContainer}>
                                <Typography>Note</Typography>
                            </Grid>
                            <Grid item xs={9} sx={styles.componentContainer}>
                                <TextField
                                    sx={styles.tokenNoteTextfield}
                                    id="note"
                                    autoFocus
                                    required
                                    variant="outlined"
                                    label="Token Note"
                                    placeholder="Note"
                                    error={errors.note}
                                    value={note}
                                    onChange={(e) => handleTokenChange('note', e)}
                                />
                            </Grid>
                            <Grid item xs={3} sx={styles.componentNameContainer}>
                                <Typography>Expiration</Typography>
                            </Grid>

                            <Grid item xs={!isEditDialogType ? 4 : 3} sx={styles.componentContainer}>
                                {!isEditDialogType ? (
                                    <Select
                                        id="access-token-expiry-select"
                                        sx={styles.tokenExpirationSelect}
                                        value={expiration}
                                        onChange={(e) => handleTokenChange('expiration', e)}
                                        renderValue={renderAccessTokenExpiration}
                                    >
                                        {EXPIRATION_VALUES.map((val, i) => (
                                            <MenuItem key={`menu-item-${i}`} value={val}>{`${val} Days`}</MenuItem>
                                        ))}
                                        <MenuItem value={0}>Custom</MenuItem>
                                    </Select>
                                ) : (
                                    <Typography>Expires On:</Typography>
                                )}
                            </Grid>
                            <Grid item xs={!isEditDialogType ? 5 : 6} sx={styles.componentContainer}>
                                {expiration === 0 && (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Expiration"
                                            value={customExpiration}
                                            onChange={(newValue) => {
                                                setCustomExpiration(newValue);
                                            }}
                                            disablePast
                                            disabled={expiration !== 0}
                                            renderInput={(params) => <TextField sx={{ display: 'flex' }} {...params} />}
                                        />
                                    </LocalizationProvider>
                                )}

                                {isEditDialogType && (
                                    <span style={{ color: isDateAfterToday(token.expiration) ? 'green' : 'red' }}>
                                        {getDateString(token.expiration)}
                                    </span>
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={3}
                                sx={{ ...styles.componentNameContainer, alignItems: 'flex-start', paddingTop: 2 }}
                            >
                                <Typography>Scopes</Typography>
                            </Grid>
                            <Grid item xs={9} sx={styles.componentContainer}>
                                <ScopeContainer />
                            </Grid>
                        </Grid>
                        <Box sx={styles.buttonContainer}>
                            <Button variant="contained" color="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            <LoadingButton
                                variant="contained"
                                color="secondary"
                                sx={{ marginLeft: 4 }}
                                loading={saving}
                                onClick={handleSaveToken}
                            >
                                {dialogType === 'new' ? 'Generate' : 'Save'}
                            </LoadingButton>
                        </Box>
                    </DialogContent>
                </Box>
            </Dialog>

            <DisplayAccessTokenApiKeyModal apiKey={apiKey} handleCloseModal={handleApiModalClose} />
        </>
    );
};

CreateAccessTokenModal.propTypes = {
    token: PropTypes.object,
    userId: PropTypes.string.isRequired,
    dialogType: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    setAccessTokens: PropTypes.func.isRequired,
};

export default memo(CreateAccessTokenModal);
