import store from '../redux/store';
import { useSelector } from 'react-redux';
import { getStoreState } from './useStoreStateHook';

/**
 * ACTIONS
 */
export const ORGANIZATION_USERS_DATA_ACTIONS = {
    UPDATE_ORGANIZATION_USERS_DATA: 'organizationUsersDataList',
    UPDATE_RECENTLY_UPDATED_ORGANIZATION_USER_ID: 'recentlyUpdatedOrganizationUserId',
    UPDATE_SELECTED_ORGANIZATION_FOR_MODAL_ACTION: 'selectedOrganizationUserForModalAction',
};

const organizationUsersDataActionType = ORGANIZATION_USERS_DATA_ACTIONS.UPDATE_ORGANIZATION_USERS_DATA;

const selectedOrganizationUserForModalActionType =
    ORGANIZATION_USERS_DATA_ACTIONS.UPDATE_SELECTED_ORGANIZATION_FOR_MODAL_ACTION;

const recentlyUpdatedOrganizationUserIdActionType =
    ORGANIZATION_USERS_DATA_ACTIONS.UPDATE_RECENTLY_UPDATED_ORGANIZATION_USER_ID;

/**
 * Get the organizationUsers data states and subscribe to changes
 * @returns {object} An object containing  the organizationUsers table states
 */
export const useOrganizationUsersData = () => {
    return useSelector((state) => {
        return state?.organizationUsersData?.[organizationUsersDataActionType] || [];
    });
};

/**
 * Get the selected organizationUser data state and subscribe to changes
 * @returns {object} Selected organizationUser data
 */
export const useSelectedOrganizationUserData = () =>
    useSelector((state) => state?.organizationUsersData?.[selectedOrganizationUserForModalActionType] || {});

/**
 * Get the selected organizationUser data state and subscribe to changes
 * @returns {object} Selected organizationUser data
 */
export const useRecentlyUpdatedOrganizationUserId = () =>
    useSelector((state) => state?.organizationUsersData?.[recentlyUpdatedOrganizationUserIdActionType] || {});

/**
 * Update the organizationUsers data
 *
 * @param {array} payload List of organizationUsers data
 * @returns {void}
 */
export const setOrganizationUsersData = (payload) => {
    store.dispatch({
        payload,
        type: organizationUsersDataActionType,
    });
};

/**
 * Update the selected organizationUser data
 *
 * @param {object} payload Selected organizationUser data
 * @returns {void}
 */
export const setSelectedOrganizationUserData = (payload) => {
    store.dispatch({
        payload,
        type: selectedOrganizationUserForModalActionType,
    });
};

/**
 * Set the recently updated organizationUser ID
 *
 * @param {string|array} payload Recently updated organizationUser ID(s)
 * @returns {void}
 */
export const setRecentlyUpdatedOrganizationUserId = (payload) => {
    store.dispatch({
        payload,
        type: recentlyUpdatedOrganizationUserIdActionType,
    });
};

/**
 * Optimistically update the organizationUser data after any changes
 * instead of refetching the data from the server.
 *
 * Note: Minimum requirement for data to be updated is the organizationUser ID
 * along with any other existing property(ies) that exists in the
 * organizationUser data.
 *
 * @param {string} actionType Type of action to perform. Possible values: 'update', 'delete'
 * @param {object} changedOrganizationUserData Changed organizationUser data.
 */
export const updateOrganizationUsersOptimistically = (actionType, changedOrganizationUserData = {}) => {
    let organizationUsersList = [];
    const organizationUsersDataStates = store?.getState()?.organizationUsersData,
        organizationUsers = organizationUsersDataStates?.[organizationUsersDataActionType],
        isDeleteActionType = actionType === 'delete',
        selectedOrganizationUser = organizationUsersDataStates?.[selectedOrganizationUserForModalActionType],
        selectedOrganizationUserId = selectedOrganizationUser?.userId,
        changedOrganizationUserDataId = changedOrganizationUserData?.userId;

    if (actionType === 'update') {
        organizationUsersList = organizationUsers?.map((organizationUser) => {
            if (changedOrganizationUserDataId !== organizationUser?.userId) {
                return organizationUser;
            }
            return changedOrganizationUserData;
        });
    } else if (isDeleteActionType) {
        organizationUsersList = organizationUsers?.filter(
            (organizationUser) => selectedOrganizationUserId !== organizationUser?.userId,
        );
    }

    // This should never happen, but lets's bail data just in case
    // {organizationUsersList} is invalid
    if (!organizationUsersList) return;

    setOrganizationUsersData(organizationUsersList);

    // This is use to highlight the updated organizationUser row in table
    const recentlyUpdatedOrganizationUserId = isDeleteActionType
        ? selectedOrganizationUserId
        : changedOrganizationUserDataId;

    setRecentlyUpdatedOrganizationUserId(recentlyUpdatedOrganizationUserId);

    // Clear the highlight organizationUser row after 4 seconds
    setTimeout(() => {
        setRecentlyUpdatedOrganizationUserId('');
    }, 4000);
};

/**
 * Get the organizationUsers data
 * @returns {array} List of organizationUsers data
 */
export const getOrganizationUsersData = () => getStoreState(organizationUsersDataActionType)([]);
