import { getStoreState, setStoreState } from '../hooks/useStoreStateHook';
import { AUTH_FORM_TABS } from '../config/constants';

/**
 * Set the auth form tab index
 * @param {number} tabIndex Current tab index
 */
const handleAuthFormTab = (tabIndex) => {
    setStoreState('authFormTab')(tabIndex);
};

/**
 * Get the auth form tab index
 * @returns {number} Current auth form tab index
 */
export const getCurrentAuthFormTab = () => getStoreState('authFormTab')();

/**
 * Switch to registration auth form tab
 */
export const switchToLoginAuthFormTab = () => {
    handleAuthFormTab(AUTH_FORM_TABS.login);
};

/**
 * Switch to registration auth form tab
 */
export const switchToRegistrationAuthFormTab = () => {
    handleAuthFormTab(AUTH_FORM_TABS.register);
};

/**
 * Switch to MFA auth form tab
 */
export const switchToMfaAuthFormTab = () => {
    handleAuthFormTab(AUTH_FORM_TABS.mfa);
};

/**
 * Switch to verify code auth form tab
 */
export const switchToVerifyCodeAuthFormTab = () => {
    handleAuthFormTab(AUTH_FORM_TABS.verifyCode);
};

/**
 * Switch to verify code auth form tab
 */
export const switchToResendVerificationCodeAuthFormTab = () => {
    handleAuthFormTab(AUTH_FORM_TABS.resendVerificationCode);
};

/**
 * Switch to reset password auth form tab
 */
export const switchToResetPasswordAuthFormTab = () => {
    handleAuthFormTab(AUTH_FORM_TABS.resetPassword);
};
