import { Typography } from '@mui/material';
import { getSelectedLogNameItem, setViewLogRequest } from 'hooks/useOrganizationLoggingHook';
import { descriptionTableColumnData, nameTableColumnData } from 'utils/tableUtils';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import DescriptionTooltip from 'components/Tooltips/DescriptionTooltip';
import ResponsesStatus from 'components/ResponseStatus';

/**
 * Render date
 * @param {string} column
 * @returns {function}
 */
const renderDate = (column) => (row) => {
    const date = row[column];
    if (!date) return '—';

    return date?.split('T').join(' ')?.split('Z')?.[0];
};

/**
 * Log action buttons
 * @param {bool} isTooltip
 * @param {object} row
 * @returns {React.Component}
 */
const renderLogId = (isTooltip, row) => (
    <Typography component="div" className="logIdField" style={{ fontSize: '13px !important' }}>
        {isTooltip ? 'Log ID: ' : ''} {row?._id}
    </Typography>
);

/**
 *
 * @param {{ row: object, view: 'request'|'response' }} param0
 */
const handleViewLogRequestAction =
    ({ row, view, title }) =>
    () => {
        setViewLogRequest({
            view,
            title,
            open: true,
            data: row,
        });
    };

/**
 * Log action buttons
 * @param {bool} isTooltip
 * @param {object} row
 * @returns {React.Component}
 */
const renderViewLogActionButtons = (isTooltip = false, row) => (
    <div className="actionButtons" style={{ marginTop: isTooltip ? '10px' : 0 }}>
        <PrimaryButton
            height="30px"
            padding="0px 7px"
            fontSize="12px"
            width="auto"
            margin="0px 5px 5px 0px"
            borderRadius="3px"
            color="primary"
            backgroundColor="#fff"
            className="viewRequestBtn"
            onClick={handleViewLogRequestAction({ row, view: 'request', title: 'View Request' })}
        >
            View Request
        </PrimaryButton>
        <PrimaryButton
            height="30px"
            padding="0px 7px"
            color="primary"
            backgroundColor="#fff"
            fontSize="12px"
            width="auto"
            margin="0px 0px 5px 0px"
            borderRadius="3px"
            onClick={handleViewLogRequestAction({ row, view: 'response', title: 'View Response' })}
        >
            View Response
        </PrimaryButton>
    </div>
);

/**
 * Table Columns
 * @param {Object} props List of properties to apply to the column
 * @param {Object} props.navigate useNavigate() object
 * @returns {Array<React.Element>}
 */
const TableColumns = () => {
    const selectedLogNameItem = getSelectedLogNameItem(),
        logNameColumn = {
            grow: 1.6,
            title: 'Integration Workflow Name',
            fieldName: 'integrationWorkflowName',
            extraColumns: [],
        };

    let actionColumnGrow = 2.2;

    switch (selectedLogNameItem?.name) {
        case 'comparison':
            logNameColumn.grow = 1.5;
            logNameColumn.title = 'Name';
            logNameColumn.fieldName = 'comparisonName';
            logNameColumn.extraColumns = [
                descriptionTableColumnData({
                    grow: 1,
                    name: 'Executed By',
                    fieldName: 'executedBy',
                }),
                descriptionTableColumnData({
                    grow: 1,
                    name: 'Executed At',
                    fieldName: 'executedAt',
                    renderCallback: renderDate('executedAt'),
                }),
                descriptionTableColumnData({
                    grow: 1,
                    name: 'Execution Time',
                    fieldName: 'executionTime',
                    renderCallback: (row) => {
                        const executionEndedAt = new Date(Date.parse(row.executionEndedAt)),
                            executedAtTime = new Date(Date.parse(row.executedAt)),
                            diffTime = Math.abs(executionEndedAt.getTime() - executedAtTime.getTime());
                        let timeRan;

                        if (diffTime >= 10000) {
                            timeRan = (diffTime / 1000).toFixed(2) + ' sec';
                        } else {
                            timeRan = diffTime + ' ms';
                        }

                        return (
                            <Typography component="div" className="logIdField">
                                {timeRan}
                            </Typography>
                        );
                    },
                }),
            ];
            break;
        case 'mappings':
            logNameColumn.grow = 1.5;
            logNameColumn.title = 'Name';
            logNameColumn.fieldName = 'mappingName';
            logNameColumn.extraColumns = [
                descriptionTableColumnData({
                    grow: 1,
                    name: 'Executed By',
                    fieldName: 'executedBy',
                }),
                descriptionTableColumnData({
                    grow: 1,
                    name: 'Executed At',
                    fieldName: 'executedAt',
                    renderCallback: renderDate('executedAt'),
                }),
            ];
            break;
        case 'integrations':
            logNameColumn.grow = 1.5;
            logNameColumn.title = 'Name';
            logNameColumn.fieldName = 'integrationName';
            logNameColumn.extraColumns = [
                descriptionTableColumnData({
                    grow: 1,
                    name: 'Executed By',
                    fieldName: 'executedBy',
                }),
                descriptionTableColumnData({
                    grow: 1,
                    name: 'Executed At',
                    fieldName: 'executedAt',
                    renderCallback: renderDate('executedAt'),
                }),
            ];
            break;
        case 'api':
            logNameColumn.grow = 2;
            logNameColumn.title = 'Route';
            logNameColumn.fieldName = 'route';
            logNameColumn.extraColumns = [
                descriptionTableColumnData({
                    grow: 1,
                    name: 'IP Address',
                    fieldName: 'ipAddress',
                    renderCallback: (row) => (
                        <Typography component="div" className="logIdField">
                            {row.ipAddress || '—'}
                        </Typography>
                    ),
                }),
                descriptionTableColumnData({
                    grow: 1.2,
                    name: 'Start Time',
                    fieldName: 'startTime',
                    renderCallback: renderDate('startTime'),
                }),
                descriptionTableColumnData({
                    grow: 1.2,
                    name: 'End Time',
                    fieldName: 'endTime',
                    renderCallback: renderDate('endTime'),
                }),
            ];
            break;

        case 'app-request':
            actionColumnGrow = 1.5;

            logNameColumn.grow = 1;
            logNameColumn.title = 'App Name';
            logNameColumn.fieldName = 'appName';
            logNameColumn.extraColumns = [
                descriptionTableColumnData({
                    grow: 1,
                    name: 'Interface',
                    fieldName: 'interfaceName',
                    renderCallback: (row) => (
                        <Typography component="div" className="logIdField">
                            {row.interfaceName || '—'}
                        </Typography>
                    ),
                }),
                descriptionTableColumnData({
                    grow: 1.2,
                    name: 'App Instance',
                    fieldName: 'appInstanceName',
                    renderCallback: (row) => (
                        <Typography component="div" className="logIdField">
                            {row.appInstanceName || '—'}
                        </Typography>
                    ),
                }),
                descriptionTableColumnData({
                    grow: 1,
                    name: 'Resource',
                    fieldName: 'resourceName',
                    renderCallback: (row) => (
                        <Typography component="div" className="logIdField">
                            {row.resourceName || '—'}
                        </Typography>
                    ),
                }),
                descriptionTableColumnData({
                    grow: 1.2,
                    name: 'Action Name',
                    fieldName: 'actionName',
                    renderCallback: (row) => (
                        <Typography component="div" className="logIdField">
                            {row.actionName || '—'}
                        </Typography>
                    ),
                }),
                descriptionTableColumnData({
                    grow: 1.2,
                    name: 'Start Time',
                    fieldName: 'startTime',
                    renderCallback: renderDate('startTime'),
                }),
                descriptionTableColumnData({
                    grow: 1.2,
                    name: 'End Time',
                    fieldName: 'endTime',
                    renderCallback: renderDate('endTime'),
                }),
            ];
            break;

        default:
            break;
    }

    return [
        descriptionTableColumnData({
            grow: 0.7,
            name: 'Log ID',
            fieldName: 'logId',
            tooltipProps: false,
            renderCallback: (row) => (
                <DescriptionTooltip
                    title={
                        <div className="logTooltip">
                            {renderLogId(true, row)}
                            {renderViewLogActionButtons(true, row)}
                        </div>
                    }
                    text={renderLogId(false, row)}
                    tooltipProps={{ placement: 'right' }}
                    rootStyle={{ marginTop: '25px' }}
                />
            ),
        }),
        descriptionTableColumnData({
            grow: logNameColumn.grow,
            name: logNameColumn.title,
            fieldName: logNameColumn.fieldName,
            renderCallback: (row) => (
                <Typography component="div" className="logIdField">
                    {row[logNameColumn.fieldName] || '—'}
                </Typography>
            ),
        }),
        ...logNameColumn.extraColumns,
        descriptionTableColumnData({
            grow: 0.5,
            name: 'Status',
            fieldName: 'statusCode',
            renderCallback: (row) => <ResponsesStatus statusCode={row?.statusCode || 0} />,
        }),
        nameTableColumnData({
            grow: actionColumnGrow,
            name: 'Actions',
            fieldName: '_id',
            renderCallback: (row) => renderViewLogActionButtons(false, row),
        }),
    ];
};

export default TableColumns;
