import { useEffect } from 'react';
import { logout } from '../../utils/loginUtils';
import { useSearchParams } from 'react-router-dom';

const Logout = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (typeof window !== 'undefined') {
            logout('', true, searchParams.has('forbidden'));
        }
    }, []);

    return <></>;
};

export default Logout;
