import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import Header from '../../Header';
import Box from '@mui/material/Box';
import styles from './styles';

const AppRoutesContainer = ({ isUserLoggedIn }) => {
    return (
        <Box style={styles.root} className="appRoutesContainer">
            <Header isUserLoggedIn={isUserLoggedIn} />
            <Outlet />
        </Box>
    );
};

AppRoutesContainer.propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
};

export default AppRoutesContainer;
