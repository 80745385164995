import { useRef, useState, useEffect } from 'react';

import TextField from 'components/InputFields/TextField';
import SearchIcon from 'assets/icons/Search';
import { fetchLogs } from 'utils/loggingUtils';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { hideSnackBarNotification, showSnackBarErrorNotification } from 'utils/snackBarNotificationUtils';
import {
    getLogFilterData,
    setLogFilterData,
    useSelectedLog,
    useSelectedLogNameItem,
} from 'hooks/useOrganizationLoggingHook';

const SearchLogInput = () => {
    const inputRef = useRef(),
        [error, setError] = useState(false),
        selectedLogType = useSelectedLogNameItem(),
        { hasSearchResults } = useSelectedLog();

    useEffect(() => {
        if (inputRef.current) inputRef.current.value = '';
    }, [selectedLogType]);

    const handleLogSearch = async (e, keyword = null) => {
        if (error) {
            hideSnackBarNotification();
            setError(false);
        }

        const searchText = e ? inputRef.current.value : keyword;

        if (!searchText) {
            setError(true);
            showSnackBarErrorNotification('Search keyword is required');
            return false;
        }
        if (searchText?.length < 3) {
            setError(true);
            showSnackBarErrorNotification('Search keyword minimum character length is 3');
            return;
        }

        setLogFilterData({
            ...getLogFilterData(),
            searchText,
        });

        fetchLogs();
    };

    const handleClearSearch = async () => {
        fetchLogs({ clearSearchFilters: true });
        inputRef.current.value = '';
    };

    const searchFieldProps = {
            InputProps: {
                endAdornment: <SearchIcon onClick={handleLogSearch} />,
            },
            onKeyUp: async (e) => {
                e?.key === 'Enter' && handleLogSearch(false, inputRef.current.value);
            },
        },
        searchInputStyle = {
            height: '35px',
            marginTop: '0px',
            marginRight: '20px',

            '& .MuiOutlinedInput-root': {
                height: '33px',
                minWidth: '230px',
                borderRadius: '4px',

                '& input': {
                    // padding: 0,
                    borderRadius: '0px',
                },
            },

            '& svg': {
                fontSize: '14px',
                cursor: 'pointer',
            },
        };

    return (
        <div className="searchInputWrapper">
            <TextField
                sx={searchInputStyle}
                placeholder="Search"
                renderWithGrid={false}
                inputRef={inputRef}
                label={null}
                required={false}
                inputProps={searchFieldProps}
                showError={false}
            />

            {hasSearchResults && (
                <PrimaryButton
                    className="clearSearchBtn"
                    fontSize="13px"
                    margin="-1px 20px 0px -15px"
                    padding="0px"
                    width="130px"
                    height="32px"
                    onClick={handleClearSearch}
                >
                    Clear filters
                </PrimaryButton>
            )}
        </div>
    );
};

export default SearchLogInput;
