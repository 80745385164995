import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

const SuccessRateIcon = (props) => {
    const fill = props.fill || '#F2994A';

    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.8125 11.5312L5.09375 13.4375C4.625 13.5625 4.59375 14.2188 5.03125 14.4375L7.15625 15.4688L4.375 18.25C4.1875 18.4375 4.1875 18.75 4.375 18.9375L5.0625 19.625C5.25 19.8125 5.5625 19.8125 5.75 19.625L8.53125 16.8438L9.5625 18.9688C9.78125 19.4062 10.4375 19.375 10.5625 18.9062L12.4688 12.1875C12.5938 11.7812 12.2188 11.4375 11.8125 11.5312ZM12.6562 15.1875L12.2188 16.75C14.7188 16.625 16.75 14.5625 16.75 12C16.75 9.375 14.625 7.25 12 7.25C9.4375 7.25 7.375 9.28125 7.25 11.7812L8.8125 11.3438C9.125 9.875 10.4375 8.75 12 8.75C13.7812 8.75 15.25 10.2188 15.25 12C15.25 13.5625 14.125 14.875 12.6562 15.1875ZM12 4.25C7.71875 4.25 4.25 7.75 4.25 12C4.25 12.25 4.25 12.4688 4.28125 12.6875C4.375 12.625 4.5 12.5625 4.625 12.5312L5.75 12.2188C5.75 12.1562 5.75 12.0938 5.75 12C5.75 8.5625 8.53125 5.75 12 5.75C15.4375 5.75 18.25 8.5625 18.25 12C18.25 15.4688 15.4375 18.25 12 18.25C11.9062 18.25 11.8438 18.25 11.7812 18.25L11.4688 19.375C11.4375 19.5 11.375 19.625 11.3125 19.7188C11.5312 19.75 11.75 19.75 12 19.75C16.25 19.75 19.75 16.2812 19.75 12C19.75 7.75 16.25 4.25 12 4.25Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

SuccessRateIcon.propTypes = {
    fill: PropTypes.string,
};

export default SuccessRateIcon;
