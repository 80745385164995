import { useEffect, memo } from 'react';
import { PropTypes } from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useStoreState } from '../../../hooks/useStoreStateHook';
import { deleteAccessToken } from '../../../utils/accessTokenUtils';

import CreateAccessTokenModal from '../../Modals/CreateAccessTokenModal';
import PersonalAccessTokensTable from '../../Tables/PersonalAccessTokensTable';
import ConfirmDeleteAccessTokenModal from '../../Modals/ConfirmDeleteAccessTokenModal';

const PersonalAccessTokens = ({ userId, userAccessTokens }) => {
    const [tokens, setTokens] = useStoreState('personalAccessTokens')([]),
        [tokenDialog, setTokenDialog] = useStoreState('createPersonalAccessTokenModal')('none'),
        [currentToken, setCurrentToken] = useStoreState('currentPersonalAccessToken')(null),
        [confirmDeleteToken, setConfirmDeleteToken] = useStoreState('confirmDeletePersonalAccessTokenModal')(false);

    useEffect(() => {
        setTokens(userAccessTokens);
        /* eslint-disable */
    }, [userAccessTokens]);

    const handleNewTokenOnClick = () => {
        setCurrentToken({
            note: '',
            scopes: [],
            expiration: new Date(),
        });
        setTokenDialog('new');
    };

    const handleEditAccessToken = (token) => () => {
        setCurrentToken(token);
        setTokenDialog('edit');
    };

    const handleDeleteAccessToken = (token) => () => {
        setCurrentToken(token);
        setConfirmDeleteToken(true);
    };

    const handleDialogClose = () => {
        setTokenDialog('none');
        setCurrentToken(null);
    };

    const handleDeleteAlertClose = () => {
        setCurrentToken(null);
        setConfirmDeleteToken(false);
    };

    const handleDeleteToken = async () => {
        if (currentToken !== null && currentToken._id !== undefined) {
            try {
                const response = await deleteAccessToken(currentToken._id, userId);
                setTokens(response.data.accessTokens);
            } catch (e) {}
            handleDeleteAlertClose();
        }
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: 4,
                    marginBottom: 4,
                    width: '100%',
                }}
            >
                <Typography
                    sx={{
                        fontSize: 24,
                    }}
                >
                    Personal Access Token
                </Typography>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                        minWidth: 180,
                        height: 40,
                    }}
                    onClick={handleNewTokenOnClick}
                >
                    Create New Token
                </Button>
            </Box>

            {!tokens.length ? (
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <Typography sx={{ fontSize: 18 }}>Add new access tokens to view them here.</Typography>
                </Box>
            ) : (
                <PersonalAccessTokensTable
                    tokens={tokens}
                    handleEditAccessToken={handleEditAccessToken}
                    handleDeleteAccessToken={handleDeleteAccessToken}
                />
            )}

            <ConfirmDeleteAccessTokenModal
                open={confirmDeleteToken}
                handleCancelAction={handleDeleteAlertClose}
                handleApprovalAction={handleDeleteToken}
            />

            {tokenDialog !== 'none' && (
                <CreateAccessTokenModal
                    token={currentToken}
                    userId={userId}
                    dialogType={tokenDialog}
                    handleClose={handleDialogClose}
                    setAccessTokens={setTokens}
                />
            )}
        </>
    );
};

PersonalAccessTokens.propTypes = {
    userId: PropTypes.string.isRequired,
    userAccessTokens: PropTypes.array.isRequired,
};

export default memo(PersonalAccessTokens);
