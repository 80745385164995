import * as constants from 'config/constants';

export default {
    root: (isMenuOpen) => ({
        width: isMenuOpen ? `${constants.SIDEBAR_WIDTH}px` : `${constants.SIDEBAR_COLLAPSE_WIDTH}px`,
        height: '100%',
        backgroundColor: '#4F4F4F',
        display: 'flex',
        flexDirection: 'column',
    }),
    wrapper: {
        padding: '20px',
        width: '100%',
    },
    divider: {
        borderColor: '#636363',
    },
    applicationMenuDivider: {
        borderColor: '#636363',
        marginBottom: '5px',
    },
    title: {
        fontWeight: 700,
        fontSize: '14px !important',
        lineHeight: '16px',
        color: '#FFFFFF',
        fontFamily: 'Roboto !important',
        textTransform: 'uppercase',
        marginBottom: '12px',
    },
    menuIcon: {
        width: '16px',
        height: '16px',
        fontSize: '25px',
    },
    profileIcon: {
        width: '24px',
        height: '24px',
    },
    orgUserMenuIcon: {
        width: '20px',
        height: '20px',
    },
    orgVpnMenuIcon: {
        width: '20px',
        height: '20px',
    },
    orgSettingMenuIcon: {
        width: '18x',
        height: '18px',
    },
    billingMenuIcon: {
        width: '18px',
        height: '18px',
    },
    bottomMenu: {
        cursor: 'pointer',
        pointerEvent: 'none',
        '&:hover': {
            backgroundColor: '#444444',
        },
    },
    feedbackButtonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        width: constants.SIDEBAR_WIDTH,
        backgroundColor: '#444444',
    },
    menuButton: (isMenuOpen) => ({
        width: '100%',
        display: 'flex',
        paddingTop: '10px',
        paddingLeft: isMenuOpen ? '15px' : '10px',
        paddingRight: '10px',
        justifyContent: 'flex-end',
    }),
    menuButtonIcon: {
        fill: '#fff',
        fontSize: '23px',
    },
};
