const styles = {
    mainContainer: {
        width: 560,
        display: 'flex',
        flexDirection: 'column',
    },
    headingContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    newEditTokenContainer: {
        border: '1px solid #E0E0E0',
        marginTop: 4,
        marginBottom: 8,
        borderRadius: 2,
    },
    componentNameContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 2,
    },
    componentContainer: {
        display: 'flex',
        paddingTop: 2,
        paddingRight: 2,
        paddingBottom: 2,
        alignItems: 'center',
    },
    tokenNoteTextfield: {
        flexGrow: 1,
    },
    tokenExpirationSelect: {
        border: 'none',
        backgroundColor: 'white',
        flexGrow: 1,
    },
    scopeContainer: {
        border: '1px solid #E0E0E0',
        flexGrow: 1,
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 1,
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
};

export default styles;
