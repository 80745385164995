import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const DisplayAccessTokenApiKeyModal = ({ apiKey, handleCloseModal }) => {
    const [value, setValue] = useState(apiKey);

    useEffect(() => {
        setValue(apiKey);
    }, [apiKey]);

    const handleCopyAccessTokenApiKey = async () => {
        await navigator.clipboard.writeText(apiKey);
    };

    return (
        <Dialog keepMounted open={apiKey !== ''} onClose={handleCloseModal}>
            <DialogTitle>Access Token</DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
                This is your access token. This will only be shown once, make sure to copy and keep it somewhere safe.
                <TextField
                    sx={{ marginTop: 2 }}
                    id="access-token-api-text"
                    label="Access Token"
                    value={value}
                    /* eslint-disable */
                    onChange={() => {}}
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={handleCopyAccessTokenApiKey}>
                                <ContentCopyIcon />
                            </IconButton>
                        ),
                    }}
                />
            </DialogContent>
            <Button sx={{ padding: '5px 10px' }} onClick={handleCloseModal}>
                Close
            </Button>
        </Dialog>
    );
};

DisplayAccessTokenApiKeyModal.propTypes = {
    apiKey: PropTypes.string.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
};

export default DisplayAccessTokenApiKeyModal;
