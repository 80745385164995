import { SvgIcon } from '@mui/material';

const MyProfile = (props) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 13C14.4688 13 16.5 11 16.5 8.5C16.5 6.03125 14.4688 4 12 4C9.5 4 7.5 6.03125 7.5 8.5C7.5 11 9.5 13 12 13ZM12 5.5C13.625 5.5 15 6.875 15 8.5C15 10.1562 13.625 11.5 12 11.5C10.3438 11.5 9 10.1562 9 8.5C9 6.875 10.3438 5.5 12 5.5ZM14.8438 13.5312L13.0938 18.3438L12.5 15.75L13.5 14H10.5L11.5 15.75L10.875 18.3438L9.125 13.5312C6.8125 13.5625 5 15.4062 5 17.7188V18.5C5 19.3438 5.65625 20 6.5 20H17.5C18.3125 20 19 19.3438 19 18.5V17.7188C19 15.4062 17.1562 13.5625 14.8438 13.5312ZM8 18.5H6.5V17.7188C6.5 16.5938 7.15625 15.6562 8.15625 15.2188L9.34375 18.5H8ZM17.5 18.5H14.625L15.8438 15.2188C16.8125 15.6562 17.5 16.5938 17.5 17.7188V18.5Z"
                fill="white"
            />
        </SvgIcon>
    );
};
export default MyProfile;
