import LoggingContentHeader from '../LoggingContentHeader';
import OrganizationLoggingTable from 'components/Tables/OrganizationLoggingTable';

const LoggingContent = () => {
    return (
        <div className="loggingContent">
            <LoggingContentHeader />
            <OrganizationLoggingTable />
        </div>
    );
};

export default LoggingContent;
