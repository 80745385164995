import { memo } from 'react';
import loadable from '@loadable/component';

import AppContainer from './components/Containers/AppContainer';
import PlatformErrorBoundary from 'components/PlatformErrorBoundary';

const Snackbar = loadable(() => import('./components/Snackbar'));

const ConfirmationModal = loadable(() => import('./components/Modals/ConfirmationModal'));

const App = () => (
    <PlatformErrorBoundary>
        <Snackbar />
        <ConfirmationModal />
        <AppContainer />
    </PlatformErrorBoundary>
);

export default memo(App);
