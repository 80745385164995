import FileContentHeader from './Header';
import FileTable from '../../Tables/FilesTable';

import styles from './styles';

const FileContent = () => {
    return (
        <div style={styles.root}>
            <FileContentHeader />
            <FileTable />
        </div>
    );
};

export default FileContent;
