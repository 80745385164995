import { memo } from 'react';

import * as envUtils from '../../utils/envUtils';
import DashboardContainer from './DashboardContainer';
import useDelightedSurvey from '../../hooks/useDelightedSurveyHook';

const Dashboard = () => {
    useDelightedSurvey();

    const urlParameters = envUtils.getUrlParameters(),
        currentOrganizationId = urlParameters.get('currentOrganizationId');

    if (currentOrganizationId) {
        if (localStorage.getItem('currentOrganizationId') !== currentOrganizationId) {
            localStorage.setItem('currentOrganizationId', currentOrganizationId);
        }

        window.history.replaceState(null, 'Dashboard', '/dashboard');
    }

    return <DashboardContainer />;
};

export default memo(Dashboard);
