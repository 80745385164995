import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import styles from './styles';
import DescriptionTooltip from '../../../Tooltips/DescriptionTooltip';

const SidebarButton = ({ icon, label, onClick, style = {}, isActive = false, collapseMenu = false }) => {
    const rootStyle = {
            ...styles.root,
            ...(isActive ? styles.active : {}),
            ...style,
        },
        iconStyle = { ...styles.icon },
        buttonStyle = {
            ...styles.root,
            ...style,
            padding: '5px 10px',
            height: '30px',
            width: 'auto',
            '&:hover': { backgroundColor: 'inherit' },
        };

    if (collapseMenu) {
        rootStyle.width = '50px';
        rootStyle.height = '40px';
        rootStyle.minWidth = '50px';
        rootStyle.alignItems = 'center';
        rootStyle.justifyContent = 'center';

        iconStyle.marginRight = 0;
    }

    return collapseMenu ? (
        <DescriptionTooltip
            text={
                <Button sx={rootStyle} onClick={onClick}>
                    <span style={iconStyle}>{icon}</span>
                </Button>
            }
            title={
                <Button sx={buttonStyle} onClick={onClick}>
                    <span
                        style={{
                            ...styles.icon,
                            marginRight: '6px',
                            position: 'relative',
                            top: '-1px',
                            background: 'inherit',
                            width: 'auto',
                            height: 'auto',
                        }}
                    >
                        {icon}
                    </span>

                    <Typography component="span" sx={{ ...styles.text, fontSize: '14px' }}>
                        {label}
                    </Typography>
                </Button>
            }
            tooltipProps={{
                placement: 'right',
            }}
            rootStyle={{
                marginTop: 0,
            }}
        />
    ) : (
        <Button sx={rootStyle} onClick={onClick}>
            <span style={iconStyle}>{icon}</span>

            <Typography component="span" sx={styles.text}>
                {label}
            </Typography>
        </Button>
    );
};

SidebarButton.propTypes = {
    style: PropTypes.object,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    label: PropTypes.node.isRequired,
    icon: PropTypes.node.isRequired,
    collapseMenu: PropTypes.bool,
};

export default SidebarButton;
