import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import styles from './styles';
import CircularLoader from 'components/Loaders/CircularLoader';

const AppUsageWidget = ({ icon, title, value, isLoading, /*description,*/ sx = {} /*type = ''*/ }) => {
    // let widgetTypeClass = '';
    const rootStyle = { ...styles.root, ...sx };

    // if (type === 'success') {
    //     widgetTypeClass = ' widgetDescriptionSuccess';
    // } else if (type === 'error') {
    //     widgetTypeClass = ' widgetDescriptionError';
    // }

    return (
        <Box sx={rootStyle} className="appUsageWidget">
            <div className="widgetIcon">{icon}</div>
            <Typography component="h4" className="widgetTitle">
                {title}
            </Typography>
            <Typography className="widgetValue">
                {isLoading ? <CircularLoader style={styles.loader} /> : value}
            </Typography>
            {/* <Typography className={`widgetDescription${widgetTypeClass}`}>{description}</Typography> */}
        </Box>
    );
};

AppUsageWidget.propTypes = {
    sx: PropTypes.object,
    icon: PropTypes.node.isRequired,
    // type: PropTypes.string,
    title: PropTypes.node.isRequired,
    value: PropTypes.node.isRequired,
    isLoading: PropTypes.bool,
    // description: PropTypes.node.isRequired,
};

export default AppUsageWidget;
