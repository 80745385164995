import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

const MapperIcon = (props) => {
    const fill = props.fill || 'white';

    return (
        <SvgIcon width="12" height="16" viewBox="0 0 12 16" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.5 6C8.3125 6 7.3125 6.84375 7.0625 7.9375C6.3125 7.84375 5.25 7.5625 4.4375 6.78125C3.90625 6.28125 3.5625 5.625 3.375 4.84375C4.3125 4.5 5 3.59375 5 2.5C5 1.125 3.875 0 2.5 0C1.09375 0 0 1.125 0 2.5C0 3.625 0.71875 4.59375 1.75 4.90625V11.125C0.71875 11.4375 0 12.4062 0 13.5C0 14.9062 1.09375 16 2.5 16C3.875 16 5 14.9062 5 13.5C5 12.4062 4.25 11.4375 3.25 11.125V7.71875C3.28125 7.75 3.34375 7.8125 3.40625 7.875C4.625 9.03125 6.21875 9.375 7.1875 9.46875C7.5625 10.375 8.4375 11.0312 9.5 11.0312C10.875 11.0312 12 9.90625 12 8.53125C12 7.125 10.875 6 9.5 6ZM2.5 1.5C3.03125 1.5 3.5 1.96875 3.5 2.5C3.5 3.0625 3.03125 3.5 2.5 3.5C1.9375 3.5 1.5 3.0625 1.5 2.5C1.5 1.96875 1.9375 1.5 2.5 1.5ZM2.5 14.5C1.9375 14.5 1.5 14.0625 1.5 13.5C1.5 12.9688 1.9375 12.5 2.5 12.5C3.03125 12.5 3.5 12.9688 3.5 13.5C3.5 14.0625 3.03125 14.5 2.5 14.5ZM9.5 9.5C8.9375 9.5 8.5 9.0625 8.5 8.5C8.5 7.96875 8.9375 7.5 9.5 7.5C10.0312 7.5 10.5 7.96875 10.5 8.5C10.5 9.0625 10.0312 9.5 9.5 9.5Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

MapperIcon.propTypes = {
    fill: PropTypes.string,
};

export default MapperIcon;
