import { processResponse, sendGetRequest } from './dataRequestUtils';
import { getIsoDateNow } from './dateUtils';

const ignoreErrorCodes = [404];

/**
 * Get app usage aggregate
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getAppUsageAggregate = ({
    organizationId,
    successCallback,
    errorCallback,
    finallyCallback,
    endDate,
    type = 'all',
    startDate = null,
} = {}) =>
    sendGetRequest({
        url: `organization/${organizationId}/type/${type}/usage/aggregate`,
        data: { endDate: startDate || getIsoDateNow(), startDate: endDate },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: '',
            ignoreErrorCodes,
        }),
    });

/**
 * Get app request usage
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getAppRequestUsage = ({ organizationId, successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: `organization/${organizationId}/type/appRequest/usage`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: '',
            ignoreErrorCodes,
        }),
    });

/**
 * Get mapping request usage
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getMappingUsage = ({ organizationId, successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: `organization/${organizationId}/type/mapping/usage`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: '',
            ignoreErrorCodes,
        }),
    });

/**
 * Get integration request usage
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getIntegrationUsage = ({ organizationId, successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: `organization/${organizationId}/type/integration/usage`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: '',
            ignoreErrorCodes,
        }),
    });

/**
 * Get Webhook request usage
 * @param {Object} args
 * @return {Promise<any>} response
 */
export const getWebhookUsage = ({ organizationId, successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: `organization/${organizationId}/type/webhook/usage`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: '',
            ignoreErrorCodes,
        }),
    });
