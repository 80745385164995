import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { switchToLoginAuthFormTab } from '../../../utils/authFormTabUtils';

const Logo = ({ isUserLoggedIn = false }) => {
    const navigate = useNavigate(),
        boxStyle = { height: 60, display: 'flex', alignItems: 'center' },
        imageStyle = { width: 116, height: 29, cursor: 'pointer' };

    const handleRootPage = () => {
        const pagePath = isUserLoggedIn ? '/dashboard' : '/';
        if (!isUserLoggedIn) {
            switchToLoginAuthFormTab();
        }
        navigate(pagePath, { replace: true });
    };

    return (
        <Box sx={boxStyle}>
            <img style={imageStyle} onClick={handleRootPage} src="/image/intely_logo.png" alt="intelylogo" />
        </Box>
    );
};

Logo.propTypes = {
    isUserLoggedIn: PropTypes.bool,
};

export default Logo;
