/**
 * Table styles
 */
export const tableCustomStyles = {
    table: {
        style: {
            width: '100%',
            zIndex: 0,
        },
    },
    tableWrapper: {
        style: {},
    },
    header: {
        style: {
            fontSize: '14px',
            minHeight: '0px',
            borderWidth: 5,
            borderRadius: 5,
        },
    },
    subHeader: {
        style: {},
    },
    head: {
        style: {
            backgroundColor: '#575757',
            height: '6vh',
            padding: '0',
            width: '100%',
            // marginTop: '-8px',
            // marginBottom: '8px',
        },
    },
    headRow: {
        style: {
            backgroundColor: '#575757',
            textAlign: 'center',
            border: '1px solid #828282',
            alignItems: 'center',
            paddingTop: '6px',
        },
    },
    headCells: {
        style: {
            fontWeight: '600',
            fontStyle: 'normal',
            fontSize: '0.875rem',
            width: '100%',
            color: '#fff',
            textTransform: 'capitalize',
            // justifyContent: 'flex-start',
            // paddingRight: '0px !important',
            // paddingLeft: '15px !important',
        },
    },
    cells: {
        style: {
            paddingTop: '8px',
            paddingBottom: '8px',
            // minWidth: 'max-content !important',
        },
    },
    pagination: {
        style: {
            align: 'center',
            textAlign: 'center',
            justifyContent: 'flex-end',
        },
    },
};

/**
 * Make the recently updated row(s) standout
 * @param {string|array} updatedRowId Updated row ID
 * @param {string} [idOverride] Updated row ID override
 * @return {array} List of conditional row styles
 */
export const conditionalRowStyles = (updatedRowId, idOverride = '') => {
    if (!updatedRowId?.length) {
        return [];
    }

    const updatedRowIds = typeof updatedRowId === 'string' ? [updatedRowId] : updatedRowId;

    return [
        {
            when: (row) => updatedRowIds.includes(idOverride?.length ? row[idOverride] : row._id),
            style: {
                borderLeft: '3px solid #42cf6c',
                backgroundColor: '#46c4841f',
            },
        },
    ];
};
