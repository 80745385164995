import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const OrganizationUserEmailColumn = (props) => {
    const { organizationUser } = props;

    return (
        <div>
            <Typography
                sx={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '0.875rem',
                    color: '#333333',
                }}
            >
                {organizationUser?.email || ''}
            </Typography>
        </div>
    );
};

OrganizationUserEmailColumn.propTypes = {
    organizationUser: PropTypes.object.isRequired,
};

export default OrganizationUserEmailColumn;
