import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider as MUIThemeProvider /* StyledEngineProvider */ } from '@mui/material/styles';

import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

// setup a cache for our dynamic styles so they will have the nonce attribute attached for CSP
const styledCache = createCache({
    key: 'main-style-cache-key',
    nonce: window.nonce,
    prepend: true,
    // insertionPoint: 'jss-insertion-point',
});

/**
 * Redefine the props for MUI theme provider and discard the 'exact-prop' check.
 *
 * Note: The `exact-prop` is a function in check ThemeProvider.propTypes
 * which is used to check for the exact prop types.
 */
MUIThemeProvider.propTypes = {
    theme: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
};

ThemeProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default function ThemeProvider({ children }) {
    const themeMode = 'light',
        isLight = themeMode === 'light',
        themeDirection = 'ltr';

    const themeOptions = useMemo(
        () => ({
            palette: isLight ? palette.light : palette.dark,
            typography,
            breakpoints,
            shape: { borderRadius: 8 },
            direction: themeDirection,
            shadows: isLight ? shadows.light : shadows.dark,
            customShadows: isLight ? customShadows.light : customShadows.dark,
        }),
        [isLight, themeDirection],
    );

    const theme = createTheme(themeOptions);
    theme.components = componentsOverride(theme);

    return (
        // <StyledEngineProvider  injectFirst>
        <CacheProvider value={styledCache}>
            <MUIThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </MUIThemeProvider>
        </CacheProvider>
        // </StyledEngineProvider>
    );
}
