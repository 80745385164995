import Typography from '@mui/material/Typography';
import MuiTextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import SearchIcon from 'assets/icons/Search';
import TextField from 'components/InputFields/TextField';
import SelectField from 'components/InputFields/SelectField';
import {
    setLogFilterData,
    useFilterLogVisibility,
    useLogFilterData,
    useSelectedLogNameItem,
} from 'hooks/useOrganizationLoggingHook';
import { formatDate } from 'utils/dateUtils';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { fetchLogs } from 'utils/loggingUtils';

const LogFilterContent = () => {
    const selectedLogType = useSelectedLogNameItem(),
        isVisible = useFilterLogVisibility(),
        logFilterData = useLogFilterData(),
        timeViews = ['hours', 'minutes'],
        isIntegrationLog = selectedLogType.name === 'integration';

    const integrationStatuses = [
        { label: 'Success', value: 'Success' },
        { label: 'Errored', value: 'Errored' },
    ];

    const integrationWorkflowFieldInputProps = {
        InputProps: {
            endAdornment: <SearchIcon />,
        },
    };

    const workflowNameInputStyle = {
        '& .MuiOutlinedInput-root': {
            '& svg': {
                marginLeft: '10px',
                fontSize: '14px',
                cursor: 'pointer',
            },
        },
    };

    /**
     *
     * @param {object} fieldProps
     * @returns {Element}
     */
    const renderDateInput = (props) => <MuiTextField {...props} variant="outlined" />;

    const handleDateChange = (state) => (date) => {
        setLogFilterData({
            ...logFilterData,
            [state]: date ? formatDate(date) : null,
        });
    };

    const handleTimeChange = (state) => (time) => {
        setLogFilterData({
            ...logFilterData,
            [state]: time ? formatDate(time, 'HH:mm') : null,
        });
    };

    const handleFieldChange = (e) => {
        setLogFilterData({
            ...logFilterData,
            [e.target.name]: e.target.value,
        });
    };

    const handleApplyFilters = async () => {
        fetchLogs({
            filters: logFilterData,
            searchText: logFilterData.searchText || '',
        });
    };

    let startTime = null,
        endTime = null;

    if (logFilterData.startTime && logFilterData.startDate) {
        startTime = `${logFilterData.startDate}T${logFilterData.startTime}`;
    }
    if (logFilterData.endTime && logFilterData.endDate) {
        endTime = `${logFilterData.endDate}T${logFilterData.endTime}`;
    }

    const filterLabel = selectedLogType.title,
        canApplyFilters = logFilterData.logName || logFilterData.logStatus || logFilterData.startDate;

    return (
        <div className={`filterLogContent${isVisible ? ' filterLogContentVisible' : ''}`}>
            <Typography component="div" className="filterLogTitle">
                <div className="filterLogTitleInner">
                    Filter
                    <span className="filterLogTitleLine"> </span>
                </div>
                <PrimaryButton
                    color="#3A71D5 !important"
                    backgroundColor="#fff"
                    width="95px"
                    height="32px"
                    fontSize="13px"
                    margin="0px 0px 0px 15px"
                    padding="0px"
                    borderRadius="3px"
                    disabled={!canApplyFilters}
                    className={canApplyFilters ? undefined : 'canApplyFiltersDisabled'}
                    onClick={handleApplyFilters}
                >
                    Apply Filters
                </PrimaryButton>
            </Typography>

            <div className={`filterLogContentFields${isIntegrationLog ? '' : ' expandFilterLogContentFields'}`}>
                {isIntegrationLog && (
                    <div className="filterLogContentField">
                        <Typography component="div" className="filterLogContentFieldLabel">
                            {filterLabel} Name
                        </Typography>
                        <TextField
                            name="logName"
                            label={null}
                            renderWithGrid={false}
                            placeholder={`Search Workflow Name`}
                            required={false}
                            inputProps={integrationWorkflowFieldInputProps}
                            sx={workflowNameInputStyle}
                            value={logFilterData.logName}
                            onChange={handleFieldChange}
                        />
                    </div>
                )}

                <div className="filterLogContentField">
                    <Typography component="div" className="filterLogContentFieldLabel">
                        Status
                    </Typography>
                    <SelectField
                        menuWidth={120}
                        name="logStatus"
                        label={null}
                        value={logFilterData.logStatus}
                        placeholder="Select Status"
                        renderWithGrid={false}
                        required={false}
                        items={integrationStatuses}
                        onChange={handleFieldChange}
                    />
                </div>
                <div className="filterLogContentField filterLogContentDateField">
                    <Typography component="div" className="filterLogContentFieldLabel">
                        Start Time
                    </Typography>
                    <div className="filterLogContentDateFieldWrapper">
                        <DatePicker
                            disableFuture={true}
                            value={logFilterData.startDate}
                            onChange={handleDateChange('startDate')}
                            renderInput={renderDateInput}
                            className="filterLogContentDateInput"
                        />
                        <TimePicker
                            ampm={false}
                            ampmInClock={false}
                            disableFuture={true}
                            value={startTime}
                            onChange={handleTimeChange('startTime')}
                            renderInput={renderDateInput}
                            className="filterLogContentTimeInput"
                            views={timeViews}
                            disabled={!logFilterData.startDate}
                        />
                    </div>
                </div>
                <div className="filterLogContentField filterLogContentDateField">
                    <Typography component="div" className="filterLogContentFieldLabel">
                        End Time
                    </Typography>
                    <div className="filterLogContentDateFieldWrapper">
                        <DatePicker
                            value={logFilterData.endDate}
                            disableFuture={true}
                            onChange={handleDateChange('endDate')}
                            renderInput={renderDateInput}
                            className="filterLogContentDateInput"
                            disabled={!logFilterData.startDate}
                        />
                        <TimePicker
                            ampm={false}
                            ampmInClock={false}
                            disableFuture={true}
                            value={endTime}
                            onChange={handleTimeChange('endTime')}
                            renderInput={renderDateInput}
                            className="filterLogContentTimeInput"
                            views={timeViews}
                            disabled={!logFilterData.endDate}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LogFilterContent;
