/**
 * Format file size in kb and return given unit
 * @param {{fileSizeInKb: number, precision: number }} args
 * @returns {string}
 */
export const formatFileSizeInKb = ({ fileSizeInKb, precision = 2 }) => {
    let fileSize = fileSizeInKb,
        unit = 'kb';

    if (fileSize >= 1024) {
        fileSize /= 1024;
        unit = 'mb';
    }

    if (fileSize >= 1024) {
        fileSize /= 1024;
        unit = 'gb';
    }

    if (fileSize >= 1024) {
        fileSize /= 1024;
        unit = 'tb';
    }

    return `${fileSize.toFixed(precision)} ${unit}`;
};
