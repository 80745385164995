import { REGEX_PATTERNS } from 'config/constants';
// import isEmail from 'validator/lib/isEmail';
import TextField from '../TextField';

const EmailAddressField = (props) => {
    const validate = {
        emptyLabel: 'Please enter email address.',
        // customValidator: (value) => isEmail(value),
        regex: REGEX_PATTERNS.email,
    };

    return (
        <TextField
            placeholder="Email Address"
            autoComplete="email"
            validate={validate}
            label="Email Address"
            type="email"
            name="email"
            {...props}
        />
    );
};

export default EmailAddressField;
