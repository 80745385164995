export default {
    container: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',

        '& .reactDataTable': {
            height: '63vh',
            maxHeight: '100%',
        },

        '& .rdt_TableHeadRow': {
            '& div': {
                fontSize: '13px',
            },
        },

        '& .toggleLogBtnSvg': {
            fontSize: '20px',
        },

        // Expanded rows
        '& .expandableDataRowWrapper': {
            display: 'flex',
            width: '100%',
            paddingBottom: '25px',
            borderBottom: '1px solid #ddd',
        },

        '& .expandableDataRowLine': {
            width: '25px',
            borderLeft: '1px solid #ddd',
            marginLeft: '24px',
            padding: '15px 0px 0px 0px',
            marginBottom: '22px',

            '& > div': {
                width: '20px',
                borderBottom: '1px solid #ddd',
            },
        },

        '& .expandableDataRow': {
            display: 'flex',
            width: '98%',
            flexDirection: 'column',
            flexGrow: 1,
            flexWrap: 'wrap',
            margin: '15px 15px 0px 5px',
            border: '1px solid #ddd',

            '& .expandableDataRowItemLine': {
                width: '100%',
                margin: '10px auto 15px auto',
                borderTop: 'none',
                borderBottom: '1px solid #ddd',
            },

            '& .expandableDataRowItem': {
                display: 'flex',
                width: '100%',
                flexWrap: 'wrap',

                '& > div': {
                    fontSize: '12px',
                    flexWrap: 'wrap',
                    // marginRight: '12px',
                    padding: '6px',
                },

                '& .expandableDataRowLineJoiner': {
                    position: 'relative',
                    marginLeft: '-35px',
                    marginRight: '4px',
                    display: 'flex',
                    alignItems: 'center',

                    '& span': {
                        display: 'block',
                        width: '22px',
                        borderBottom: '1px solid #ddd',
                    },
                },

                '& > .expandedRowActions': {
                    marginRight: '0px',
                },

                '& .expandedRowLogId': {
                    flex: 0.06,
                },
                '& .expandedRowStepName': {
                    flex: 0.2,
                },
                '& .expandedRowStatus': {
                    flex: 0.08,
                },
                '& .expandedRowStatusCode': {
                    flex: 0.08,
                },
                '& .expandedRowRequestTime': {
                    flex: 0.12,
                },
                '& .expandedRowErrorMsg': {
                    flex: 0.18,
                },
                '& .expandedRowActions': {
                    flex: 0.28,
                },
            },
        },

        '& .expandableDataRowHeader': {
            margin: '0px 0px 15px 0px',
            backgroundColor: '#E0E0E0',
            padding: '8px 0px',

            '& > div': {
                fontWeight: '600 !important',
            },
        },

        '& .expandableDataRowContent': {},

        '& .expandedRowErrorMsgBtn': {
            width: '22px',
            height: '22px',
            borderRadius: '5px',
            backgroundColor: '#3A70D4',
            color: '#fff',
            padding: '8px',
            marginLeft: '10px',

            '& svg': {
                fill: '#fff',
                fontSize: '18px',
            },
        },

        '& .responseStatusText': {
            fontSize: '13px',
        },
        '& .responseStatusTextError': {
            color: '#d54551',
        },
        '& .responseStatusTextSuccess': {
            color: '#139a48',
        },

        '& .logIdField': {
            display: 'flex',
            flexWrap: 'wrap',
            fontSize: '13px',
        },

        '& .actionButtons': {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
        },

        '& .rdt_TableCell .actionButtons': {
            marginTop: '5px',

            '& button': {
                border: '1px solid #3A70D4',
                minWidth: '98px',
            },
        },

        '& .logTooltip': {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
        },
    },

    tableContainer({ isLoading }) {
        return {
            flexGrow: 1,
            marginBottom: isLoading ? '5%' : undefined,
            border: '1px solid #E0E0E0',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottomWidth: isLoading ? '1px' : '0px',
        };
    },
};
