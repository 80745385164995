export default {
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    homeIconWrapper: {
        marginRight: '4px',
    },
    homeIcon: {
        width: '15px',
        height: '11px',
    },
    homeText: {
        color: '#3A70D4',
        fontSize: '10px',
        lineHeight: '11px',
        fontFamily: 'Roboto',
    },
    activeNavText: {
        color: '#333',
    },
    separator: {
        margin: '0px 6px',
        color: '#3A70D4',
        fontSize: '12px',
    },
    link: {
        display: 'flex',
        height: '12px',
        alignItems: 'center',
        textDecoration: 'none',
    },
};
