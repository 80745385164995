import { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

import TextField from '../TextField';
import { REGEX_PATTERNS } from '../../../config/constants';

const PasswordField = (props) => {
    const [showPassword, setShowPassword] = useState(false),
        noRegex = false === props.passwordRegex,
        emptyLabel = 'Password should have at least 8 characters',
        passwordError =
            'Password should be a minimum of 8 characters in length, with uppercase and lowercase characters, one special character, and one number.',
        validate = {
            regex: noRegex ? null : REGEX_PATTERNS.password,
            emptyLabel,
            invalidLabel: passwordError,
            minLengthErrorLabel: noRegex ? emptyLabel : passwordError,
            ...(props.validate || {}),
        };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const passwordAdornment = (
        <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
            >
                {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </InputAdornment>
    );

    const inputProps = {
        InputProps: {
            endAdornment: passwordAdornment,
        },
    };

    return (
        <TextField
            validationProps={false}
            placeholder="Password"
            minLength={8}
            inputProps={inputProps}
            trimValue={false}
            label="Password"
            type={showPassword ? 'text' : 'password'}
            {...props}
            validate={validate}
        />
    );
};

// Other props inherits TextField props validation
PasswordField.propTypes = {
    validate: PropTypes.object,
    passwordRegex: PropTypes.bool,
};

export default PasswordField;
