import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

const PaperPlaneIcon = (props) => {
    const fill = props.fill || '#F2994A';

    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.75 4.21875L4.75 11.7188C3.65625 12.3438 3.75 13.9375 4.90625 14.4062L8.5 15.875V18.5C8.5 19.9688 10.3438 20.5625 11.1875 19.4062L12.5625 17.5625L16.0625 19C16.25 19.0938 16.4375 19.125 16.625 19.125C16.875 19.125 17.125 19.0625 17.375 18.9375C17.7812 18.6875 18.0312 18.3125 18.125 17.8438L19.9688 5.75C20.1562 4.5 18.8125 3.59375 17.75 4.21875ZM10 18.5V16.5L11.125 16.9688L10 18.5ZM16.625 17.625L11.8125 15.625L16.2188 9.3125C16.5312 8.8125 15.9062 8.25 15.4688 8.65625L8.84375 14.4062L5.5 13L18.5 5.5L16.625 17.625Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

PaperPlaneIcon.propTypes = {
    fill: PropTypes.string,
};

export default PaperPlaneIcon;
