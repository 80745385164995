import { useState, useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import TextFieldMui from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';

import InputLabel from '../InputLabel';
import { inputValueType } from '../../../utils/typeUtils';
import { useInputFieldRef, useInputActionCallback, useInputFieldDefaultValue } from '../../../hooks/useInputFieldHook';

export const textareaInputProps = {
    multiline: true,
    rows: 5,
    size: 'small',
};

const TextField = (props) => {
    const {
        id,
        value,
        inputRef,
        onChange,
        minLength,
        maxLength,
        handleChange,
        defaultValue,
        isValueChanged,
        sx = {},
        name = '',
        type = 'text',
        error = false,
        label = 'Label',
        variant = 'outlined',
        disabled = false,
        required = true,
        showError = true,
        gridProps = {},
        fullWidth = true,
        trimValue = true,
        inputProps = {},
        helperText = '',
        placeholder = '',
        autoComplete = '',
        requiredLabel = ' *',
        actionCallback = null,
        isSubmittingForm = false,
        renderWithGrid = true,

        // Whether to validate the input field when loaded
        validateOnLoad = false,

        // Whether to validate the input field as you type,
        // useful for auth fields such as: Verification code.
        realtimeValidationOnType = false,

        // Control whether to add the validation props to
        // the input field, e.g: minLength, maxLength, etc.
        validationProps = true,

        validate = {
            regex: null,
            errorLabel: '', // Default to: {label}
            emptyLabel: '', // Default to: `{label} is required`
            invalidLabel: '', // Default to: `{label} is invalid`

            // `${label} minimum ${pluralizeCharacterText(minLength)} is ${minLength}.`
            minLengthErrorLabel: '',

            // `${label} maximum ${pluralizeCharacterText(maxLength)} is ${maxLength}.`
            maxLengthErrorLabel: '',

            /**
             * A custom validator function.
             *
             * @param {string|number} value Input field value.
             * @param {HTMLFormElement} inputRef HTML form input element.
             *
             * @return {bool|object} True if field is valid.
             * Otherwise false or an object with the following properties:
             * - error: (bool) True if field is invalid.
             * - helperText: (React.ReactNode) Error helper text
             */
            customValidator: null,
        },

        /**
         * Validation error callback function. If defined, it fires if
         * validation fails.
         * @param {object} args
         * @param {number|string} args.value Input field value.
         * @param {HTMLFormElement} args.inputRef HTML form input element.
         * @returns {void}
         */
        validationErrorCallback = null,

        /**
         * Validation success callback function. If defined, it fires if
         * validation was successful.
         * @param {object} args
         * @param {number|string} args.value Input field value.
         * @param {HTMLFormElement} args.inputRef HTML form input element.
         * @returns {void}
         */
        validationSuccessCallback = null,
    } = props;

    const inputFieldRef = useInputFieldRef(inputRef),
        hasShownErrorForFirstTime = useRef(false),
        autoFilledFallbackChecked = useRef(false),
        [hasError, setHasError] = useState(error),
        [fieldHelperText, setFieldHelperText] = useState(helperText),
        regex = validate.regex,
        hasRegex = regex && typeof regex === 'object',
        errorLabel = validate.errorLabel || label,
        isEmailField = type === 'email',
        invalidLabel = validate.invalidLabel || `${errorLabel} is invalid`,
        emptyErrorLabel = validate.emptyLabel || `${errorLabel} is required`,
        customValidator = validate.customValidator,
        minLengthErrorLabel = validate.minLengthErrorLabel,
        maxLengthErrorLabel = validate.maxLengthErrorLabel;

    // Keep field value in sync if default value is changed in props
    useInputFieldDefaultValue(defaultValue, inputFieldRef);

    // Run input field action callback if specified
    useInputActionCallback(inputFieldRef, actionCallback);

    /**
     * Make error a dependency to re-render component if
     * it's changed and painting is completed.
     */
    useEffect(() => {
        setHasError(error);
    }, [error]);

    /**
     * Make helperText a dependency to re-render component if
     * it's changed and painting is completed.
     */
    useEffect(() => {
        setFieldHelperText(helperText);
    }, [helperText]);

    /**
     * Handle input field error visibility
     */
    useEffect(() => {
        (validateOnLoad || isSubmittingForm) && validateInputField();
        /* eslint-disable */
    }, [isSubmittingForm, validateOnLoad]);

    /**
     * Clear error helper for fields whose values are updated without trigger change event
     */
    useEffect(() => {
        if (isValueChanged) {
            clearFieldError();
        }
    }, [isValueChanged]);

    /**
     * Get input field value
     * @returns {number|string} Input field value.
     */
    const getInputFieldValue = () => {
        const fieldValue = trimValue ? inputFieldRef.current.value.trim() : inputFieldRef.current.value;

        return isEmailField ? fieldValue.toLowerCase() : fieldValue;
    };

    /**
     * Pluralize the `character` text
     * @param {number} len Input field value length.
     * @return {string} Pluralized `characters` text if value length is greater
     * then 1. Otherwise 'character' is returned.
     */
    const pluralizeCharacterText = (len) => (len > 1 ? 'characters' : 'character');

    /**
     * Handle input field validation
     * @param {boolean} showError Use to control whether to error.
     * This is used to implement fallback for auto-filled input fields.
     */
    const validateInputField = (showError = true) => {
        const fieldValue = getInputFieldValue(),
            fieldValueLen = fieldValue.length;

        // Required field check
        if (required && fieldValueLen < 1) {
            showError && displayFieldError(emptyErrorLabel);
            return;
        }

        // Minimum field value length check
        if (!isInputMinLengthOkay(fieldValueLen, showError)) {
            return;
        }

        // Maximum field value length check
        if (!isInputMaxLengthOkay(fieldValueLen, showError)) {
            return;
        }

        // Regex pattern check
        if (hasRegex && !regex?.test(fieldValue)) {
            displayFieldError(invalidLabel);
            return;
        }

        // Custom validator check
        if (!isCustomValidationCheckOkay(fieldValue)) return;

        // Clear field error
        showError && clearFieldError();

        // Fires validation success callback if defined
        validationSuccessCallback &&
            validationSuccessCallback({
                value: fieldValue,
                inputRef: inputFieldRef,
            });

        return true;
    };

    /**
     * Check whether the field value minlength is okay.
     * @param {number} fieldValueLen Input field value length
     * @param {boolean} showError Whether to display error.
     * @returns {boolean} True if field value minlength is okay.
     * False otherwise.
     */
    const isInputMinLengthOkay = (fieldValueLen, showError) => {
        if (showError && minLength && fieldValueLen < minLength) {
            const minLengthError =
                minLengthErrorLabel || `${errorLabel} minimum ${pluralizeCharacterText(minLength)} is ${minLength}.`;

            displayFieldError(minLengthError);
            return false;
        }
        return true;
    };

    /**
     * Check whether the field value max-length is okay.
     * @param {number} fieldValueLen Input field value length
     * @param {boolean} showError Whether to display error.
     * @returns {boolean} True if field value max-length is okay.
     * False otherwise.
     */
    const isInputMaxLengthOkay = (fieldValueLen, showError) => {
        if (showError && maxLength && fieldValueLen > maxLength) {
            const maxLengthError =
                maxLengthErrorLabel || `${errorLabel} maximum ${pluralizeCharacterText(maxLength)} is ${maxLength}.`;

            displayFieldError(maxLengthError);
            return;
        }
        return true;
    };

    /**
     * Validate the input field value using the custom validator if defined.
     * @param {number|string} fieldValue Input field value.
     * @returns {boolean} True if validation is successful, false otherwise.
     */
    const isCustomValidationCheckOkay = (fieldValue) => {
        if (customValidator) {
            const validateInput = customValidator(fieldValue, inputFieldRef.current);

            if (true !== validateInput && (!validateInput || validateInput?.error)) {
                displayFieldError(validateInput?.helperText || invalidLabel);
                return false;
            }
        }

        return true;
    };

    /**
     * Display input field error
     * @param {String} errorText Field error helper text
     */
    const displayFieldError = (errorText) => {
        setHasError(true);
        setFieldHelperText(errorText);
        setErrorDisplayState();

        // Fires validation error callback if defined
        validationErrorCallback &&
            validationErrorCallback({
                value: getInputFieldValue(),
                inputRef: inputFieldRef,
            });
    };

    /**
     * Set the error display state
     */
    const setErrorDisplayState = () => {
        if (!hasShownErrorForFirstTime.current) {
            hasShownErrorForFirstTime.current = true;
        }
    };

    /**
     * Clear input field error
     */
    const clearFieldError = () => {
        setHasError(false);
        setFieldHelperText('');
    };

    /**
     * If field error is shown for the first time, then validate field
     * when value is changed.
     */
    const handleValueChange = async (e) => {
        if (hasShownErrorForFirstTime.current || realtimeValidationOnType) {
            validateInputField();
        } else {
            // fallback for auto-filled input fields
            if (!autoFilledFallbackChecked.current && true === validateInputField(false)) {
                // Should run just once
                autoFilledFallbackChecked.current = true;
                setErrorDisplayState();
            }
        }

        // Todo: rename handleChange to onChange
        const handleChangeFunc = onChange ? onChange : handleChange;
        handleChangeFunc && (await handleChangeFunc(e));
    };

    const style = {
        mt: 1,
        '& input': { padding: '10px', fontSize: 14 },
        '& .MuiOutlinedInput-root': { backgroundColor: '#fff' },
        '& .MuiInputBase-input': {
            backgroundColor: 'transparent !important',
            marginTop: 0,
            fontSize: '14px !important',
            fontWeight: '400 !important',
        },
        ...sx,
    };

    const renderInputField = (
        <>
            {label !== null && (
                <InputLabel inputId={id} label={label} required={required} requiredLabel={requiredLabel} />
            )}

            <TextFieldMui
                id={id}
                sx={style}
                type={type}
                name={name}
                value={value}
                error={hasError}
                variant={variant}
                inputRef={inputFieldRef}
                required={required}
                disabled={disabled}
                fullWidth={fullWidth}
                onChange={handleValueChange}
                minLength={!validationProps ? undefined : minLength}
                maxLength={!validationProps ? undefined : maxLength}
                placeholder={placeholder}
                autoComplete={autoComplete}
                defaultValue={defaultValue}
                {...inputProps}
            />

            {showError && <FormHelperText error={hasError}>{fieldHelperText}</FormHelperText>}
        </>
    );

    return renderWithGrid ? (
        <Grid item xs={12} md={6} {...gridProps}>
            {renderInputField}
        </Grid>
    ) : (
        renderInputField
    );
};

TextField.propTypes = {
    id: PropTypes.string,
    sx: PropTypes.object,
    type: PropTypes.string,
    name: PropTypes.string,
    regex: PropTypes.object,
    error: PropTypes.bool,
    label: PropTypes.node,
    value: inputValueType,
    variant: PropTypes.string,
    required: PropTypes.bool,
    showError: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    validate: PropTypes.object,
    inputRef: PropTypes.object,
    fullWidth: PropTypes.bool,
    trimValue: PropTypes.bool,
    gridProps: PropTypes.object,
    inputProps: PropTypes.object,
    helperText: PropTypes.node,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    defaultValue: inputValueType,
    autoComplete: PropTypes.string,
    handleChange: PropTypes.func,
    requiredLabel: PropTypes.node,
    isValueChanged: PropTypes.any,
    actionCallback: PropTypes.object,
    renderWithGrid: PropTypes.bool,
    reRenderCounter: PropTypes.number,
    validationProps: PropTypes.bool,
    validateOnLoad: PropTypes.bool,
    isSubmittingForm: PropTypes.bool,
    realtimeValidationOnType: PropTypes.bool,
    validationErrorCallback: PropTypes.func,
    validationSuccessCallback: PropTypes.func,
};

export default memo(TextField);
