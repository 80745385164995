import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import PrimaryButton from 'components/Buttons/PrimaryButton';
import { setFilterLogVisibility, useFilterLogVisibility } from 'hooks/useOrganizationLoggingHook';

const FilterLogButton = () => {
    const isFilterLogVisible = useFilterLogVisibility(),
        filterBtnStyle = {
            display: 'flex',
            alignItems: 'center',
            minWidth: '80px',
            justifyContent: 'flex-start',
            border: '1px solid #3A71D5',
            textAlign: 'left',

            '& .MuiOutlinedInput-root': {
                borderRadius: '4px',
            },

            '& span': {
                flexGrow: 1,
                fontSize: '14px',
            },

            '& svg': {
                fontSize: '22px',
                marginRight: '-5px',
                transition: 'all 300ms',
                transform: isFilterLogVisible ? 'rotate(180deg)' : undefined,
            },
        };

    const handleSetFilterLogVisibility = () => {
        setFilterLogVisibility(!isFilterLogVisible);
    };

    return (
        <>
            <PrimaryButton
                height="32px"
                width="auto"
                fontSize="14px"
                fontWeight={400}
                padding="0px 10px"
                backgroundColor="#fff"
                borderRadius="3px"
                color="primary"
                sx={filterBtnStyle}
                onClick={handleSetFilterLogVisibility}
                className={isFilterLogVisible ? 'filterLogBtnVisible' : undefined}
            >
                <span>Filter</span>
                <ExpandMoreIcon />
            </PrimaryButton>
        </>
    );
};

export default FilterLogButton;
