export default {
    root: {
        height: 'auto',
        display: 'flex',
        width: '100%',
    },
    containerWrapper: {
        width: '100%',
        padding: '0px 20px',
        marginBottom: '80px',
    },
    contentWrapper: {
        width: '100%',
        // minHeight: '400px',
    },

    content: {
        flex: 1,
        flexGrow: 1,
        width: '100%',
        backgroundColor: '#F5F5F5',
        overflowY: 'auto',
        height: 'calc(100vh - 60px)',
    },
    dashboardWidgetsContent: {
        display: 'flex',
        height: '120px',
        left: '220px',
        top: '60px',
        background: 'linear-gradient(90deg, #FCB64B 0%, #19C787 52.08%, #3A71D5 100%)',
        borderRadius: '4px',
        marginTop: '20px',
        justifyContent: 'space-evenly',
        maxHeight: '170px',
    },
    widgetsTitle: {
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '40px !important',
        lineHeight: '47px',
        textTransform: 'uppercase',
        color: '#333333',
        zIndex: 1,
        height: '47px',
        paddingLeft: '143px',
        paddingTop: '40px',
        paddingBottom: '40px',
        fontFamily: 'Roboto !important',
    },
    widgetsSubTitle: {
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '25px !important',
        lineHeight: '133%',
        color: '#333333',
        zIndex: 1,
        paddingTop: '52px',
        fontFamily: 'Roboto !important',
    },
    dashboardComingSoonWrapper: {
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
    },
    dashboardHelpWrapper: {
        width: '100%',
        paddingTop: '150px',
        justifyContent: 'center',
        display: 'flex',
        // height: 'inherit',
        // position: 'inherit',
        paddingBottom: '60px',
    },
    dashboardHelpContent: {
        width: '623px',
        height: '190px',
        background: '#3A70D4',
        borderRadius: '10px',
        color: '#fff',
        textAlign: 'center',
        paddingTop: '40px',
        paddingBottom: '11.5px',
    },
    helpTitle: {
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '18px !important',
        lineHeight: '21px',
        fontFamily: 'Roboto !important',
    },
    helpSubTitle: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px !important',
        lineHeight: '19px',
        fontFamily: 'Roboto !important',
    },
    bookMeetingButton: {
        height: '46px',
        width: '249px',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px !important',
        lineHeight: '16px',
        textAlign: 'center',
        padding: '15px 21px',
        fontFamily: 'Roboto !important',
        '&.secondary': {
            backgroundColor: '#00C4E1',
            color: '#FFFFFF',
            boxShadow: 'none',
        },
        '&.secondary:hover': {
            backgroundColor: '#828282',
            color: '#FFFFFF',
            boxShadow: 'none',
        },
    },
    dashboardBottomImage: {
        bottom: 0,
        backgroundRepeat: 'repeat',
        width: '100%',
    },
    footerBgColor: {
        background: 'linear-gradient(90deg, #FCB64B 0%, #19C787 52.08%, #3A71D5 100%)',
        height: '40px',
        width: '100%',
    },
    footerBackground: {
        background: 'linear-gradient(90deg, #FCB64B 0%, #19C787 52.08%, #3A71D5 100%)',
        height: 'calc(100vh - 60px - 790px)',
        width: '100%',
    },
};
