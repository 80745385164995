import { memo } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import UserAvatar from '../../Avatars/UserAvatar';
import UserNameInfo from '../UserNameInfo';
import BootstrapTooltip from '../../Tooltips/BootstrapTooltip';
import UploadUserProfileImageButton from '../../Buttons/UploadUserProfileImageButton';
import RemoveUserProfileImageButton from '../../Buttons/RemoveUserProfileImageButton';

const UserAccountInfo = ({ userData, uploadProfileImageButtonProps = {} }) => {
    const hasProfileImage = userData.profileImg,
        showUploadButton = false, // remove upload button
        rootBoxStyle = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 4,
        },
        userAvatarBoxStyle = {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
        userProfileImageButtonBoxStyle = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: 0,
            alignSelf: 'end',
        };

    const renderUploadButton = <UploadUserProfileImageButton {...uploadProfileImageButtonProps} />;

    const renderUploadButtonWithTooltip = !uploadProfileImageButtonProps?.disabled ? (
        <BootstrapTooltip title="Upload Profile Image" placement="bottom">
            <div>{renderUploadButton}</div>
        </BootstrapTooltip>
    ) : (
        renderUploadButton
    );

    return (
        <Box sx={rootBoxStyle}>
            <Box sx={userAvatarBoxStyle}>
                <UserAvatar
                    iconStyle={{
                        width: '60px',
                        height: '60px',
                    }}
                    avatarStyle={{
                        width: '120px',
                        height: '120px',
                    }}
                />

                <Box sx={userProfileImageButtonBoxStyle}>
                    {showUploadButton && renderUploadButtonWithTooltip}

                    {hasProfileImage && (
                        <BootstrapTooltip title="Remove Profile" placement="bottom">
                            <div>
                                <RemoveUserProfileImageButton userId={userData.id} {...uploadProfileImageButtonProps} />
                            </div>
                        </BootstrapTooltip>
                    )}
                </Box>
            </Box>

            <UserNameInfo userData={userData} />
        </Box>
    );
};

UserAccountInfo.propTypes = {
    userData: PropTypes.object.isRequired,
    uploadProfileImageButtonProps: PropTypes.object,
};

export default memo(UserAccountInfo);
