import * as constants from '../config/constants';

/**
 *
 * @param {String} label
 * @param {*} messages
 */
export const logMessage = (label, ...messages) => {
    if (!constants.IS_PRODUCTION) {
        /* eslint-disable */
        console.log(label, ...messages);
    }
};

/**
 *
 * @param {*} errors
 */
export const logError = (...errors) => {
    if (!constants.IS_PRODUCTION) {
        for (const error of errors) {
            /* eslint-disable */
            console.error(error);
        }
    }
};

/**
 * Get the base URL
 * @returns {string} Gateway Base URL
 */
export const getGatewayBaseUrl = () => process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST;

/**
 *
 * @param {String} path Path portion of URL beginning with a backwards slash(/)
 * @returns {`${string}${string}`}
 */
export const getGatewayUrl = (path) => {
    return `${getGatewayBaseUrl()}${path}`;
};

/**
 * Go to apps screen
 */
export const navigateToAppsUrl = () => {
    window.location = '/dashboard';
};

/**
 *
 * @returns {module:url.URLSearchParams}
 */
export const getUrlParameters = () => {
    return new URLSearchParams(window.location.search);
};
