import { authorizedAxiosInstance } from '../axiosInstances';
import { showSnackBarErrorNotification, showSnackBarSuccessNotification } from './snackBarNotificationUtils';
import { getGatewayUrl } from './envUtils';

export const addAccessToken = (accessToken, userId) => {
    return new Promise((resolve, reject) => {
        authorizedAxiosInstance
            .post(getGatewayUrl(`/user/${userId}/access-token`), accessToken)
            .then((res) => {
                if (res.status === 200) {
                    showSnackBarSuccessNotification('New Access Token added successfully.');
                    resolve(res);
                }
            })
            .catch((err) => {
                showSnackBarErrorNotification('An error occurred attempting to add a new token.');
                reject(err);
            });
    });
};

export const deleteAccessToken = (tokenId, userId) => {
    return new Promise((resolve, reject) => {
        authorizedAxiosInstance
            .delete(getGatewayUrl(`/user/${userId}/access-token/${tokenId}`))
            .then((res) => {
                if (res.status === 200) {
                    showSnackBarSuccessNotification('Access Token deleted successfully.');
                }
                resolve(res);
            })
            .catch((err) => {
                showSnackBarErrorNotification('An error occurred attempting to delete token.');
                reject(err);
            });
    });
};

export const updateAccessToken = (tokenId, changes, userId) => {
    return new Promise((resolve, reject) => {
        authorizedAxiosInstance
            .patch(getGatewayUrl(`/user/${userId}/access-token/${tokenId}`), {
                token: changes,
            })
            .then((res) => {
                if (res.status === 200) {
                    showSnackBarSuccessNotification('Access Token updated successfully.');
                }
                resolve(res);
            })
            .catch((err) => {
                showSnackBarErrorNotification('An error occurred attempting to update token.');
                reject(err);
            });
    });
};
