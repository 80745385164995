import TextField from '../TextField';
import { REGEX_PATTERNS } from '../../../config/constants';

const PhoneNumberField = (props) => {
    const validate = {
        regex: REGEX_PATTERNS.phone,
        emptyLabel: 'Please enter phone number.',
        invalidLabel: 'Phone number should be numeric value.',
    };

    return (
        <TextField
            placeholder="Your Phone Number"
            autoComplete="phoneNumber"
            label="Phone Number"
            name="phoneNumber"
            validate={validate}
            {...props}
        />
    );
};

export default PhoneNumberField;
