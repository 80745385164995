import { memo } from 'react';
import PropTypes from 'prop-types';

import allStyles from './styles';
import PageTitleBar from '../../PageTitleBar';
import NavigationIndicator from '../../NavigationIndicator';
import DashboardMainSidebar from '../../Sidebars/DashboardMainSidebar';

const DashboardMainSidebarContainer = ({ content, pageTitleBarProps, navigationIndicatorProps, styles = {} }) => {
    const renderPageTitleBar = <PageTitleBar {...pageTitleBarProps} />,
        navigationIndicatorStyle = {
            ...(styles.navigationIndicator || {}),
            position: 'fixed',
            backgroundColor: '#fff',
            width: '100%',
            zIndex: 20,
            paddingTop: '8px',
            height: '40px',
        },
        renderNavigationIndicator = (
            <NavigationIndicator style={navigationIndicatorStyle} {...navigationIndicatorProps} />
        ),
        rootStyle = { ...allStyles.root, ...styles.root },
        contentWrapper = {
            ...allStyles.content,
            ...(styles.content || {}),
            overflowY: 'auto',
            position: 'relative',
        },
        pageContentWrapper = {
            ...styles.pageContent,
            marginTop: styles.navigationIndicator?.height ?? '32px',
        };

    return (
        <div style={rootStyle} className="rootDashboardContainer">
            <DashboardMainSidebar />

            <div style={contentWrapper}>
                {renderNavigationIndicator}
                <div style={pageContentWrapper}>
                    {renderPageTitleBar}
                    {content}
                </div>
            </div>
        </div>
    );
};

DashboardMainSidebarContainer.propTypes = {
    styles: PropTypes.object,
    content: PropTypes.node.isRequired,
    pageTitleBarProps: PropTypes.object,
    navigationIndicatorProps: PropTypes.object.isRequired,
};

export default memo(DashboardMainSidebarContainer);
