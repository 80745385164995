import { HEADER_HEIGHT as headerHeight } from '../../config/constants';

export default {
    root: {
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #E0E0E0',
        height: `calc(100% - ${headerHeight}px)`,
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
    loader: {
        height: `calc(100% - ${headerHeight}px)`,
    },
    fileTreeContainer: {
        height: '100%',
    },
    fileContentContainer: {
        height: '100%',
        width: '100%',
    },
};
