import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { setViewLogRequest } from 'hooks/useOrganizationLoggingHook';

const ExpandedComponent = (/*{ data }*/) => {
    // console.log(data);

    const handleViewLogRequest = () => {
        setViewLogRequest({
            open: true,
            title: 'View Request',
            content: 'log content here',
        });
    };

    const handleViewErrorMsg = () => {
        setViewLogRequest({
            open: true,
            title: 'Error Message',
            content: 'Error content here',
        });
    };

    return (
        <div className="expandableDataRowWrapper">
            <div className="expandableDataRowLine"></div>
            <div className="expandableDataRow">
                <div className="expandableDataRowItem expandableDataRowHeader">
                    <div className="expandableDataRowLineJoiner">
                        <span></span>
                    </div>
                    <div className="expandedRowLogId">Log ID</div>
                    <div className="expandedRowStepName">Step Name / Description</div>
                    <div className="expandedRowStatus">Status</div>
                    <div className="expandedRowStatusCode">Status Code</div>
                    <div className="expandedRowRequestTime">Request Time</div>
                    <div className="expandedRowErrorMsg">Error Message</div>
                    <div className="expandedRowActions">Actions</div>
                </div>

                <div className="expandableDataRowItem expandableDataRowContent">
                    <div className="expandableDataRowLineJoiner">
                        <span></span>
                    </div>
                    <div className="expandedRowLogId">14123423</div>
                    <div className="expandedRowStepName">Description or step name</div>
                    <div className="expandedRowStatus">Success</div>
                    <div className="expandedRowStatusCode">402</div>
                    <div className="expandedRowRequestTime">2002-43-23 89:78</div>
                    <div className="expandedRowErrorMsg">
                        Some long string
                        <IconButton className="expandedRowErrorMsgBtn" onClick={handleViewErrorMsg}>
                            <OpenInNewIcon />
                        </IconButton>
                    </div>
                    <div className="expandedRowActions">
                        <PrimaryButton
                            height="30px"
                            padding="0px 7px"
                            fontSize="12px"
                            width="auto"
                            margin="0px 5px 0px 0px"
                            borderRadius="3px"
                            onClick={handleViewLogRequest}
                        >
                            View Request
                        </PrimaryButton>
                        <PrimaryButton
                            height="30px"
                            padding="0px 7px"
                            fontSize="12px"
                            width="auto"
                            margin="0px 5px 0px 0px"
                            borderRadius="3px"
                        >
                            View Response
                        </PrimaryButton>
                        <PrimaryButton
                            height="30px"
                            padding="0px 7px"
                            fontSize="12px"
                            width="auto"
                            margin="0px"
                            borderRadius="3px"
                        >
                            Reprocess
                        </PrimaryButton>
                    </div>
                </div>

                <hr className="expandableDataRowItemLine" />

                <div className="expandableDataRowItem expandableDataRowContent">
                    <div className="expandableDataRowLineJoiner">
                        <span></span>
                    </div>
                    <div className="expandedRowLogId">14123423</div>
                    <div className="expandedRowStepName">Description or step name</div>
                    <div className="expandedRowStatus">Success</div>
                    <div className="expandedRowStatusCode">402</div>
                    <div className="expandedRowRequestTime">2002-43-23 89:78</div>
                    <div className="expandedRowErrorMsg">
                        Some long string
                        <IconButton className="expandedRowErrorMsgBtn">
                            <OpenInNewIcon />
                        </IconButton>
                    </div>
                    <div className="expandedRowActions">
                        <PrimaryButton
                            height="30px"
                            padding="0px 7px"
                            fontSize="12px"
                            width="auto"
                            margin="0px 5px 0px 0px"
                            borderRadius="3px"
                        >
                            View Request
                        </PrimaryButton>
                        <PrimaryButton
                            height="30px"
                            padding="0px 7px"
                            fontSize="12px"
                            width="auto"
                            margin="0px 5px 0px 0px"
                            borderRadius="3px"
                        >
                            View Response
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

ExpandedComponent.propTypes = {
    data: PropTypes.any,
};

export default ExpandedComponent;
