import { useState, useEffect } from 'react';
import { Typography, DialogActions, DialogContent, DialogTitle, Divider, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Badge from '@mui/material/Badge';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import DialogContainer from '../../Containers/DialogContainer';
import useModalState, { MODAL_ACTIONS } from '../../../hooks/useModalStateHook';
import { updateOrganizationUserData, getOrganizationUsersData } from '../../../utils/organizationUtils';
import { showSnackBarNotification, showSnackBarErrorNotification } from '../../../utils/snackBarNotificationUtils';
import {
    useSelectedOrganizationUserData,
    updateOrganizationUsersOptimistically,
    setOrganizationUsersData,
} from '../../../hooks/useOrganizationUsersHook';

const useStyles = makeStyles((theme) => ({
    MuiDialog: {
        styleOverrides: {
            paper: {
                boxShadow: theme.customShadows.z24,
                '&.MuiPaper-rounded': {
                    borderRadius: '0 !important',
                },
                '&.MuiDialog-paperFullScreen': {
                    borderRadius: '0 !important',
                },
                '&.MuiDialog-paper .MuiDialogActions-root': {
                    padding: theme.spacing(3),
                },
                '@media (max-width: 600px)': {
                    margin: theme.spacing(2),
                },
                '@media (max-width: 663.95px)': {
                    '&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody': {
                        maxWidth: '100%',
                    },
                },
            },
            paperFullWidth: {
                width: '100%',
            },
        },
    },
    MuiDialogTitle: {
        styleOverrides: {
            root: {
                padding: theme.spacing(3, 3, 0),
            },
        },
    },
    MuiDialogContent: {
        styleOverrides: {
            root: {
                borderTop: 0,
                borderBottom: 0,
                padding: theme.spacing(3),
            },
        },
    },
    MuiDialogActions: {
        styleOverrides: {
            root: {
                '& > :not(:first-of-type)': {
                    marginLeft: theme.spacing(1.5),
                },
            },
        },
    },
    photobtn: {
        background: theme.palette.primary.main,
        borderRadius: '5px',
        color: '#FFFFFF',
        textTransform: 'capitalize',
        border: '1px solid #FFFFFF',
        '&:hover': {
            color: theme.palette.primary.main,
            background: '#FFF',
            border: '1px solid ' + theme.palette.primary.main,
        },
    },
    camerabtn: {
        width: 20,
        height: 20,
    },
    usericon: {
        width: 120,
        height: 120,
        color: '#C4C4C4',
        cursor: 'pointer',
        boxShadow: '0 0 98px 6px rgba(0, 0, 0, 0.2)',
        borderRadius: '50%',
    },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const EditOrganizationUserModal = () => {
    const classes = useStyles();
    const [, setDisabled] = useState(true);
    const [profileimg] = useState();

    const [organizationIsAdmin, setOrganizationIsAdmin] = useState('');
    const [open, setOpen] = useModalState(MODAL_ACTIONS.UPDATE_ORGANIZATION_USER_MODAL);

    const selectedOrganizationUser = useSelectedOrganizationUserData();

    const currentOrganizationUserName = selectedOrganizationUser?.organizationName || '';
    const currentOrganizationIsAdmin = (selectedOrganizationUser?.isAdmin ? '1' : '2') || '';
    const currentOrganizationUserOrganizationName =
        (selectedOrganizationUser?.firstName || '') + ' ' + (selectedOrganizationUser?.lastName || '') || '';
    const navigate = useNavigate();

    useEffect(() => {
        setOrganizationIsAdmin(currentOrganizationIsAdmin);
    }, [selectedOrganizationUser]);

    const handleCloseModal = () => {
        setOpen(false);
        setOrganizationIsAdmin(currentOrganizationIsAdmin);
    };

    /**
     * Get the organization user's
     * @returns {void}
     */
    const getOrganizationUserData = (organizationId) => {
        if (!organizationId) return; // Bail initial load state
        getOrganizationUsersData(organizationId)
            .then((data) => {
                const organizationUsersData = data?.length ? data : [];
                setOrganizationUsersData(organizationUsersData);
            })
            .catch((err) => {
                showSnackBarErrorNotification(err.response.data.message);
                navigate('/dashboard');
            });
    };

    /**
     * Update selected organization
     */
    const updateOrganizationUser = () => {
        setDisabled(true);
        const { organizationId, userId } = selectedOrganizationUser;
        const data = {};

        data['isAdmin'] = Number(organizationIsAdmin) === 1 ? true : false;
        data['applications'] = [];

        updateOrganizationUserData(organizationId, userId, data)
            .then((updatedOrganization) => {
                if (updatedOrganization) {
                    setOpen(false);
                    setDisabled(false);
                    /**
                     * Optimistically update the organization user in organizations list
                     */
                    updateOrganizationUsersOptimistically('update', updatedOrganization);
                    getOrganizationUserData(organizationId);
                    showSnackBarNotification({
                        type: 'success',
                        message: 'Organization user updated Successfully',
                    });
                }
            })
            .catch((err) => {
                setDisabled(true);
                showSnackBarErrorNotification(err.response.data.message);
                navigate('/dashboard');
            });
    };

    const handleChangeIsAdmin = (event) => {
        setOrganizationIsAdmin(event.target.value);
    };

    // TO DO when application added
    // const handleChangeFormAccess = (event) => {
    //     setOrganizationUserFormApplications(event.target.value);
    // }
    // const handleChangeConnectAccess = (event) => {
    //     setOrganizationUserConnectApplications(event.target.value);
    // }

    return (
        <DialogContainer
            dialog={{
                open,
            }}
        >
            <DialogTitle>
                Edit User
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 5,
                        color: '#FFFFFF',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: 'flex',
                        paddingTop: '20px',
                        paddingBottom: '10px',
                        flexDirection: 'row',
                    }}
                >
                    <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        {profileimg ? (
                            <img
                                src={profileimg}
                                alt={currentOrganizationUserName || currentOrganizationUserOrganizationName}
                                className={classes.usericon}
                            />
                        ) : (
                            <Avatar
                                alt={currentOrganizationUserName || currentOrganizationUserOrganizationName}
                                src="/static/images/avatar/1.jpg"
                                sx={{ width: 48, height: 48 }}
                            />
                        )}
                    </StyledBadge>
                    <Box sx={{ paddingLeft: '5px' }}>
                        <Typography component="h4" variant="h6">
                            {currentOrganizationUserName || ''}
                        </Typography>
                        <Typography>{currentOrganizationUserOrganizationName || ''}</Typography>
                    </Box>
                </Box>
                <Divider
                    sx={{
                        background: '#BDBDBD',
                        mt: 1,
                        mb: 2,
                        width: '100%',
                        height: 2,
                    }}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <FormControl>
                            <Typography
                                sx={{ fontSize: 16, fontWeight: '500' }}
                                id="edit-user-organization-administrator"
                            >
                                Organization Administrator
                            </Typography>
                            <RadioGroup
                                row
                                value={
                                    organizationIsAdmin === true || organizationIsAdmin?.toString() === '1' ? '1' : '2'
                                }
                                aria-labelledby="edit-user-organization-administrator-label"
                                name="edit-user-organization-administrator"
                                onChange={handleChangeIsAdmin}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                <FormControlLabel value="2" control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    {/* <Grid item xs={12} sm={12}>
                        <FormControl>
                            <Typography sx={{ fontSize: 16, fontWeight: '500' }} id="edit-user-intely-forms-access">
                                Intely Forms Access
                            </Typography>
                            <RadioGroup
                                row
                                aria-labelledby="edit-user-intely-forms-access-label"
                                name="edit-user-intely-forms-access"
                                onChange={handleChangeFormAccess}
                                value={organizationUserFormApplications}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Admin" />
                                <FormControlLabel value="2" control={<Radio />} label="User" />
                                <FormControlLabel value="3" control={<Radio />} label="Viewer" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <FormControl>
                            <Typography sx={{ fontSize: 16, fontWeight: '500' }} id="edit-user-intely-connect-access">
                                Intely Connect Access
                            </Typography>
                            <RadioGroup
                                row
                                aria-labelledby="edit-user-intely-connect-access-label"
                                name="edit-user-intely-connect-access"
                                onChange={handleChangeConnectAccess}
                                value={organizationUserConnectApplications}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Admin" />
                                <FormControlLabel value="2" control={<Radio />} label="User" />
                                <FormControlLabel value="3" control={<Radio />} label="Viewer" />
                            </RadioGroup>
                        </FormControl>
                    </Grid> */}
                </Grid>
            </DialogContent>
            <Divider
                sx={{
                    background: '#BDBDBD',
                    width: '100%',
                    height: 2,
                }}
            />
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <LoadingButton
                    type="button"
                    variant="contained"
                    sx={{
                        mr: 2,
                        height: '40px',
                        '&.secondary': {
                            backgroundColor: '#E0E0E0',
                            color: '#333333',
                            boxShadow: 'none',
                        },
                        '&.secondary:hover': {
                            backgroundColor: '#828282',
                            color: '#333333',
                            boxShadow: 'none',
                        },
                    }}
                    className="secondary"
                    onClick={handleCloseModal}
                >
                    {'Cancel'}
                </LoadingButton>

                <LoadingButton
                    type="button"
                    variant="contained"
                    sx={{
                        mr: 2,
                        height: '40px',
                        '&.MuiLoadingButton-root:hover': {
                            backgroundColor: '#333F99',
                            color: '#FFFFFF',
                            boxShadow: 'none',
                        },
                    }}
                    onClick={updateOrganizationUser}
                >
                    {'Save'}
                </LoadingButton>
            </DialogActions>
        </DialogContainer>
    );
};

export default EditOrganizationUserModal;
