import TextField from '../TextField';

const CompanyPositionField = (props) => {
    const validate = {
        emptyLabel: 'Please enter job title.',
    };

    return (
        <TextField
            placeholder="Your Job Title"
            autoComplete="jobTitle"
            label="Job Title"
            name="jobTitle"
            validate={validate}
            {...props}
        />
    );
};

export default CompanyPositionField;
