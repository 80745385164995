import { useState } from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import CustomBootstrapDialog, { BootstrapModalTitle } from '../BootstrapModal';

import { useStorageAccessModal, closeStorageAccessModal } from 'hooks/useModalHook';
import { isDateAtLeastOneDayBeforeToday, formatDateTime } from 'utils/dateUtils';
import { requestStorageAccess } from 'utils/organizationUtils';
import { getCurrentOrganizationId } from 'utils/loginUtils';

const StorageAccessModal = () => {
    const storageAccessModalStates = useStorageAccessModal() || {},
        isOpen = storageAccessModalStates?.open || false,
        lastSubmittedStorageAccessRequestDate = storageAccessModalStates?.lastSubmittedStorageAccessRequestDate || '',
        isRequestStorageDisabled = lastSubmittedStorageAccessRequestDate
            ? !isDateAtLeastOneDayBeforeToday(lastSubmittedStorageAccessRequestDate)
            : false;

    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        closeStorageAccessModal(false);
    };

    const onSubmit = async () => {
        setIsLoading(true);
        try {
            await requestStorageAccess(getCurrentOrganizationId());
        } catch (error) {
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };

    const titleStyle = {
            fontSize: '16px',
            fontWeight: '500',
        },
        bodyTextStyle = {
            fontSize: '14px',
            marginBottom: '20px',
        },
        buttonStyle = (type) => ({
            background: type === 'cancel' ? '#C4C4C4' : '#3A70D4',
            color: type === 'cancel' ? 'black' : 'white',
            '&:hover': {
                backgroundColor: type === 'cancel' ? '#d4d1d1' : '#376fd8',
            },
            fontWeight: 700,
            padding: '6px 16px',

            '&:disabled': {
                backgroundColor: '#C4C4C4',
            },
        });

    const requested = lastSubmittedStorageAccessRequestDate ? (
        <Typography style={{ ...bodyTextStyle, color: 'grey' }}>
            Last requested on {formatDateTime(lastSubmittedStorageAccessRequestDate, 'YYYY-MM-DD hh:mm A')}
        </Typography>
    ) : null;

    return (
        <CustomBootstrapDialog open={isOpen}>
            <BootstrapModalTitle sx={titleStyle} onClose={handleClose}>
                Storage Access
            </BootstrapModalTitle>
            <DialogContent>
                <Typography style={bodyTextStyle}>
                    To enable storage for this organization, please select the &apos;Request Storage&apos; button. Your
                    request will be sent to a member of the Intely team for review.
                    <br /> <br />
                    If you&apos;ve submitted a request and are waiting to hear back, please submit a support ticket
                    using the &apos;Contact Support&apos; button in the top bar. In case we have not responded to your
                    request, you may re-request storage access after 24 hours.
                </Typography>
                <br />
                {requested}
            </DialogContent>
            <DialogActions
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    borderTop: '1px solid #C4C4C4',
                }}
            >
                <Button sx={buttonStyle('cancel')} onClick={handleClose}>
                    Cancel
                </Button>
                <Button sx={buttonStyle('submit')} disabled={isRequestStorageDisabled || isLoading} onClick={onSubmit}>
                    Request Storage
                </Button>
            </DialogActions>
        </CustomBootstrapDialog>
    );
};

export default StorageAccessModal;
