import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { unstable_useTreeItem2 as useTreeItem2 } from '@mui/x-tree-view';
import {
    TreeItem2Content,
    TreeItem2IconContainer,
    TreeItem2GroupTransition,
    TreeItem2Label,
    TreeItem2Root,
} from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { useTreeItem2Utils } from '@mui/x-tree-view/hooks/useTreeItem2Utils';

const CustomTreeItem = forwardRef((props, ref) => {
    const {
        id,
        itemId,
        children,
        label,
        labelIcon,
        CustomComponent = null,
        disabled,
        handleExpansionClick: handleExpansionClickProp,
        handleItemClick: handleItemClickProp,
        ...other
    } = props;

    const { getRootProps, getContentProps, getIconContainerProps, getLabelProps, getGroupTransitionProps, status } =
        useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

    const { interactions } = useTreeItem2Utils({
        itemId: itemId,
        children: children,
    });

    const handleContentClick = (event) => {
        event.defaultMuiPrevented = true;
        handleItemClickProp && handleItemClickProp(itemId);
    };

    const handleIconClick = (event) => {
        interactions.handleExpansion(event);
        handleExpansionClickProp && handleExpansionClickProp(itemId);
    };

    return (
        <TreeItem2Provider itemId={itemId}>
            <TreeItem2Root {...getRootProps(other)}>
                <TreeItem2Content {...getContentProps()} onClick={() => {}}>
                    <TreeItem2IconContainer {...getIconContainerProps()} onClick={handleIconClick}>
                        <TreeItem2Icon status={status} />
                    </TreeItem2IconContainer>
                    {CustomComponent ? (
                        CustomComponent
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                columnGap: '6px',
                                alignItems: 'center',
                                width: '100%',
                            }}
                            onClick={handleContentClick}
                        >
                            {labelIcon}
                            <TreeItem2Label
                                {...getLabelProps()}
                                sx={{
                                    fontSize: '14px',
                                }}
                            />
                        </div>
                    )}
                </TreeItem2Content>
                {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
            </TreeItem2Root>
        </TreeItem2Provider>
    );
});

CustomTreeItem.displayName = 'CustomTreeItem';
CustomTreeItem.propTypes = {
    id: PropTypes.string,
    itemId: PropTypes.string.isRequired,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    labelIcon: PropTypes.node,
    CustomComponent: PropTypes.node,
    handleExpansionClick: PropTypes.func,
    handleItemClick: PropTypes.func,
};

export default CustomTreeItem;
