import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

const FlexEndGrid = ({ gridProps = {}, children }) => {
    const loadingButtonGridStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    };

    return (
        <Grid item xs={12} sm={6} sx={loadingButtonGridStyle} {...gridProps}>
            {children}
        </Grid>
    );
};

FlexEndGrid.propTypes = {
    children: PropTypes.node.isRequired,
    gridProps: PropTypes.object,
};

export default FlexEndGrid;
