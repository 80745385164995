import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { setSelectedOrganizationUserData } from '../../../../../hooks/useOrganizationUsersHook';
import { MODAL_ACTIONS, setModalState } from '../../../../../hooks/useModalStateHook';

const OrganizationUsernNameColumn = (props) => {
    const { organizationUser } = props;

    const onOrganizationEdit = () => {
        setSelectedOrganizationUserData(organizationUser);
        setModalState(MODAL_ACTIONS.UPDATE_ORGANIZATION_USER_MODAL)(true);
    };

    return (
        <div onClick={() => onOrganizationEdit()}>
            <Typography
                sx={{
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '0.875rem',
                    color: '#333333',
                    '&:hover': { textDecoration: 'underline', cursor: 'pointer' },
                }}
            >
                {(organizationUser?.firstName || '') + ' ' + (organizationUser?.lastName || '')}
            </Typography>
        </div>
    );
};

OrganizationUsernNameColumn.propTypes = {
    organizationUser: PropTypes.object.isRequired,
};

export default OrganizationUsernNameColumn;
