import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#333',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#333',
    },
}));

export default BootstrapTooltip;
