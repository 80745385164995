import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

const NumberOfFailuresIcon = (props) => {
    const fill = props.fill || '#BB6BD9';

    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1975_14094)">
                <path
                    d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z"
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_1975_14094">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};

NumberOfFailuresIcon.propTypes = {
    fill: PropTypes.string,
};

export default NumberOfFailuresIcon;
