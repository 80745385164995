import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

const DashboardIcon = (props) => {
    const fill = props.fill || 'white';

    return (
        <SvgIcon width="15" height="16" viewBox="0 0 15 16" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path d="M0 16V5.33333L7.15556 0L14.2222 5.33333V16H9.02222V9.66667H5.17778V16H0Z" fill={fill} />
        </SvgIcon>
    );
};

DashboardIcon.propTypes = {
    fill: PropTypes.string,
};

export default DashboardIcon;
