/**
 * Convert rem to pixels
 * @param {number} rem
 * @return {number} Converted rem in pixels
 */
export function remToPx(value) {
    return Math.round(parseFloat(value) * 16);
}

/**
 * Convert pixels to rem
 * @param {number} value
 * @returns {string} Converted pixels in rem
 */
export function pxToRem(value) {
    return `${value / 16}rem`;
}

/**
 * Auto adjust the font size to fit the given screen size
 * @param {*} arg
 * @returns {string} Adjusted font size.
 */
export function responsiveFontSizes({ sm, md, lg }) {
    return {
        '@media (min-width:600px)': {
            fontSize: pxToRem(sm),
        },
        '@media (min-width:900px)': {
            fontSize: pxToRem(md),
        },
        '@media (min-width:1200px)': {
            fontSize: pxToRem(lg),
        },
    };
}
