import { showSnackBarSuccessNotification } from './snackBarNotificationUtils';
import { authorizedAxiosInstance } from '../axiosInstances';
import { logError, getGatewayUrl } from './envUtils';
import { getCurrentUserId } from '../hooks/useUserHook';

/**
 * Update the user profile details
 * @param {userData} userData
 * @param {boolean} userData.id
 * @param {boolean} userData.mfa
 * @param {string} userData.phoneNumber
 * @param {string} userData.companyPosition
 * @param {string} userData.companyName
 * @param {string} userData.firstName
 * @param {string} userData.lastName
 * @param {string} userData.profileImage
 * @returns {Promise<*>} Promise resolved on success or rejected on failure.
 */
export const updateUserDetails = async (userData) => {
    return new Promise((resolve, reject) => {
        authorizedAxiosInstance
            .patch(getGatewayUrl(`/user/${getCurrentUserId()}`), {
                mfa: userData.mfa,
                phone: userData.phoneNumber,
                roles: userData.companyPosition,
                lastName: userData.lastName,
                firstName: userData.firstName,
                profileImg: userData.profileImage,
                companyName: userData.companyName,
                defaultOrganizationId: userData.defaultOrganizationId,
                jobTitle: userData.jobTitle,
            })
            .then(async (res) => {
                if (res.status === 200) {
                    await updateProfileFormService({
                        lastName: userData.lastName,
                        firstName: userData.firstName,
                        companyName: userData.companyName,
                    });
                    showSnackBarSuccessNotification('Profile updated successfully. !');
                    resolve(res);
                }
            })
            .catch((err) => reject(err.response));
    });
};

/**
 * Update intelyFrom user profile
 */
export const updateProfileFormService = async (credentials) => {
    return new Promise((resolve) => {
        authorizedAxiosInstance
            .post(getGatewayUrl(`/forms/user/update`), credentials)
            .then(() => resolve(true))
            .catch((error) => {
                logError('updateProfileFormService: ', error);
                resolve(false);
            });
    });
};

/**
 * Update user password
 * @param {string} newPassword New user password
 * @param {string} oldPassword Old user password
 * @returns {Promise<*>} Promise resolved on success or rejected on failure.
 */
export const updateUserPassword = (newPassword, oldPassword) => {
    return new Promise((resolve, reject) => {
        authorizedAxiosInstance
            .patch(getGatewayUrl(`/user/${getCurrentUserId()}/changePassword`), {
                newPassword: newPassword,
                currentPassword: oldPassword,
            })
            .then((res) => {
                resolve(res.status === 200 ? res.status : false);
            })
            .catch((err) => {
                logError('updateUserPassword: ', err);
                reject(err.response);
            });
    });
};
