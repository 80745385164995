import { memo, useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

import useUser from '../../hooks/useUserHook';
import { useStoreState } from '../../hooks/useStoreStateHook';
import { getUserData } from '../../utils/loginUtils';
import { showSnackBarErrorNotification } from '../../utils/snackBarNotificationUtils';

import ProfileDivider from './ProfileDivider';
import EditUserDetails from './EditUserDetails';
import EditUserPassword from './EditUserPassword';
import PersonalAccessTokens from './PersonalAccessTokens';

const Profile = () => {
    const user = useUser(),
        [userData, setUserData] = useStoreState('userAccountData')({
            id: user.userId,
            email: user.email,
            lastName: user.lastName,
            firstName: user.firstName,
            phoneNumber: null,
            jobTitle: null,
            defaultOrganizationId: user?.defaultOrganizationId,
        }),
        profileBoxContentStyle = {
            marginTop: 5,
            paddingBottom: 5,
        };

    /**
     * Setup useEffect to get current user details
     */
    useEffect(async () => {
        if (user.userId) {
            await getUserData(user.userId)
                .then((data) => {
                    setUserData({ ...data, phoneNumber: data.phone, jobTitle: data?.jobTitle || '' });
                })
                .catch(() => {
                    showSnackBarErrorNotification('Unable to load user account data');
                });
        }
        /* eslint-disable */
    }, [user.refreshedAt]);

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />

            <Box sx={profileBoxContentStyle}>
                <EditUserDetails userData={userData} />

                <ProfileDivider sx={{ mb: 3 }} />

                <EditUserPassword />

                <ProfileDivider />

                <PersonalAccessTokens userId={user.userId || ''} userAccessTokens={userData.accessTokens || []} />
            </Box>
        </Container>
    );
};

export default memo(Profile);
