/**
 * Check if the passed object is a react state function
 * @param {*} obj
 * @returns {boolean}
 */
const isStateArray = (obj) => typeof obj === 'function';

/**
 * Set the input state data object
 * @param {function} stateObj
 * @param {React.MutableRefObject} inputRef
 * @param {*} value
 */
const setStateObjProp = (setState, inputRef, value) => {
    setState((prevState) => ({ ...prevState, [inputRef.current.name]: value }));
};

/**
 * An helper to hook into the input change event and set the inputted value
 *
 * Note: Expected to pass raw object: dataRefObj.current
 *
 * @param {React.MutableRefObject|function} dataRefObj Ref to hold
 * input data or a react state.
 * @param {function} callbackFunc A callback function to be executed
 * @returns {function} callback which can be pass to validationSuccessCallback
 */
export const setInputFieldValueCallback = (dataRefObj, callbackFunc = null) => {
    const stateArray = isStateArray(dataRefObj);

    return ({ value, inputRef }) => {
        if (stateArray) {
            setStateObjProp(dataRefObj, inputRef, value);
        } else {
            if (inputRef.current.name) {
                dataRefObj[inputRef.current.name] = value;
            }
        }

        callbackFunc && callbackFunc();
    };
};

/**
 * An helper to hook into the input change event and clear the previous value
 * of the current input field stored in the `dataRefObj`.
 *
 * Note: Expected to pass raw object: dataRefObj.current
 *
 * @param {React.MutableRefObject|function} dataRefObj Ref to hold input data
 * @param {function} callbackFunc A callback function to be executed
 * @returns {function} callback which can be pass to validationErrorCallback
 */
export const clearInputFieldValueCallback = (dataRefObj, callbackFunc = null) => {
    const stateArray = isStateArray(dataRefObj);

    return ({ inputRef }) => {
        if (stateArray) {
            setStateObjProp(dataRefObj, inputRef, null);
        } else {
            const fieldName = inputRef.current.name;
            if (fieldName && dataRefObj[fieldName] !== null) {
                dataRefObj[fieldName] = null;
            }
        }

        callbackFunc && callbackFunc();
    };
};

/**
 * Check whether all input fields are filled correctly.
 *
 * Note: Expected to pass raw object: dataRefObj.current
 *
 * @returns {Promise<boolean>} True if all input fields are filled
 * correctly. Otherwise false.
 */
export const isInputFieldsReady = (dataRefObj) => async () => {
    for (const o in dataRefObj) {
        const fieldValue = dataRefObj[o];
        if (fieldValue === null || fieldValue === '') {
            return false;
        }
    }
    return true;
};

/**
 * Get the input value for case where the input value has
 * a default value. This ensures the dataRefObj is populated
 * properly.
 * @param {React.MutableRefObject} inputRef Input ref object
 * @param {boolean} canRunAction Whether to run action callback
 * @return {object<boolean, function>}
 */
export const getInputFieldValues = (dataRefObj, canRunAction) => {
    const getFieldValue = (inputRef) => {
        if (inputRef.current.name) {
            dataRefObj[inputRef.current.name] = inputRef.current.value;
        }
    };

    return {
        action: canRunAction,
        callback: getFieldValue,
    };
};

/**
 * Reset the input field ref data object
 * Note: Expected to pass raw object: dataRefObj.current
 */
export const resetDataRefObj = (dataRefObj) => {
    for (const o in dataRefObj) {
        dataRefObj[o] = null;
    }
};
