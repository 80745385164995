import PropTypes from 'prop-types';
import CommentIcon from 'assets/icons/CommentIcon';
import SidebarButton from '../SidebarButton';

import styles from '../styles';

const FeedbackSidebarButton = ({ sidebarMenu }) => {
    const handleClick = () => {
        window.location.replace('https://ideas.intely.io/');
    };

    const rootStyle = { ...styles.feedbackButtonWrapper };

    if (sidebarMenu.collapseMenu) {
        rootStyle.bottom = '1px';
        rootStyle.paddingLeft = '10px';
    }

    return (
        <div style={styles.feedbackButtonWrapper}>
            <SidebarButton
                isActive={false}
                style={styles.bottomMenu}
                label="Feedback/Ideas"
                onClick={handleClick}
                icon={<CommentIcon style={styles.menuIcon} />}
                collapseMenu={sidebarMenu.collapseMenu}
            />
        </div>
    );
};

FeedbackSidebarButton.propTypes = {
    sidebarMenu: PropTypes.object.isRequired,
};

export default FeedbackSidebarButton;
