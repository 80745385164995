import { SvgIcon } from '@mui/material';

const Organization = (props) => {
    return (
        <SvgIcon width="20" height="16" viewBox="0 0 20 16" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.5 1.5H17.5V5.6875C18.0312 5.78125 18.5625 5.96875 19 6.28125V1.5625C19 0.71875 18.3125 0 17.5 0H2.5C1.65625 0 1 0.71875 1 1.5625V6.28125C1.40625 5.96875 1.9375 5.78125 2.5 5.6875V1.5ZM3.375 12.625H2.625C1.15625 12.625 0 13.8125 0 15.25C0 15.6875 0.3125 16 0.75 16C1.15625 16 1.5 15.6875 1.5 15.25C1.5 14.6562 2 14.125 2.625 14.125H3.375C3.96875 14.125 4.5 14.6562 4.5 15.25C4.5 15.6875 4.8125 16 5.25 16C5.65625 16 6 15.6875 6 15.25C6 13.8125 4.8125 12.625 3.375 12.625ZM17 6.625C15.5938 6.625 14.5 7.75 14.5 9.125C14.5 10.5312 15.5938 11.625 17 11.625C18.375 11.625 19.5 10.5312 19.5 9.125C19.5 7.75 18.375 6.625 17 6.625ZM17 10.125C16.4375 10.125 16 9.6875 16 9.125C16 8.59375 16.4375 8.125 17 8.125C17.5312 8.125 18 8.59375 18 9.125C18 9.6875 17.5312 10.125 17 10.125ZM17.375 12.625H16.625C15.1562 12.625 14 13.8125 14 15.25C14 15.6875 14.3125 16 14.75 16C15.1562 16 15.5 15.6875 15.5 15.25C15.5 14.6562 16 14.125 16.625 14.125H17.375C17.9688 14.125 18.5 14.6562 18.5 15.25C18.5 15.6875 18.8125 16 19.25 16C19.6562 16 20 15.6875 20 15.25C20 13.8125 18.8125 12.625 17.375 12.625ZM3 11.625C4.375 11.625 5.5 10.5312 5.5 9.125C5.5 7.75 4.375 6.625 3 6.625C1.59375 6.625 0.5 7.75 0.5 9.125C0.5 10.5312 1.59375 11.625 3 11.625ZM3 8.125C3.53125 8.125 4 8.59375 4 9.125C4 9.6875 3.53125 10.125 3 10.125C2.4375 10.125 2 9.6875 2 9.125C2 8.59375 2.4375 8.125 3 8.125ZM7.5 9.125C7.5 10.5312 8.59375 11.625 10 11.625C11.375 11.625 12.5 10.5312 12.5 9.125C12.5 7.75 11.375 6.625 10 6.625C8.59375 6.625 7.5 7.75 7.5 9.125ZM11 9.125C11 9.6875 10.5312 10.125 10 10.125C9.4375 10.125 9 9.6875 9 9.125C9 8.59375 9.4375 8.125 10 8.125C10.5312 8.125 11 8.59375 11 9.125ZM10.375 12.625H9.625C8.15625 12.625 7 13.8125 7 15.25C7 15.6875 7.3125 16 7.75 16C8.15625 16 8.5 15.6875 8.5 15.25C8.5 14.6562 9 14.125 9.625 14.125H10.375C10.9688 14.125 11.5 14.6562 11.5 15.25C11.5 15.6875 11.8125 16 12.25 16C12.6562 16 13 15.6875 13 15.25C13 13.8125 11.8125 12.625 10.375 12.625Z"
                fill="white"
            />
        </SvgIcon>
    );
};
export default Organization;
