import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const ResponsesStatus = ({ statusCode, showCode = false }) => {
    let status = 'Errored',
        className = 'responseStatusTextError';

    if (`${statusCode}`.indexOf('20') === 0) {
        status = 'Success';
        className = 'responseStatusTextSuccess';
    }

    return (
        <Typography className={`responseStatusText ${className}`}>
            {status}
            {showCode ? ` — ${statusCode}` : null}
        </Typography>
    );
};

ResponsesStatus.propTypes = {
    showCode: PropTypes.bool,
    statusCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ResponsesStatus;
