import { memo, useEffect, useState } from 'react';

import TableContainer from '../../Containers/TableContainer';
import TableColumns from './TableColumns';

import AppLoader from 'components/Loaders/AppLoader';

import {
    useIntelyStorageSelectedFolder,
    useNewlyAddedFile,
    useFilteredFiles,
    useDataTableLoading,
    fetchCurrentPageFiles,
    setCurrentSort,
    useFileFilters,
    useCurrentSort,
} from 'hooks/useMyFilesHook';

const IntelyStorageFilesTable = () => {
    const selectedFolder = useIntelyStorageSelectedFolder(),
        newlyAddedFile = useNewlyAddedFile(),
        filteredFiles = useFilteredFiles(),
        isLoading = useDataTableLoading(),
        filters = useFileFilters(),
        currentSort = useCurrentSort();

    const [isPageLoading, setIsPageLoading] = useState(true),
        [sort, setSort] = useState({}),
        [page, setPage] = useState(1);

    useEffect(() => {
        if (currentSort.field.length > 0) {
            setSort({
                field: currentSort.field,
                direction: currentSort.direction,
            });
        }
    }, []);

    const onPageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    useEffect(() => {
        const queryOptions = {},
            sortOptions = {};

        if (filters.searchTerm) {
            queryOptions.searchText = filters.searchTerm;
        }

        if (sort.field?.length > 0) {
            sortOptions[sort.field] = sort.direction;
        }

        setIsPageLoading(true);
        fetchCurrentPageFiles(
            selectedFolder.path,
            page,
            {
                sorts: sortOptions,
            },
            queryOptions,
        );
        setIsPageLoading(false);
    }, [page]);

    const handleSort = (column, sortDirection) => {
        setIsPageLoading(true);
        let key;

        switch (column.fieldName) {
            case 'name':
                key = 'relativePath';
                break;
            case 'modifiedAt':
                key = 'fileModifiedAt';
                break;
            case 'size':
                key = 'size';
                break;
            // ========= Uncomment this block to enable sorting by modifiedBy =========
            // Changes needed to be done in IAM to make this work
            // case 'modifiedBy':
            //     key = 'fileModifiedBy';
            //     break;
            default:
                break;
        }

        if (key) {
            const direction = sortDirection === 'desc' ? -1 : 1;
            setCurrentSort(key, direction);
            setSort({
                field: key,
                direction,
            });

            setTimeout(() => {}, 1500);

            if (page === 1) {
                fetchCurrentPageFiles(selectedFolder.path, 1, {
                    sorts: {
                        [key]: direction,
                    },
                });
            }
        }

        setIsPageLoading(false);
    };

    useEffect(() => {
        if (isLoading) {
            setIsPageLoading(true);
        } else {
            setIsPageLoading(false);
        }
    }, [isLoading]);

    if (isLoading) {
        return <AppLoader />;
    } else {
        return (
            <TableContainer
                data={filteredFiles.filtered ? filteredFiles.files : selectedFolder.files}
                onChangePage={onPageChange}
                loading={isPageLoading}
                columns={TableColumns({})}
                emptyDataText="No files in selected folder."
                style={{
                    border: '1px solid #E0E0E0',
                    borderLeft: 'none',
                    borderRight: 'none',
                    height: '100%',
                    overflowY: 'auto',
                }}
                updatedRowId={newlyAddedFile}
                idOverride="path"
                defaultSortFieldId={2}
                defaultSortAsc={false}
                pagination
                paginationServer={true}
                paginationTotalRows={filteredFiles.filtered ? filteredFiles.length : selectedFolder.totalFiles}
                paginationPerPage={100}
                paginationRowsPerPageOptions={[100]}
                sortServer={true}
                onSort={handleSort}
            />
        );
    }
};

export default memo(IntelyStorageFilesTable);
