import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import styles from './styles';

const PageTitleBar = ({ title = '', actionButtons, infoButton }) => {
    if (!title) return null;

    const buttons = actionButtons.map((actionButton, index) => {
        const actionButtonStyle = { ...styles.createMappingBtn, ...(actionButton?.style || {}) };

        return (
            <Button key={index} onClick={actionButton.onClick} style={actionButtonStyle}>
                {actionButton.label}
            </Button>
        );
    });

    return (
        <div style={styles.root}>
            <div style={styles.headingWrapper}>
                <Typography style={styles.heading}>{title}</Typography>
            </div>

            {actionButtons.length ? (
                <div style={{ display: 'flex', flexDirection: 'row', columnGap: '12px' }}>{buttons}</div>
            ) : null}

            {infoButton && (
                <IconButton onClick={infoButton.onClick} style={styles.infoBtn}>
                    <MoreHorizIcon />
                </IconButton>
            )}
        </div>
    );
};

PageTitleBar.propTypes = {
    title: PropTypes.node,
    actionButtons: PropTypes.arrayOf(PropTypes.object),
    infoButton: PropTypes.object,
};

export default PageTitleBar;
