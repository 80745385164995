// __webpack_nonce__ is a magic var set so webpack is in the know about the nonce value and can use it
__webpack_nonce__ = window.nonce; // eslint-disable-line
window.__webpack_nonce__ = window.nonce;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import reportWebVitals from './reportWebVitals';

import './index.css';
import store from './redux/store';

import App from './App';
import ThemeProvider from './theme';

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider>
            <Provider store={store}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <App />
                </LocalizationProvider>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

reportWebVitals();
