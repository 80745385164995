export default {
    root: (isButtonActive) => ({
        display: 'flex',
        alignItems: 'center',
        minWidth: '80px',
        justifyContent: 'flex-start',
        border: '1px solid #3A71D5',
        textAlign: 'left',
        color: isButtonActive ? '#fff !important' : '#3A71D5 !important',
        backgroundColor: isButtonActive ? '#3A71D5 !important' : '#fff !important',

        '& .MuiOutlinedInput-root': {
            borderRadius: '4px',
        },

        '& span': {
            flexGrow: 1,
            fontSize: '14px',
        },

        '& svg': {
            fontSize: '22px',
            marginRight: '-5px',
            transition: 'all 300ms',
            transform: isButtonActive ? 'rotate(180deg)' : undefined,
        },
    }),
};
