import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';

const ProfileDivider = ({ sx = {} }) => {
    const style = {
        mt: 2,
        height: 2,
        width: '100%',
        background: '#BDBDBD',
        ...sx,
    };

    return <Divider sx={style} />;
};

ProfileDivider.propTypes = {
    sx: PropTypes.object,
};

export default ProfileDivider;
