import { combineReducers } from 'redux';

import auth from './auth.reducer';
import modals from './modals.reducer';
import loading from './startloading.reducer';
import appUsage from './appUsage.reducer';
import feedback from './snackbar.reducer';
import storeStates from './storeStates.reducer';
import intelyStorage from './intelyStorage.reducer';
import organizationLogging from './organizationLogging.reducer';
import authUserSessionToken from './authUserSessionToken.reducer';
import currentOrganization from './currentOrganization.reducer';
import organizationUsersData from './organizationUsersData.reducer';

const reducers = combineReducers({
    auth,
    modals,
    loading,
    feedback,
    appUsage,
    storeStates,
    intelyStorage,
    organizationLogging,
    currentOrganization,
    authUserSessionToken,
    organizationUsersData,
});

export default reducers;
