import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { MONTHS } from '../config/constants';

dayjs.extend(utc);

/**
 * Get a date parsable by Date() function
 * @param {*} date
 * @returns {string} Date string
 */
export const getDateString = (date) => {
    const datObj = new Date(date);

    return `${datObj?.getDate()} ${MONTHS[datObj?.getMonth()]} ${datObj?.getFullYear()}`;
};

/**
 * Check if given date is after today.
 * @param {*} date Date to check
 * @return {boolean} True if date is after today. Otherwise false.
 */
export const isDateAfterToday = (date) => new Date(date) > new Date();

/**
 * Get ISO date based on current date and time
 * @returns {string} ISO date based on current date and time
 */
export const getIsoDateNow = () => new Date().toISOString();

/**
 * Get current date in given format. Default format: YYYY-MM-DD
 * @param {{ format: string }} format
 * @returns {string}
 */
export const getCurrentDate = (format = 'YYYY-MM-DD') => dayjs().format(format);

/**
 * Get current date in given format. Default format: YYYY-MM-DD
 * @param {{ format: string }} format
 * @returns {string}
 */
export const formatDate = (date, format = 'YYYY-MM-DD') => dayjs(date).format(format);

/**
 * Get current date-time in given format. Default format: YYYY-MM-DD HH:mm:ss
 * @param {{ format: string }} format
 * @returns {string}
 */
export const formatDateTime = (date, format = 'YYYY-MM-DD HH:mm:ss.ms') => dayjs(date).format(format);

/**
 * Get added date and return in given format
 * @param {{ unit: string, unitValue: number, format: string, isISO: boolean}} args
 * @returns {string}
 */
export const getAddedDateFromFormat = ({ format, unit, unitValue, isISO }) => {
    const date = dayjs().add(unitValue, unit);
    if (isISO) return date.toISOString();
    return date.format(format);
};

/**
 * Get subtracted date and return in given format
 * @param {{ unit: string, unitValue: number, format: string, isISO: boolean}} args
 * @returns {string}
 */
export const getSubtractedDateFromFormat = ({ format, unit, unitValue, isISO }) => {
    const date = dayjs().subtract(unitValue, unit);
    if (isISO) return date.toISOString();
    return date.format(format);
};

/**
 * Check if given date is at least one day before today
 * @param {*} date
 * @returns {boolean}
 */
export const isDateAtLeastOneDayBeforeToday = (date) => {
    return dayjs(date).isBefore(dayjs().subtract(1, 'day'));
};
