export default {
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    headerRootContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: '20px',
        width: '100%',
        alignItems: 'center',
        columnGap: '12px',
    },
    filterRoot: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '4px 12px 8px 12px',

        '& .filterHeader': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: '12px',

            '& .filterLine': {
                flexGrow: 1,
                borderTop: '1px solid #E0E0E0',
            },

            '& .filterTitle': {
                fontWeight: 700,
            },
        },

        '& .filterSelectors': {
            display: 'flex',
            flexDirection: 'row',
            columnGap: '20px',
            marginTop: '12px',
        },

        '& .selectContainer': {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '12px',

            '& .label': {
                fontSize: '14px',
            },

            '& .MuiOutlinedInput-root': {
                height: '35px !important',
                borderRadius: '4px !important',
                marginTop: '1px',

                '& input': {
                    padding: '10px !important',
                    borderRadius: '0px',
                    transition: 'all 300ms',
                    fontSize: '14px',
                    backgroundColor: '#fff',
                },
            },

            '& .MuiInputBase-root': {
                width: '220px',
            },
        },
    },
};
