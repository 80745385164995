export default {
    root: {
        height: '32px',
        minWidth: '100px',
        width: '300px',
        borderRadius: '4px',
        fontSize: '14px',
        backgroundColor: 'transparent !important',
        borderColor: '#C4C4C4 !important',

        '& .MuiInputBase-input ': {
            backgroundColor: 'transparent !important',
        },

        '&:hover fieldset': {
            borderColor: ' #3A71D5 !important',
        },
    },
};
