import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import styles from './styles';
import SuccessRateIcon from 'assets/icons/SuccessRateIcon';
import AppUsageWidget from 'components/Widgets/AppUsageWidget';
import SelectField from 'components/InputFields/SelectField';
import { evaluateAppUsageData, setAppUsageData, setupAppUsageData, useAppUsageValue } from 'hooks/useAppUsageHook';
import useSelectedOrganization from 'hooks/useSelectedOrganizationHook';
import NumberOfRequests from 'assets/icons/NumberOfRequestsIcon';
import ComputerChipIcon from 'assets/icons/ComputerChipIcon';
import NumberOfFailuresIcon from 'assets/icons/NumberOfFailuresIcon';
import EnvelopeSquareIcon from 'assets/icons/EnvelopeSquareIcon';
import PaperPlaneIcon from 'assets/icons/PaperPlaneIcon';
import { getSubtractedDateFromFormat } from 'utils/dateUtils';
import { formatFileSizeInKb } from 'utils/fileUtils';
import { formatNumber } from 'utils/numberUtils';

const getUsageEndDate = (interval) => {
    let timeUnit = 'hour';
    if (interval.indexOf('Days') > -1) {
        timeUnit = 'day';
    }

    const unitValue = +interval.replace(/[^\d]/g, '');

    return getSubtractedDateFromFormat({ unitValue, isISO: true, unit: timeUnit });
};

const AppUsageContainer = () => {
    const [dataInterval, setDataInterval] = useState('24 Hours'),
        [usageType, setUsageType] = useState('all'),
        [{ organizationId }] = useSelectedOrganization(),
        appUsage = useAppUsageValue(),
        dataIntervals = [
            { label: 'Last Hour', value: '1 Hour' },
            { label: 'Last 24 Hours', value: '24 Hours' },
            { label: 'Last 7 Days', value: '7 Days' },
            { label: 'Last 30 Days', value: '30 Days' },
            { label: 'Last 90 Days', value: '90 Days' },
        ],
        usageTypes = [
            { label: 'All', value: 'all' },
            { label: 'App Request', value: 'appRequest' },
            { label: 'Mapping', value: 'mapping' },
            { label: 'Integration', value: 'integration' },
            { label: 'Webhook', value: 'webhook' },
        ];

    useEffect(() => {
        async function runAppUsageRequest() {
            if (appUsage?.appState?.usageType === usageType && appUsage?.reactState?.dataInterval === dataInterval)
                return;

            setAppUsageData({
                isLoaded: false,
                appState: { usageType, dataInterval },
                ...evaluateAppUsageData(),
            });

            setupAppUsageData({
                organizationId,
                type: usageType,
                endDate: getUsageEndDate(dataInterval),
                appState: { usageType, dataInterval },
            });
        }
        runAppUsageRequest();
    }, [dataInterval, usageType]);

    const handleDataIntervalChange = (e) => {
        setDataInterval(e.target.value);
    };

    const handleUsageTypeChange = (e) => {
        setUsageType(e.target.value);
    };

    return (
        <Box sx={styles.root}>
            <Typography component="div" className="widgetNamespace">
                <div className="widgetNamespaceTitle">
                    Connect
                    <span className="widgetNamespaceLine"></span>
                </div>
                <div className="widgetNamespaceUsageType widgetNamespaceDataIntervalField">
                    <Typography className="widgetFieldTitle">Type</Typography>
                    <SelectField
                        menuWidth={200}
                        value={usageType}
                        inputDataList={usageTypes}
                        label={''}
                        required={false}
                        handleChange={handleUsageTypeChange}
                    />
                </div>
                <div className="widgetNamespaceDataIntervalField">
                    <Typography className="widgetFieldTitle">Time Interval</Typography>
                    <SelectField
                        menuWidth={200}
                        isSearchEnabled={false}
                        value={dataInterval}
                        inputDataList={dataIntervals}
                        label={''}
                        required={false}
                        handleChange={handleDataIntervalChange}
                        menuProps={{
                            sx: { '& .MuiPopover-root': { boxShadow: '1px 2px 2px 2px #ccc' } },
                        }}
                    />
                </div>
            </Typography>
            <Box sx={styles.widgetsWrapper}>
                <AppUsageWidget
                    title="Number of Requests"
                    value={formatNumber({ number: appUsage.totalRequest })}
                    icon={<NumberOfRequests />}
                    isLoading={!appUsage.isLoaded}
                />
                <AppUsageWidget
                    title="Success Rate"
                    value={`${formatNumber({ number: appUsage.successRate, unit: false })}%`}
                    icon={<ComputerChipIcon />}
                    isLoading={!appUsage.isLoaded}
                />
                <AppUsageWidget
                    title="Number of Failures"
                    value={formatNumber({ number: appUsage.totalFailureRequest, unit: false })}
                    icon={<NumberOfFailuresIcon />}
                    isLoading={!appUsage.isLoaded}
                />
                <AppUsageWidget
                    title="Avg Run Time"
                    value={`${formatNumber({ number: appUsage.avgRunTime, unit: false })}s`}
                    icon={<SuccessRateIcon />}
                    isLoading={!appUsage.isLoaded}
                />
                <AppUsageWidget
                    title="Avg Request Size"
                    value={`${formatFileSizeInKb({ fileSizeInKb: appUsage.avgRequestSize })}`}
                    icon={<EnvelopeSquareIcon />}
                    isLoading={!appUsage.isLoaded}
                />
                <AppUsageWidget
                    title="Avg Response Size"
                    value={`${formatFileSizeInKb({ fileSizeInKb: appUsage.avgResponseSize })}`}
                    icon={<PaperPlaneIcon />}
                    isLoading={!appUsage.isLoaded}
                />
            </Box>
        </Box>
    );
};

export default AppUsageContainer;
