import SvgIcon from '@mui/material/SvgIcon';

const UserIcon = (props) => {
    return (
        <SvgIcon width="14" height="19" {...props} viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.38393 11.5572C8.95362 11.5572 8.6152 11.6738 8.32791 11.7728C8.28907 11.7862 8.25117 11.7992 8.2141 11.8116C7.90321 11.9157 7.55747 12.0143 7 12.0143C6.42771 12.0143 6.07452 11.915 5.76108 11.8112C5.72249 11.7984 5.68303 11.7849 5.64258 11.771C5.35481 11.6726 5.01716 11.5572 4.58929 11.5572C2.29644 11.5572 0.5 13.5598 0.5 15.9143V16.6286C0.5 17.6453 1.25645 18.5001 2.28571 18.5001H11.7143C12.7102 18.5001 13.5 17.6523 13.5 16.6286V15.9143C13.5 13.5548 11.6721 11.5572 9.38393 11.5572ZM7 10.5C9.41994 10.5 11.3571 8.41901 11.3571 5.88571C11.3571 3.38332 9.42212 1.27143 7 1.27143C4.54891 1.27143 2.64286 3.38565 2.64286 5.88571C2.64286 8.41668 4.55109 10.5 7 10.5Z"
                fill="white"
                stroke="#828282"
            />
        </SvgIcon>
    );
};
export default UserIcon;
