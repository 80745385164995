/* eslint default-param-last: 0 */

import reduxReducerHelper from '../../utils/reduxReducerHelperUtils';
import { ORGANIZATION_USERS_DATA_ACTIONS } from '../../hooks/useOrganizationUsersHook';

const INIT_STATE = {
    [ORGANIZATION_USERS_DATA_ACTIONS.UPDATE_ORGANIZATION_USERS_DATA]: [],
    [ORGANIZATION_USERS_DATA_ACTIONS.UPDATE_RECENTLY_UPDATED_ORGANIZATION_USER_ID]: '',
    [ORGANIZATION_USERS_DATA_ACTIONS.UPDATE_SELECTED_ORGANIZATION_USER_FOR_MODAL_ACTION]: {},
};

export default reduxReducerHelper(ORGANIZATION_USERS_DATA_ACTIONS, INIT_STATE);
