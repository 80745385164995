// import * as constants from 'config/constants';

export const contentStyle = {
    flex: 1,
    flexGrow: 1,
    width: '100%',
    background: '#fff',
    padding: '0px 20px 12px 20px',
    // overflowY: 'auto',
};

export default {
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: 'calc(100vh - 60px)',
        backgroundColor: '#fff',
        marginTop: '60px',
    },
    content: contentStyle,
};
