/**
 * Format a given number into unit based on number value
 * @param {{number: number, unit: boolean}} number
 * @returns {string}
 */
export const formatNumber = ({ number, unit: showUnit, precision = 2 }) => {
    let result,
        numberBase = null,
        unit = '';

    if (number >= 1000000000) {
        numberBase = 1000000000;
        unit = 'b';
    } else if (number >= 1000000) {
        numberBase = 1000000;
        unit = 'm';
    } else if (number >= 1000) {
        numberBase = 1000;
        unit = 'k';
    }

    if (!numberBase) return `${number}`.indexOf('.') > 0 ? number.toFixed(precision) : number;

    result = number / numberBase;

    if (['m', 'b'].includes(unit) || (unit === 'k' && `${number}`.length > 4)) result = result.toFixed(precision);

    return result.toLocaleString() + (showUnit ? unit : '');
};
