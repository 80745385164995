import { useSelector } from 'react-redux';
import store from '../redux/store';

export const MODAL_ACTIONS = {
    UPDATE_GENERATE_VISUAL_MODAL: 'generateVisualModal',
    UPDATE_ORGANIZATION_USER_MODAL: 'updateOrganizationUserDialogActionModal',
    UPDATE_INVITE_ORGANIZATION_USER_MODAL: 'updateInviteOrganizationUserDialogActionModal',
    UPDATE_GENERATE_VISUAL_MODAL_COUNTER: 'generateVisualModalCounter',
    UPDATE_CONFIRM_USER_DETAIL_CHANGES_MODAL: 'confirmUserDetailChangesModal',
};

/**
 * Get the modal state
 * @param {string} modalType Modal type to bind to.
 * @param {object} state Store States - useSelector(state => state)
 * @returns {mixed} Current modal state value.
 */
export const getModalState = (state, modalType) => state?.modals?.[modalType];

/**
 * Get the modal state and subscribe to it using the useSelector() hook.
 * @param {string} modalType Modal type to bind to.
 * @returns {mixed} Current modal state value.
 */
export const useGetModalState = (modalType) => {
    return useSelector((state) => getModalState(state, modalType));
};

/**
 * Toggle the modal visibility
 * @param {string} type Modal type to bind to.
 * @param {boolean} payload New modal state value.
 * @returns {Function}
 */
export const setModalState = (type) => (payload) => {
    store.dispatch({ type, payload });
};

/**
 * Allows you to access and set the create integration modal state.
 * Behaves like the React useState() hook.
 * @param {string} modalType Modal type to bind to.
 * @see {MODAL_ACTIONS} for supported modal type actions
 * @returns {array}
 * - isModalOpened: (boolean) Specify whether the modal is opened or closed.
 * - setModalState: (callable) A function to be called to set the modal state.
 */
const useModalState = (modalType) => {
    const isModalOpened = useSelector((state) => getModalState(state, modalType));
    const toggleModalState = setModalState(modalType);

    return [isModalOpened, toggleModalState];
};

export default useModalState;
