import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const BootstrapDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': {
        maxWidth: '700px',
        width: '100%',
        borderRadius: '5px',
    },
    '& .MuiDialogContent-root': {
        paddingTop: '20px',
        paddingBottom: '0px',
    },
    '& .MuiDialogActions-root': {
        padding: 0,
    },
}));

export const BootstrapModalTitle = (props) => {
    const { children, onClose, sx = {}, iconButton = {}, ...other } = props,
        style = {
            display: 'flex',
            alignItems: 'center',
            height: '40px',
            m: 0,
            padding: '10px 5px 10px 20px',
            color: '#fff',
            backgroundColor: '#4F4F4F',
            ...sx,
        },
        titleWrapperStyle = {
            flexGrow: 1,
            flex: 1,
            marginRight: '10px',
            fontSize: '14px',
            lineHeight: '16px',
        },
        iconButtonProps = iconButton.props || {},
        iconButtonStyle = {
            color: '#fff',
            backgroundColor: '#4F4F4F',
            ...(iconButton.style || {}),
        },
        iconStyle = { fontSize: '19px' };

    return (
        <DialogTitle style={style} {...other} component="div">
            <div style={titleWrapperStyle}>{children}</div>

            <IconButton aria-label="close" onClick={onClose} sx={iconButtonStyle} {...iconButtonProps}>
                <CloseIcon style={iconStyle} />
            </IconButton>
        </DialogTitle>
    );
};

BootstrapModalTitle.propTypes = {
    sx: PropTypes.object,
    iconButton: PropTypes.object,
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export const BootstrapModalContent = ({ children, ...otherProps }) => {
    const dialogContentStyle = { paddingBottom: '30px' };
    return (
        <DialogContent
            dividers
            style={dialogContentStyle}
            className="elementScrollBar mappingInfoModalScrollBar  elementScrollBarBorderRadius"
            {...otherProps}
        >
            <DialogContentText tabIndex={-1} component="div">
                {children}
            </DialogContentText>
        </DialogContent>
    );
};

BootstrapModalContent.propTypes = {
    children: PropTypes.node,
};

export const BootstrapModalActions = ({ children, ...otherProps }) => {
    const dialogActionsStyle = { marginTop: '8px', marginBottom: '8px' };
    return (
        <DialogActions style={dialogActionsStyle} {...otherProps}>
            {children}
        </DialogActions>
    );
};

BootstrapModalActions.propTypes = {
    children: PropTypes.node,
};

/**
 * Bootstrap Dialog
 */
const CustomBootstrapDialog = ({ open, onClose, children, ...otherProps }) => (
    <BootstrapDialog keepMounted onClose={onClose} open={open} {...otherProps}>
        {children}
    </BootstrapDialog>
);

CustomBootstrapDialog.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
};

export default CustomBootstrapDialog;
