import { useState, useEffect } from 'react';

import FileTree from './FileTree';
import FilesContent from './FilesContent';
import NewFolderModal from 'components/Modals/StorageModals/NewFolderModal';
import DeleteFileModal from 'components/Modals/StorageModals/DeleteFileModal';
import RenameFileModal from 'components/Modals/StorageModals/RenameFileModal';
import MoveFileModal from 'components/Modals/StorageModals/MoveFileModal';
import CopyFileModal from 'components/Modals/StorageModals/CopyFileModal';
import FileUploadModal from 'components/Modals/StorageModals/FileUploadModal';
import AppLoader from 'components/Loaders/AppLoader';

import styles from './styles';

import { initIntelyStorageStates, resetToDefaultState } from '../../hooks/useMyFilesHook';

const FileStorage = () => {
    const [isLoading, setIsLoading] = useState(true);

    const initStates = async () => {
        resetToDefaultState();
        await initIntelyStorageStates();
        setIsLoading(false);
    };

    useEffect(() => {
        initStates();
    }, []);

    useEffect(() => {
        const appRootWrapper = document.querySelector('.appRoutesContainer');
        appRootWrapper.classList.add('noScrollContainer');

        return () => {
            appRootWrapper.classList.remove('noScrollContainer');
        };
    }, []);

    return isLoading ? (
        <div style={styles.loadingContainer}>
            <AppLoader style={styles.loader} />
        </div>
    ) : (
        <div style={styles.root}>
            <div style={styles.fileTreeContainer}>
                <FileTree />
            </div>
            <div style={styles.fileContentContainer}>
                <FilesContent />
            </div>

            <NewFolderModal />
            <DeleteFileModal />
            <RenameFileModal />
            <MoveFileModal />
            <CopyFileModal />
            <FileUploadModal />
        </div>
    );
};

export default FileStorage;
