import PropTypes from 'prop-types';
import { useLocation, matchPath, useOutletContext } from 'react-router-dom';

import Profile from 'components/Profile';
import Logging from 'components/Logging';
import Dashboard from 'components/Dashboard';
import { contentStyle } from 'components/Containers/DashboardMainSidebarContainer/styles';
import OrganizationEdit from 'components/Settings/Organization/OrganizationEdit';
import OrganizationVpnManagement from 'components/Settings/Organization/OrganizationVpnManagement';
import FileStorage from 'components/FileStorage';
import OrganizationUsers from 'components/Settings/Organization/OrganizationUsers';
import useSelectedOrganization from 'hooks/useSelectedOrganizationHook';
import DashboardMainSidebarContainer from 'components/Containers/DashboardMainSidebarContainer';
import { MODAL_ACTIONS, setModalState } from 'hooks/useModalStateHook';

const RenderDashboardPage = ({ isUserLoggedIn }) => {
    const location = useLocation(),
        [currentSelectedOrganization] = useSelectedOrganization(),
        organizationName = currentSelectedOrganization?.organizationName || 'Organization',
        locationPathName = location ? location?.pathname : '',
        containerStyles = {
            ...contentStyle,
            padding: '12px 20px 0px 20px',
        },
        pageRoutes = [],
        pageTitleBar = { title: '', actionButtons: [] },
        organizationRoute = {
            title: 'Organization',
            route: '/organization',
        },
        { applicationAccess = {} } = useOutletContext(),
        { hasConnectAccess } = applicationAccess;

    let pathPatterns = {
        dashboard: {
            end: false,
            path: '/dashboard',
        },
        userProfile: {
            end: false,
            path: '/my-profile',
        },
        organizationProfile: {
            end: true,
            path: '/organization',
        },
        organizationManageUsers: {
            end: true,
            path: '/organization/organization-users',
        },
    };

    if (hasConnectAccess) {
        pathPatterns = {
            ...pathPatterns,
            organizationLogs: {
                end: true,
                path: '/organization/logging',
            },
            organizationVpnManagement: {
                end: true,
                path: '/organization/vpn-management-access',
            },
            organizationFileStorage: {
                end: true,
                path: '/organization/file-storage',
            },
        };
    }

    let PageComponent = null,
        activePageTitle = '';

    if (matchPath(pathPatterns.dashboard, locationPathName)) {
        PageComponent = Dashboard;
        containerStyles.content = {
            ...contentStyle,
            padding: '0px',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
        };
        containerStyles.navigationIndicator = {
            paddingLeft: '20px',
        };
    } else if (matchPath(pathPatterns.userProfile, locationPathName)) {
        PageComponent = Profile;
        activePageTitle = 'My Profile';
    } else if (matchPath(pathPatterns.organizationManageUsers, locationPathName)) {
        PageComponent = OrganizationUsers;
        activePageTitle = 'Organization Users';
        pageTitleBar.title = `${organizationName} Users`;
        pageRoutes.push(organizationRoute);
        pageTitleBar.actionButtons = [
            {
                label: 'Invite User',
                onClick: () => {
                    setModalState(MODAL_ACTIONS.UPDATE_INVITE_ORGANIZATION_USER_MODAL)(true);
                },
            },
        ];
    } else if (matchPath(pathPatterns.organizationProfile, locationPathName)) {
        PageComponent = OrganizationEdit;
        activePageTitle = 'Organization';
    }

    if (hasConnectAccess) {
        if (matchPath(pathPatterns.organizationVpnManagement, locationPathName)) {
            PageComponent = OrganizationVpnManagement;
            activePageTitle = 'VPN Management Access';
            pageTitleBar.title = `${organizationName} VPN Management Access`;
            pageRoutes.push(organizationRoute);
        } else if (matchPath(pathPatterns.organizationFileStorage, locationPathName)) {
            PageComponent = FileStorage;
            activePageTitle = 'My Files';
            pageTitleBar.title = 'My Files';
            pageTitleBar.actionButtons = [];
            pageRoutes.push(organizationRoute);
            containerStyles.pageContent = {
                height: 'calc(100% - 64px)',
            };
        } else if (matchPath(pathPatterns.organizationLogs, locationPathName)) {
            PageComponent = Logging;
            activePageTitle = 'Logging';
            pageTitleBar.title = `${organizationName} Logging`;
            pageRoutes.push(organizationRoute);
        }
    }

    if (!PageComponent || !currentSelectedOrganization?.organizationId) {
        return null;
    }

    const navigationIndicatorProps = {
        activePageTitle,
        routes: pageRoutes,
    };

    const renderPage = <PageComponent isUserLoggedIn={isUserLoggedIn} />;

    return (
        <DashboardMainSidebarContainer
            content={renderPage}
            pageTitleBarProps={pageTitleBar}
            navigationIndicatorProps={navigationIndicatorProps}
            styles={containerStyles}
        />
    );
};

RenderDashboardPage.propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
};

export default RenderDashboardPage;
