import { getStoreState, useStoreState, useStoreValue } from './useStoreStateHook';

/**
 * Get a given property from the authenticated user data object.
 *
 * @param {string} prop The name of the property in the user data
 * object to retrieve.
 * @param {*} fallbackValue Value to fallback to if the property
 * is not found. Default value is undefined.
 *
 * @returns {*} The value of the property if found.
 * Otherwise {fallbackValue} is returned.
 */
export function useUserProp(prop, fallbackValue = undefined) {
    return useStoreValue(`user.${prop}`, 'auth')(fallbackValue);
}

/**
 * Get the authenticated user ID
 * @returns {string|undefined} The authenticated user ID on success.
 * Otherwise undefined.
 */
export function useUserId() {
    return useUserProp('id');
}

/**
 * Get the authenticated user email address
 * @returns {string|undefined} The authenticated user email address on success.
 * Otherwise undefined..
 */
export function useUserEmail() {
    return useUserProp('email');
}

/**
 * Check whether the authenticated user email is the admin email address.
 * @returns {boolean} True if current logged in user email is the admin
 * email address. Otherwise false.
 */
export function useIsUserAdmin() {
    return useUserEmail() === process.env.REACT_APP_ADMIN_USERNAME;
}

/**
 * Get the authenticated user data
 * @returns {object|undefined} The authenticated user data on success.
 * Otherwise undefined.
 */
export const getAuthUserData = () => getStoreState('user', 'auth')({});

/**
 *
 * @returns {String}
 */
export const getCurrentUserId = () => getAuthUserData().userId;

/**
 * Get the authenticated user property value.
 *
 * @param {string} prop The name of the property in the user data
 * object to retrieve.
 *  @param {*} fallbackValue Value to fallback to if the property
 * is not found. Default value is undefined.
 *
 * @returns {*} The authenticated user data on success.
 * Otherwise undefined.
 */
export const getAuthUserProp = (prop, fallbackValue = undefined) =>
    getStoreState(`user.${prop}`, 'auth')(fallbackValue);

/**
 * Get the authenticated user property value and subscribe to changes.
 *
 * @param {string} prop The name of the property in the user data
 * object to retrieve. *
 *  @param {*} fallbackValue Value to fallback to if the property
 * is not found. Default value is undefined.
 *
 * @returns {<*, Function>} An array having the user state as first
 * parameter, and a function to update the state as second parameter.
 */
export const useUserState = (prop, fallbackValue = undefined) => useStoreState(`user.${prop}`, 'auth')(fallbackValue);

/**
 * Get the authenticated user data and subscribe to changes.
 * @returns {object|undefined} The authenticated user data on success.
 * Otherwise undefined.
 */
const useUser = () => useStoreValue('user', 'auth')({});
export default useUser;
