import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const UserNameInfo = ({ userData }) => {
    const boxStyle = {
            display: 'inline',
            flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: 3,
        },
        email = userData?.email || '',
        fullName = `${userData?.firstName || ''} ${userData?.lastName || ''}`,
        sliceFullName = fullName.length > 20 ? `${fullName.slice(0, 20)}...` : fullName; // TODO: use tooltip to show full name

    return (
        <Box sx={boxStyle}>
            <Typography component="h1" variant="h4">
                {sliceFullName}
            </Typography>
            <Typography>{email}</Typography>
        </Box>
    );
};

UserNameInfo.propTypes = {
    userData: PropTypes.object.isRequired,
};

export default UserNameInfo;
