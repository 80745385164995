import TextField from '../TextField';

const CompanyPositionField = (props) => {
    const validate = {
        emptyLabel: 'Please enter organization name.',
    };
    return (
        <TextField
            placeholder="Your Organization Name"
            autoComplete="organizationName"
            label="Organization Name"
            name="organizationName"
            validate={validate}
            {...props}
        />
    );
};

export default CompanyPositionField;
