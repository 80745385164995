import { SvgIcon } from '@mui/material';

const Icon = (props) => {
    return (
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 14.2365H14C13.4375 14.2365 13 14.7053 13 15.2365V19.2365C13 19.799 13.4375 20.2365 14 20.2365H16C16.5312 20.2365 17 19.799 17 19.2365V15.2365C17 14.7053 16.5312 14.2365 16 14.2365ZM15.5 18.7365H14.5V15.7365H15.5V18.7365ZM21 10.2365H19C18.4375 10.2365 18 10.7053 18 11.2365V19.2365C18 19.799 18.4375 20.2365 19 20.2365H21C21.5312 20.2365 22 19.799 22 19.2365V11.2365C22 10.7053 21.5312 10.2365 21 10.2365ZM20.5 18.7365H19.5V11.7365H20.5V18.7365ZM11 10.2365H9C8.4375 10.2365 8 10.7053 8 11.2365V19.2365C8 19.799 8.4375 20.2365 9 20.2365H11C11.5312 20.2365 12 19.799 12 19.2365V11.2365C12 10.7053 11.5312 10.2365 11 10.2365ZM10.5 18.7365H9.5V11.7365H10.5V18.7365ZM6 15.2365H4C3.4375 15.2365 3 15.7053 3 16.2365V19.2365C3 19.799 3.4375 20.2365 4 20.2365H6C6.53125 20.2365 7 19.799 7 19.2365V16.2365C7 15.7053 6.53125 15.2365 6 15.2365ZM5.5 18.7365H4.5V16.7365H5.5V18.7365ZM5 12.2365C5.8125 12.2365 6.5 11.5803 6.5 10.7365C6.5 10.6115 6.46875 10.4865 6.4375 10.3615L9.59375 7.20526C9.71875 7.23651 9.84375 7.23651 10 7.23651C10.1875 7.23651 10.3438 7.20526 10.5312 7.14276L13.5 9.51776C13.5 9.61151 13.5 9.67401 13.5 9.73651C13.5 10.5803 14.1562 11.2365 15 11.2365C15.8125 11.2365 16.5 10.5803 16.5 9.73651C16.5 9.67401 16.4688 9.61151 16.4688 9.51776L19.4375 7.14276C19.625 7.20526 19.7812 7.23651 20 7.23651C20.8125 7.23651 21.5 6.58026 21.5 5.73651C21.5 4.92401 20.8125 4.23651 20 4.23651C19.1562 4.23651 18.5 4.92401 18.5 5.73651C18.5 5.83026 18.5 5.89276 18.5 5.98651L15.5312 8.36151C15.3438 8.29901 15.1875 8.23651 14.9688 8.23651C14.7812 8.23651 14.625 8.29901 14.4375 8.36151L11.4688 5.98651C11.4688 5.89276 11.5 5.83026 11.5 5.73651C11.5 4.92401 10.8125 4.23651 10 4.23651C9.15625 4.23651 8.5 4.92401 8.5 5.73651C8.5 5.89276 8.5 6.01776 8.53125 6.14276L5.375 9.29901C5.25 9.26776 5.125 9.23651 5 9.23651C4.15625 9.23651 3.5 9.92401 3.5 10.7365C3.5 11.5803 4.15625 12.2365 5 12.2365Z"
                fill="white"
            />
        </SvgIcon>
    );
};
export default Icon;
