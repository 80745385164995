import PropagateLoader from 'react-spinners/PropagateLoader';
import PropTypes from 'prop-types';

const AppLoader = ({ style = {} }) => {
    const styles = {
        display: 'flex',
        top: 0,
        position: 'absolute',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255,255,255,1)',
        zIndex: 1000,
        height: '100vh',
        ...style,
    };

    return (
        <div style={styles}>
            <PropagateLoader
                //    loader="SyncLoader"
                color="#162f6f"
                loading={true}
            />
        </div>
    );
};

AppLoader.propTypes = {
    style: PropTypes.object,
};

export default AppLoader;
