import PropTypes from 'prop-types';
import ClipLoader from 'react-spinners/ClipLoader';
import { useTheme } from '@mui/material/styles';

const CircularLoader = (props) => {
    const theme = useTheme(),
        { color = theme.palette.primary.main, loading = true, style = {}, size = 50, height, width } = props;

    return <ClipLoader color={color} loading={loading} css={style} size={size} width={width} height={height} />;
};

CircularLoader.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    loading: PropTypes.bool,
    style: PropTypes.object,
};

export default CircularLoader;
