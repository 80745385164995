import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import PrimaryButton from '../../../Buttons/PrimaryButton';
import { memo, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Backdrop, CircularProgress, FormHelperText } from '@mui/material';
import Grid from '@mui/material/Grid';
import useSelectedOrganization from '../../../../hooks/useSelectedOrganizationHook';
import {
    showSnackBarErrorNotification,
    showSnackBarSuccessNotification,
} from '../../../../utils/snackBarNotificationUtils';
import { getVpnManagementStatus, requestVpnManagement } from '../../../../utils/organizationUtils';

const OrganizationVpnManagement = () => {
    const [isCheckingStatus, setCheckingStatus] = useState(true),
        [isLoading, setLoading] = useState(false),
        [connectionQuotaRequest, setConnectionQuotaRequest] = useState(1),
        [currentOrganization] = useSelectedOrganization(),
        [vpnManagementStatus, setVpnManagementStatus] = useState({}),
        [formHasError, setFormHasError] = useState(false);
    let currentStatus = 'loading';

    useEffect(async () => {
        if (isCheckingStatus) {
            setCheckingStatus(false);
            try {
                await getVpnManagementStatus(currentOrganization.organizationId, {
                    successCallback: (res) => {
                        setVpnManagementStatus(res.data);
                    },
                    errorCallback: () => {
                        showSnackBarErrorNotification(
                            'There was an error trying to load the current status of your organizations VPN management access.',
                        );
                    },
                });
            } catch (e) {
                showSnackBarSuccessNotification('Failed to retrieve VPN management status');
            }
            setLoading(false);
        }
    });

    const handleSubmittingAccessRequest = async () => {
        if (handleErrorChecks() === false) {
            setLoading(true);
            await requestVpnManagement(currentOrganization.organizationId, connectionQuotaRequest, {
                successCallback: () => {
                    showSnackBarSuccessNotification('Your request has been successfully logged.');
                    setCheckingStatus(true);
                    setLoading(false);
                },
                errorCallback: () => {
                    setLoading(false);
                },
            });
        }
    };

    if (Object.keys(vpnManagementStatus) === 0) {
        currentStatus = 'loading';
    } else if (vpnManagementStatus.isDisabled) {
        currentStatus = 'disabled';
    } else if (
        vpnManagementStatus.isRequested &&
        vpnManagementStatus.isEnabled === false &&
        vpnManagementStatus.isDisabled === false
    ) {
        currentStatus = 'requested';
    } else if (
        vpnManagementStatus.isRequested &&
        vpnManagementStatus.isEnabled &&
        vpnManagementStatus.isDisabled === false
    ) {
        currentStatus = 'enabled';
    } else {
        currentStatus = 'not-set';
    }

    const handleFormInput = (e) => {
        setFormHasError(false);
        switch (e.target.name) {
            case 'connectionQuotaRequested':
                if (/^[0-9]*$/.test(e.target.value)) {
                    setConnectionQuotaRequest(e.target.value);
                }
                break;
        }
    };

    const handleErrorChecks = () => {
        setFormHasError(false);
        if (/^[0-9]*$/.test(connectionQuotaRequest) === false) {
            setFormHasError(true);
            return true;
        }

        return false;
    };

    return (
        <>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box
                sx={{
                    width: '50%',
                    marginLeft: '20%',
                    marginRight: '20%',
                    padding: '25px',
                    border: '1px solid lightgray',
                }}
            >
                <Typography sx={{ fontSize: '1.1em' }}>VPN Management Access</Typography>
                {currentStatus === 'not-set' && (
                    <>
                        <Typography>
                            If you would like to be able to manage your own VPN connections through Connect, please
                            enter the amount of connections your application requires. You will receive a confirmation
                            email if your request is approved.
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <FormHelperText>Number of Connections:</FormHelperText>
                                <TextField
                                    required
                                    variant="outlined"
                                    name="connectionQuotaRequested"
                                    autoComplete="off"
                                    placeholder="Number of Connections"
                                    error={formHasError}
                                    value={connectionQuotaRequest}
                                    onChange={handleFormInput}
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ textAlign: 'right', padding: '10px 0 0 0' }}>
                            <PrimaryButton disabled={isLoading} onClick={handleSubmittingAccessRequest}>
                                Submit Request for VPN Management
                            </PrimaryButton>
                        </Box>
                    </>
                )}
                {currentStatus === 'requested' && (
                    <Typography>
                        We are currently processing a request for VPN management access. If the request is approved, the
                        administrator who requested it will be notified.
                    </Typography>
                )}
                {currentStatus === 'enabled' && (
                    <Typography>
                        This feature is currently enabled for your organization. The VPNs can be managed in the Connect
                        application.
                    </Typography>
                )}
                {currentStatus === 'disabled' && (
                    <Typography>This feature is currently disabled for your organization.</Typography>
                )}
            </Box>
        </>
    );
};

export default memo(OrganizationVpnManagement);
