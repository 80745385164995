import Typography from '@mui/material/Typography';

import SearchLogInput from './SearchLogInput';
import FilterLogButton from './FilterLogButton';
import LogFilterContent from './LogFilterContent';
import { useSelectedLogNameItem } from 'hooks/useOrganizationLoggingHook';

const LoggingContentHeader = () => {
    const selectedLogNameItem = useSelectedLogNameItem();

    return (
        <>
            <div className="logContentHeader">
                <Typography className="logContentHeaderTitle" component="h4">
                    {selectedLogNameItem.title} Logs
                </Typography>
                <div className="logContentHeaderSearchBar">
                    <SearchLogInput />
                    <FilterLogButton />
                </div>
            </div>
            <LogFilterContent />
        </>
    );
};

export default LoggingContentHeader;
