export default {
    root: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '40px',
        marginTop: '2px',
        borderRadius: '8px',
        transition: 'background-color 300ms',

        '&:hover': {
            backgroundColor: 'rgba(130, 130, 130, 0.85)',
        },
    },
    active: {
        backgroundColor: 'rgba(130, 130, 130, 1)',
    },
    icon: {
        display: 'flex',
        width: '30px',
        height: '30px',
        background: '#4F4F4F',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '6px',
        borderRadius: '100%',

        '& svg path': {
            fill: '#fff',
        },
    },
    text: {
        color: '#FFFFFF',
        fontSize: '13px',
        // lineHeight: '15px',
        fontFamily: 'Roboto !important',
        fontStyle: 'normal',
        fontWeight: '400',
        textAlign: 'left',
    },
};
