import { memo, useState, useRef, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

import MfaField from '../../InputFields/MfaField';
import LastNameField from '../../InputFields/LastNameField';
import LoadingButton from '../../Buttons/LoadingButton';
import FirstNameField from '../../InputFields/FirstNameField';
import UserAccountInfo from '../UserAccountInfo';
import PhoneNumberField from '../../InputFields/PhoneNumberField';
import EmailAddressField from '../../InputFields/EmailAddressField';
import CompanyPositionField from '../../InputFields/CompanyPositionField';
import CompanySelectField from '../../InputFields/CompanySelectField';

import ConfirmUserDetailChangesModal from 'components/Modals/ConfirmUserDetailChangesModal';

import { refreshUserToken } from '../../../utils/loginUtils';
import { getCurrentUserId } from 'hooks/useUserHook';
import { updateUserDetails } from '../../../utils/userProfileUpdateUtils';

import {
    resetDataRefObj,
    isInputFieldsReady,
    getInputFieldValues,
    setInputFieldValueCallback,
    clearInputFieldValueCallback,
} from '../../../utils/inputFieldUtils';

import {
    showSnackBarErrorNotification,
    showSnackBarSuccessNotification,
} from '../../../utils/snackBarNotificationUtils';

import { closeConfirmUserDetailChangesModal, openConfirmUserDetailChangesModal } from 'hooks/useModalHook';

const EditUserDetails = ({ userData }) => {
    const userDetailsRefObj = useRef({
            mfa: null,
            lastName: null,
            firstName: null,
            phoneNumber: null,
            companyName: null,
            jobTitle: null,
            defaultOrganizationId: userData?.defaultOrganizationId,
        }).current,
        mfaInputRef = useRef(),
        defaultOrganizationIdInputRef = useRef(),
        [isEditingProfile, setIsEditingProfile] = useState(true),
        [isUpdatingProfile, setIsUpdatingProfile] = useState(false);

    /**
     * Normalize the user profile data for update
     */
    const normalizeUserProfileDataForUpdate = () => {
        userDetailsRefObj.mfa = 1 === parseInt(mfaInputRef.current.value) ? true : false;
        userDetailsRefObj.defaultOrganizationId = defaultOrganizationIdInputRef.current.value;
        userDetailsRefObj.companyName = userData.organizations.find(
            (organization) => organization.organizationId === defaultOrganizationIdInputRef.current.value,
        ).organizationName;
        userDetailsRefObj.id = userData._id;
    };

    /**
     * Handle user profile update
     */
    const handleUpdateProfile = async () => {
        if (!isEditingProfile) {
            setIsEditingProfile(true);
            return;
        }

        setIsUpdatingProfile(() => true);
        normalizeUserProfileDataForUpdate();

        // // Lookup errors
        const isInputFieldsValid = await isInputFieldsReady(userDetailsRefObj)();

        if (!isInputFieldsValid) {
            setIsUpdatingProfile(false);
            return;
        }

        openConfirmUserDetailChangesModal({
            userDetails: {
                ...userDetailsRefObj,
                organizationName:
                    inputDataList.find((item) => item.value === userDetailsRefObj.defaultOrganizationId)?.label || '',
                email: userData.email,
            },
            onConfirm: async () => {
                updateUserDetails(userDetailsRefObj)
                    .then(async () => {
                        setIsEditingProfile(true);
                        resetDataRefObj(userDetailsRefObj);
                        showSnackBarSuccessNotification('Profile updated successfully.');

                        await refreshUserToken(getCurrentUserId(), { success: false, error: false });
                    })
                    .catch(() => {
                        showSnackBarErrorNotification('Unable to update profile.');
                    })
                    .finally(() => {
                        setIsUpdatingProfile(false);
                        closeConfirmUserDetailChangesModal();
                    });
            },
            onClose: () => {
                setIsUpdatingProfile(false);
                closeConfirmUserDetailChangesModal();
            },
        });
    };

    /**
     * Input props
     */
    const inputFieldProps = {
        disabled: !isEditingProfile || isUpdatingProfile,
        isSubmittingForm: isUpdatingProfile,
        actionCallback: getInputFieldValues(userDetailsRefObj, isEditingProfile),
        validationErrorCallback: clearInputFieldValueCallback(userDetailsRefObj),
        validationSuccessCallback: setInputFieldValueCallback(userDetailsRefObj),
    };

    const inputDataList = useMemo(() => {
        const list = [];
        if (userData?.organizations) {
            userData.organizations.forEach((organization) => {
                list.push({ value: organization.organizationId, label: organization.organizationName });
            });
        }
        return list;
    }, [userData?.organizations]);

    const mfaFieldGridProps = {
        sx: {
            marginTop: '-5px',
            marginBottom: '0px',
        },
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={8}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <UserAccountInfo userData={userData} uploadProfileImageButtonProps={inputFieldProps} />
                </Grid>
                <Grid item xs={4} textAlign="end" justifyContent="center" alignItems="end">
                    <LoadingButton
                        label={'Save'}
                        loading={isUpdatingProfile}
                        buttonProps={{
                            sx: {
                                mt: 5,
                                height: '40px',
                                minWidth: 0,
                                '&.MuiLoadingButton-root:hover': {
                                    backgroundColor: '#333F99',
                                    color: '#FFFFFF',
                                    boxShadow: 'none',
                                },
                            },
                        }}
                        onClick={handleUpdateProfile}
                    />
                </Grid>

                <FirstNameField {...inputFieldProps} defaultValue={userData.firstName} />

                <LastNameField {...inputFieldProps} defaultValue={userData.lastName} />

                <EmailAddressField disabled={true} defaultValue={userData.email} />

                <PhoneNumberField {...inputFieldProps} defaultValue={userData.phone} />

                {inputDataList && inputDataList.length > 0 && (
                    <CompanySelectField
                        {...inputFieldProps}
                        inputDataList={inputDataList}
                        value={userDetailsRefObj.defaultOrganizationId || userData?.defaultOrganizationId}
                        inputRef={defaultOrganizationIdInputRef}
                        defaultValue={
                            userDetailsRefObj.defaultOrganizationId
                                ? userDetailsRefObj.defaultOrganizationId
                                : userData.defaultOrganizationId || ''
                        }
                        handleChange={(e) => {
                            defaultOrganizationIdInputRef.current.value = e.target.value;
                        }}
                    />
                )}

                <CompanyPositionField {...inputFieldProps} defaultValue={userData.jobTitle} />

                <MfaField
                    inputRef={mfaInputRef}
                    defaultValue={userData.mfa ? 1 : 0}
                    disabled={!isEditingProfile}
                    gridProps={mfaFieldGridProps}
                />
            </Grid>
            <ConfirmUserDetailChangesModal />
        </>
    );
};

EditUserDetails.propTypes = {
    userData: PropTypes.object.isRequired,
};

export default memo(EditUserDetails);
