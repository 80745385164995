import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

const Icon = (props) => {
    const fill = props.fill || '#828282';

    return (
        <SvgIcon width="12" height="12" viewBox="0 0 12 12" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.5511 9.45636L9.57607 7.4813C9.93518 6.76309 10.1746 5.92519 10.1746 5.08728C10.1746 2.27431 7.90026 0 5.08729 0C2.27432 0 0 2.27431 0 5.08728C0 7.90025 2.27432 10.1746 5.08729 10.1746C5.9252 10.1746 6.7631 9.93516 7.48131 9.57606L9.45637 11.5511C10.0549 12.1496 10.9526 12.1496 11.5511 11.5511C12.0898 10.9526 12.0898 10.0549 11.5511 9.45636ZM5.08729 8.97756C2.93267 8.97756 1.19701 7.24189 1.19701 5.08728C1.19701 2.93267 2.93267 1.19701 5.08729 1.19701C7.24191 1.19701 8.97757 2.93267 8.97757 5.08728C8.97757 7.24189 7.24191 8.97756 5.08729 8.97756Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

Icon.propTypes = {
    fill: PropTypes.string,
};
export default Icon;
