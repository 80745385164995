import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

export const DialogContainerProps = {
    outline: 0,
    maxWidth: 'sm',
    fullWidth: true,
    keepMounted: true,
    disableScrollLock: false,
};

const dialogbox = {
    '& .MuiDialog-paper': {
        padding: '0px 0px',
        borderRadius: 0,
        boxShadow: 1,
        '&.MuiPaper-rounded': {
            borderRadius: 0.5,
        },
        '&.MuiDialog-paperFullScreen': {
            borderRadius: 0,
        },
        '&.MuiDialog-paper .MuiDialogActions-root': {
            padding: 3,
        },
        '@media (max-width: 600px)': {
            margin: 0,
        },
        '@media (max-width: 663.95px)': {
            '&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody': {
                maxWidth: '100%',
            },
        },
        '&.MuiDialog-paper .MuiDialogTitle-root': {
            padding: '12px 12px',
            backgroundColor: '#4F4F4F',
            color: '#FFFFFF',
            fontSize: '13px',
            fontWeight: '400',
        },
    },
};

const StyledDialogContainer = styled('div')`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
`;

const DialogContainer = (props) => {
    const dialogProps = props?.dialog || {};

    return (
        <StyledDialogContainer>
            <Dialog {...DialogContainerProps} sx={dialogbox} {...dialogProps}>
                {props.children}
            </Dialog>
        </StyledDialogContainer>
    );
};

DialogContainer.propTypes = {
    dialog: PropTypes.object.isRequired,
    children: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default DialogContainer;
