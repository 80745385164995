const styles = {
    root: {
        marginBottom: 16,
        width: '100%',
        overflowX: 'hidden',
    },
    tableCellStyle: {
        head: {
            backgroundColor: '#4f4f4f',
            color: '#FFFFFF',
            '&&': {
                borderRadius: 0,
                boxShadow: 'none',
            },
        },
        body: {
            fontSize: 14,
        },
    },
    scopeChips: {
        marginRight: 1,
        marginBottom: 1,
    },
    scopeChipContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    tableDataRow: {
        border: '1px solid #E0E0E0',
    },
};

export default styles;
