import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Typography,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    TextField,
    InputLabel,
    FormHelperText,
} from '@mui/material';
import Radio from '@mui/material/Radio';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import LoadingButton from '@mui/lab/LoadingButton';

import DialogContainer from '../../Containers/DialogContainer';
import { getCurrentOrganizationId } from '../../../utils/loginUtils';
import { setOrganizationUsersData } from '../../../hooks/useOrganizationUsersHook';
import useModalState, { MODAL_ACTIONS } from '../../../hooks/useModalStateHook';
import { inviteOrganizationUserData, getOrganizationUsersData } from '../../../utils/organizationUtils';
import { showSnackBarNotification, showSnackBarErrorNotification } from '../../../utils/snackBarNotificationUtils';

const InviteOrganizationUserModal = () => {
    const [disabled, setDisabled] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [organizationIsAdmin, setOrganizationIsAdmin] = useState('2');
    const [organizationUserEmail, setOrganizationUserEmail] = useState('');
    const navigate = useNavigate();
    // const [organizationUserFirstName, setOrganizationUserFirstName] = useState('');
    // const [organizationUserLastName, setOrganizationUserLastName] = useState('');
    // const [organizationUserPhone, setOrganizationUserPhone] = useState('');
    // const [organizationUserJobTitle, setOrganizationUserJobTitle] = useState('');
    // const [organizationUserApplications, setOrganizationUserApplications] = useState('');
    // const [organizationUserFormApplications, setOrganizationUserFormApplications] = useState(1);
    // const [organizationUserConnectApplications, setOrganizationUserConnectApplications] = useState(1);

    const [open, setOpen] = useModalState(MODAL_ACTIONS.UPDATE_INVITE_ORGANIZATION_USER_MODAL);

    const handleCloseModal = () => {
        setOrganizationIsAdmin('2');
        setOrganizationUserEmail('');
        setIsSubmitted(false);
        // setOrganizationUserFormApplications('');
        // setOrganizationUserConnectApplications('');
        // setOrganizationUserApplications('');
        // setOrganizationUserFirstName('');
        // setOrganizationUserLastName('');
        // setOrganizationUserJobTitle('');
        // setOrganizationUserPhone('');
        setOpen(false);
    };

    /**
     * Get the organization user's
     * @returns {void}
     */
    const getOrganizationUserData = (organizationId) => {
        if (!organizationId) return; // Bail initial load state
        getOrganizationUsersData(organizationId)
            .then((data) => {
                const organizationUsersData = data?.length ? data : [];
                setOrganizationUsersData(organizationUsersData);
            })
            .catch((err) => {
                navigate('/dashboard');
                showSnackBarErrorNotification(err.response.data.message);
            });
    };

    /**
     * New organization user send invitation
     */
    const inviteOrganizationUser = () => {
        setDisabled(true);
        setIsSubmitted(true);
        const organizationId = getCurrentOrganizationId();
        const data = {};
        data['additionalUserProperties'] = {
            isAdmin: Number(organizationIsAdmin) === 1 ? true : false,
        };
        data['email'] = organizationUserEmail;

        // data['jobTitle'] = organizationUserJobTitle;
        // data['phone'] = organizationUserPhone;
        // data['firstName'] = organizationUserFirstName;
        // data['lastName'] = organizationUserLastName;
        if (organizationUserEmail) {
            inviteOrganizationUserData(organizationId, data)
                .then((updatedOrganization) => {
                    if (updatedOrganization) {
                        setDisabled(false);
                        setOpen(false);
                        setIsSubmitted(false);
                        handleCloseModal();
                        getOrganizationUserData(organizationId);
                        showSnackBarNotification({
                            type: 'success',
                            message: 'An invite has been sent to the requested email.',
                        });
                    }
                })
                .catch((err) => {
                    setDisabled(false);
                    showSnackBarErrorNotification(err.response.data.message);
                });
        } else {
            setDisabled(false);
        }
    };

    const handleChangeIsAdmin = (event) => {
        setOrganizationIsAdmin(event.target.value);
    };
    // const handleChangeFormAccess = (event) => {
    //     setOrganizationUserFormApplications(event.target.value);
    // };
    // const handleChangeConnectAccess = (event) => {
    //     setOrganizationUserConnectApplications(event.target.value);
    // };

    return (
        <DialogContainer
            dialog={{
                open,
            }}
        >
            <DialogTitle>
                Invite User
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 5,
                        color: '#FFFFFF',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} mt={3}>
                        <InputLabel sx={{ fontSize: 16, fontWeight: '500' }}>
                            {' '}
                            Email{' '}
                            <Typography sx={{ color: 'red' }} variant="subtitle1" component="span">
                                {' '}
                                *
                            </Typography>{' '}
                        </InputLabel>
                        <TextField
                            required
                            fullWidth
                            onChange={(e) => setOrganizationUserEmail(e.target.value)}
                            value={organizationUserEmail}
                            variant="outlined"
                            placeholder="Email"
                            inputProps={{ maxLength: 256 }}
                            error={isSubmitted && !organizationUserEmail}
                        />
                        {isSubmitted && !organizationUserEmail && (
                            <FormHelperText error={isSubmitted && !organizationUserEmail}>
                                {'Email is required'}
                            </FormHelperText>
                        )}
                    </Grid>
                    {/* <Grid item xs={12} sm={12}>
                        <Typography sx={{ fontSize: 16, fontWeight: '500' }} id="edit-user-organization-firstname">
                            First Name
                        </Typography>

                        <TextField
                            fullWidth
                            onChange={(e) => setOrganizationUserFirstName(e.target.value)}
                            value={organizationUserFirstName}
                            variant="outlined"
                            placeholder="First Name"
                            inputProps={{ maxLength: 256 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ fontSize: 16, fontWeight: '500' }} id="edit-user-organization-lastname">
                            Last Name
                        </Typography>

                        <TextField
                            fullWidth
                            onChange={(e) => setOrganizationUserLastName(e.target.value)}
                            value={organizationUserLastName}
                            variant="outlined"
                            placeholder="Last Name"
                            inputProps={{ maxLength: 256 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ fontSize: 16, fontWeight: '500' }} id="edit-user-organization-phone">
                            Phone
                        </Typography>

                        <TextField
                            fullWidth
                            onChange={(e) => setOrganizationUserPhone(e.target.value)}
                            value={organizationUserPhone}
                            variant="outlined"
                            placeholder="Phone"
                            inputProps={{ maxLength: 256 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ fontSize: 16, fontWeight: '500' }} id="edit-user-organization-job-title">
                            Job Title
                        </Typography>

                        <TextField
                            fullWidth
                            onChange={(e) => setOrganizationUserJobTitle(e.target.value)}
                            value={organizationUserJobTitle}
                            variant="outlined"
                            placeholder="Job Title"
                            inputProps={{ maxLength: 256 }}
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={12}>
                        <FormControl>
                            <Typography
                                sx={{ fontSize: 16, fontWeight: '500' }}
                                id="edit-user-organization-administrator"
                            >
                                Organization Administrator
                            </Typography>
                            <RadioGroup
                                row
                                value={organizationIsAdmin}
                                aria-labelledby="edit-user-organization-administrator-label"
                                name="edit-user-organization-administrator"
                                onChange={handleChangeIsAdmin}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                <FormControlLabel value="2" control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    {/* <Grid item xs={12} sm={12}>
                        <FormControl>
                            <Typography sx={{ fontSize: 16, fontWeight: '500' }} id="edit-user-intely-forms-access">
                                Intely Forms Access
                            </Typography>
                            <RadioGroup
                                row
                                aria-labelledby="edit-user-intely-forms-access-label"
                                name="edit-user-intely-forms-access"
                                onChange={handleChangeFormAccess}
                                value={organizationUserFormApplications}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Admin" />
                                <FormControlLabel value="2" control={<Radio />} label="User" />
                                <FormControlLabel value="3" control={<Radio />} label="Viewer" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <FormControl>
                            <Typography sx={{ fontSize: 16, fontWeight: '500' }} id="edit-user-intely-connect-access">
                                Intely Connect Access
                            </Typography>
                            <RadioGroup
                                row
                                aria-labelledby="edit-user-intely-connect-access-label"
                                name="edit-user-intely-connect-access"
                                onChange={handleChangeConnectAccess}
                                value={organizationUserConnectApplications}
                            >
                                <FormControlLabel value="1" control={<Radio />} label="Admin" />
                                <FormControlLabel value="2" control={<Radio />} label="User" />
                                <FormControlLabel value="3" control={<Radio />} label="Viewer" />
                            </RadioGroup>
                        </FormControl>
                    </Grid> */}
                </Grid>
            </DialogContent>
            <Divider
                sx={{
                    background: '#BDBDBD',
                    width: '100%',
                    height: 2,
                }}
            />
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <LoadingButton
                    type="button"
                    variant="contained"
                    sx={{
                        mr: 2,
                        height: '40px',
                        '&.secondary': {
                            backgroundColor: '#E0E0E0',
                            color: '#333333',
                            boxShadow: 'none',
                        },
                        '&.secondary:hover': {
                            backgroundColor: '#828282',
                            color: '#333333',
                            boxShadow: 'none',
                        },
                    }}
                    className="secondary"
                    onClick={handleCloseModal}
                    disabled={disabled}
                >
                    {'Cancel'}
                </LoadingButton>

                <LoadingButton
                    type="button"
                    variant="contained"
                    sx={{
                        mr: 2,
                        height: '40px',
                        width: 110,
                        '&.MuiLoadingButton-root:hover': {
                            backgroundColor: '#333F99',
                            color: '#FFFFFF',
                            boxShadow: 'none',
                        },
                    }}
                    onClick={inviteOrganizationUser}
                    disabled={disabled}
                    loading={disabled}
                >
                    {'Invite'}
                </LoadingButton>
            </DialogActions>
        </DialogContainer>
    );
};

export default InviteOrganizationUserModal;
