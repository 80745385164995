import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListSubheader from '@mui/material/ListSubheader';

import { inputValueType } from '../../utils/typeUtils';

/**
 * Select field search implementation
 * @param {Object} args
 * @return {ReactElement}
 */
export const SelectFieldSearchMenuItem = ({ onSearch, placeholder = 'Search' }) => {
    const handleStopImmediatePropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const ignoreKeys = ['escape', 'enter', 'arrowup', 'arrowdown'];

    const handleStopPropagation = (e) => {
        const targetKey = e.key?.toLowerCase();
        if (!ignoreKeys.includes(targetKey)) {
            e.stopPropagation();
        }
    };

    const handleChange = (e) => {
        e.stopPropagation();
        onSearch(e.target.value);
    };

    const inputStyle = { height: '45px', maxWidth: '95%', fontSize: '14px' },
        horizontalLineStyle = { width: '100%', height: '0px', borderTop: '1px solid #cccccc80', margin: '15px 0px' },
        menuItemStyle = {
            flexDirection: 'column',
            pointerEvents: 'inherit',
            padding: '0px',
            opacity: 1,
            fontSize: '14px',
        },
        inputProps = {
            className: 'menuSearchInput',
        };

    return (
        <MenuItem
            disabled
            style={menuItemStyle}
            onKeyDown={handleStopPropagation}
            onClickCapture={handleStopImmediatePropagation}
        >
            <OutlinedInput
                fullWidth
                autoFocus
                type="text"
                size="medium"
                inputProps={inputProps}
                style={inputStyle}
                placeholder={placeholder}
                onKeyDown={handleStopPropagation}
                onChange={handleChange}
            />
            <div style={horizontalLineStyle}></div>
        </MenuItem>
    );
};
SelectFieldSearchMenuItem.propTypes = {
    onSearch: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
};

const SelectFieldSearchMenu = ({
    value,
    required,
    getItemId,
    getItemLabel,
    getItemValue,
    getItemFilterBy,
    items = [],
    menuStyles = {},
    menuHeight = '95vh',
    menuWidth = 650,
    itemIdProp = 'value',
    placeholder = 'Search',
    itemGroupBy = '',
    itemLabelProp = 'label',
    itemValueProp = 'value',
    itemFilterByProp = 'label',
    noItemsFoundText = '',
    backdropFilter = true,
    selectComponentProps = {},
    searchMenItemProps = {},
}) => {
    const [searchKeyword, setSearchKeyword] = useState(''),
        keyword = searchKeyword?.trim()?.toLowerCase(),
        fieldValue = selectComponentProps.value || value || '';

    const handleSetSearchKeyword = (keyword) => {
        setSearchKeyword(keyword);
    };

    const menuWidthLimit = useMemo(() => {
        const menuWidthLimit = typeof window !== 'undefined' ? window.innerWidth : menuWidth;
        return menuWidthLimit < menuWidth ? menuWidthLimit - 40 : menuWidth;
    }, [window?.innerWidth]);

    const inputStyle = {
            fontSize: '14px',
            borderColor: '#000',
            minWidth: '140px',
            // maxWidth: '225px',
            overflow: 'hidden',
            height: '40px',
            background: '#fff',
            color: '#000',
            marginRight: '14px',
            flexGrow: 1,
            fontWeight: 400,
        },
        menuStyle = {
            zIndex: 9999999,
            display: 'flex',
            justifyContent: 'center',
            '& .MuiPaper-root': {
                backgroundColor: '#fff',
                top: '16px',
                // transformOrigin: 'initial',
                height: menuHeight,
                width: menuWidthLimit,
                // left: 'initial !important',
                // right: 'initial !important',
                ...menuStyles,
            },
            '& .MuiBackdrop-root': {
                backgroundColor: backdropFilter ? 'rgba(111, 126, 140, 0.2)' : undefined,
                backdropFilter: backdropFilter ? 'blur(2px)' : undefined,
            },
            '& .MuiMenuItem-root': {
                paddingLeft: itemGroupBy ? '35px' : '',
                fontSize: '14px',
            },
            '& .MuiListSubheader-root': {
                color: '#444',
                fontSize: '14px',
                fontWeight: 600,
            },
        };

    const selectProps = {
        size: 'small',
        label: placeholder,
        autoWidth: false,
        autoFocus: false,
        ...selectComponentProps,
        MenuProps: {
            autoFocus: false,
            sx: menuStyle,
            // disableAutoFocusItem: true,
            onKeyDown: selectComponentProps?.MenuProps?.onKeyDown || undefined,
        },
        input: <OutlinedInput fullWidth autoFocus={false} style={inputStyle} size="medium" />,
        value: searchKeyword ? '' : fieldValue,
        onOpen: (props) => {
            selectComponentProps?.onOpen && selectComponentProps.onOpen(props);
        },
        onClose: (props) => {
            selectComponentProps?.onClose && selectComponentProps.onClose(props);
            handleSetSearchKeyword('');

            if (props?.target?.classList?.contains('MuiMenuItem-root')) {
                const dataValue = props?.target?.getAttribute('data-value');

                if (dataValue === '') {
                    selectComponentProps.onChange({
                        simulated: true,
                        target: {
                            name: selectComponentProps.name,
                            value: dataValue,
                        },
                    });
                }
            }
        },
    };

    const renderSearchMenuItem = (
        <SelectFieldSearchMenuItem onSearch={handleSetSearchKeyword} placeholder={placeholder} />
    );

    const itemsNotFoundText = noItemsFoundText || 'No items found',
        showItemFound = false,
        renderNoItemsFound = showItemFound && (
            <MenuItem autoFocus={false} value="" className="noItemFound">
                {itemsNotFoundText}
            </MenuItem>
        );

    const getItems = () => {
        const options = {};
        if (!itemGroupBy) return items;

        for (const item of items) {
            if (!options[item[itemGroupBy]]) {
                options[item[itemGroupBy]] = {
                    items: [],
                    title: item[itemGroupBy]?.toLowerCase(),
                };
            }

            options[item[itemGroupBy]].items.push(item);
        }

        return options;
    };

    const options = [],
        menuItems = getItems();

    let hasItem = true,
        wasSearchItemFound = false;

    if (!required) {
        options.push(
            <MenuItem key="optionNone" {...searchMenItemProps} value="">
                None
            </MenuItem>,
        );
    }

    /**
     * Get item label
     * @param {Object} item
     * @param {Number} index
     * @return {String}
     */
    const getFieldItemLabel = (item, index) => {
        if (getItemLabel) return getItemLabel(item, index);
        return item?.[itemLabelProp] || '';
    };

    /**
     * Get item value
     * @param {Object} item
     * @param {Number} index
     * @returns {String|Object}
     */
    const getFieldItemValue = (item, index) => {
        if (getItemValue) return getItemValue(item, index);
        return item?.[itemValueProp] || '';
    };

    /**
     * Get item ID
     * @param {Object} item
     * @param {Number} index
     * @return {String}
     */
    const getFieldItemId = (item, index) => {
        if (getItemId) return getItemId(item, index);
        return item?.[itemIdProp];
    };

    /**
     * Get item filter by
     * @param {Object} item
     * @return {String}
     */
    const getFieldItemFilterBy = (item) => {
        if (getItemFilterBy) return getItemFilterBy(item);
        return item[itemFilterByProp] || '';
    };

    /**
     * Render menu item
     * @param {Object} item
     * @param {Number} index
     */
    const renderMenuItem = (item, index) => {
        const itemLabel = getFieldItemLabel(item, index);

        // Skip first item if used to render empty value
        if (index === 0 && item?.value === '') return null;

        if (keyword) {
            const filterBy = (getFieldItemFilterBy(item) || itemLabel)?.toLowerCase() || '';
            if (filterBy?.indexOf(keyword) < 0) return null;
        }

        const itemValue = getFieldItemValue(item, index),
            itemId = getFieldItemId(item, index) || itemValue;

        options.push(
            <MenuItem key={itemId} value={itemValue} {...searchMenItemProps}>
                {itemLabel}
            </MenuItem>,
        );

        hasItem = true;
        wasSearchItemFound = true;
    };

    /**
     * Determine how to render the menu item base on menu grouping
     */
    if (itemGroupBy) {
        let counter = 0;
        for (const menuItem in menuItems) {
            options.push(<ListSubheader key={menuItem}>{menuItem}</ListSubheader>);
            hasItem = false;

            menuItems[menuItem].items?.forEach((item) => renderMenuItem(item, counter));

            if (!hasItem) {
                options.pop();
            }
            ++counter;
        }
    } else {
        items?.forEach((item, index) => renderMenuItem(item, index));
    }

    const noItemsPlaceholder = wasSearchItemFound ? null : renderNoItemsFound;

    return (
        <Select {...selectProps}>
            {renderSearchMenuItem}
            {options}
            {noItemsPlaceholder}
        </Select>
    );
};

export const searchMenuProps = {
    items: PropTypes.array,
    required: PropTypes.bool,
    menuWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    getItemId: PropTypes.func,
    menuHeight: PropTypes.string,
    menuStyles: PropTypes.object,
    itemIdProp: PropTypes.string,
    itemGroupBy: PropTypes.string,
    getItemLabel: PropTypes.func,
    getItemValue: PropTypes.func,
    itemLabelProp: PropTypes.string,
    itemValueProp: PropTypes.string,
    backdropFilter: PropTypes.bool,
    getItemFilterBy: PropTypes.func,
    noItemsFoundText: PropTypes.node,
    itemFilterByProp: PropTypes.string,
    searchMenItemProps: PropTypes.object,
    selectComponentProps: PropTypes.object,
};

SelectFieldSearchMenu.propTypes = {
    ...searchMenuProps,
    value: inputValueType,
    placeholder: PropTypes.string,
};

export default SelectFieldSearchMenu;
