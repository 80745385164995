import SelectField from '../SelectField';

const CompanySelectField = (props) => {
    const validate = {
        emptyLabel: 'Please select Default Organization.',
    };
    return (
        <SelectField
            placeholder="Your Default Organization"
            autoComplete="defaultOrganizationId"
            label="Default Organization"
            name={'defaultOrganizationId'}
            validate={validate}
            {...props}
        />
    );
};

export default CompanySelectField;
