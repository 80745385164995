import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

const FormIcon = (props) => {
    const fill = props.fill || 'white';

    return (
        <SvgIcon width="13" height="16" viewBox="0 0 13 16" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.18 12.6H9.62V11.4H3.18V12.6ZM3.18 9.2H9.62V8H3.18V9.2ZM1.2 16C0.88 16 0.6 15.88 0.36 15.64C0.12 15.4 0 15.12 0 14.8V1.2C0 0.88 0.12 0.6 0.36 0.36C0.6 0.12 0.88 0 1.2 0H8.42L12.8 4.38V14.8C12.8 15.12 12.68 15.4 12.44 15.64C12.2 15.88 11.92 16 11.6 16H1.2ZM7.82 4.92H11.6L7.82 1.2V4.92Z"
                fill={fill}
            />
        </SvgIcon>
    );
};

FormIcon.propTypes = {
    fill: PropTypes.string,
};

export default FormIcon;
