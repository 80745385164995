import { useState } from 'react';
import PropTypes from 'prop-types';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import SearchIcon from '@mui/icons-material/Search';

import styles from './styles';

const SearchBar = (props) => {
    const {
        value,
        onEnter,
        onSearch,
        onChange,
        placeholder,
        style: styleProps = {},
        sx: sxProps = {},
        endIcon: endIconOverride,
        endIconAction: endIconActionOverride,
    } = props;

    let searchValue, setSearchValue;

    if (!value) {
        [searchValue, setSearchValue] = useState('');
    }

    const handleOnChange = (e) => {
        if (onChange) {
            onChange(e.target.value);
        } else {
            setSearchValue(e.target.value);
        }
    };

    return (
        <OutlinedInput
            id="search-bar"
            placeholder={placeholder || 'Search'}
            onChange={handleOnChange}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onEnter(e.target.value);
                }
            }}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        aria-label="search"
                        onClick={() =>
                            endIconActionOverride !== undefined
                                ? endIconActionOverride(searchValue)
                                : onSearch(searchValue)
                        }
                    >
                        {endIconOverride || <SearchIcon style={{ height: '20px' }} />}
                    </IconButton>
                </InputAdornment>
            }
            value={value || searchValue}
            sx={{ ...styles.root, ...sxProps }}
            style={styleProps}
        />
    );
};

SearchBar.propTypes = {
    value: PropTypes.string,
    onEnter: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    style: PropTypes.object,
    sx: PropTypes.object,
    endIcon: PropTypes.element,
    endIconAction: PropTypes.func,
};

export default SearchBar;
