import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import DescriptionTooltip from '../components/Tooltips/DescriptionTooltip';
import ToggleTableContentButton from '../components/Buttons/ToggleTableContentButton';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

/**
 * Render table cell using callback
 * @param {object} args
 * @returns {React.ReactNode}
 */
const renderCell = ({ row, fieldName, renderCallback }) => {
    if (!renderCallback) {
        return row[fieldName] || '';
    }
    return renderCallback(row);
};

export const tableStyles = {
    title: {
        color: '#3261B7',
        fontSize: '13px',
        textDecoration: 'underline',
        lineHeight: '13px',
        cursor: 'pointer',
        transition: 'text-description 300ms',
        whitespace: 'initial',
    },
    titleState: {
        '&:hover': {
            textDecorationThickness: '1.5px !important',
        },
    },
    description: {
        fontSize: '13px',
        lineHeight: '15px',
        color: '#000',
        maxHeight: '100px',
        overflow: 'hidden',
        whiteSpace: 'initial',
    },
    actionButtonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        minWidth: 95,
        zIndex: 99999,
    },
    iconButton: {
        width: '30px',
        height: '30px',
        position: 'relative',
        // left: '-5px',
    },
    icon: {
        fontSize: '18px',
    },
    toggleTableBtn: {
        minWidth: '1px',
        // maxWidth: '1px',
    },
};

/**
 * Format the table date.
 * @param {String} date
 * @returns {String} Formatted table date.
 */
export const formatTableDataDate = (date) => (date ? dayjs(date).format('MM-DD-YYYY') : '—');

/**
 * Modified At table column data.
 * @param {Object} args
 * @param {String} args.fieldName
 * @returns {Object} Modified At table column data
 */
export const modifiedAtTableColumnData = ({ fieldName = 'updatedAt', ...props } = {}) => ({
    name: 'Modified At',
    fieldName,
    grow: 1.8,
    // minWidth: '128px',
    selector: (row) => row[fieldName] || '',
    sortable: true,
    cell: (row) => <Typography style={tableStyles.description}>{formatTableDataDate(row[fieldName])}</Typography>,
    ...props,
});

/**
 * Modified By table column data.
 * @see modifiedAtTableColumnData()
 * @returns {Object} Modified By table column data
 */
export const modifiedByTableColumnData = (props = {}) => ({
    name: 'Modified By',
    grow: 1.8,
    // minWidth: '128px',
    selector: (row) => row.modifierFirstName || row.modifierLastName || '',
    // Disabled temporarily as organization user name is not stored on row data
    sortable: false,
    cell: (row) => (
        <Typography style={tableStyles.description}>{`${row.modifierFirstName || ''} ${
            row.modifierLastName || ''
        }`}</Typography>
    ),
    ...props,
});

/**
 * Name table column data.
 * @param {Object} args
 * @param {String} args.fieldName
 * @param {Function} args.onClick
 * @returns {Object} Name table column data
 */
export const nameTableColumnData = ({ fieldName = 'name', onClick = null, renderCallback, ...props } = {}) => {
    return {
        name: 'Name',
        fieldName,
        selector: (row) => row[fieldName],
        grow: 2,
        sortable: true,
        ignoreRowClick: true,
        cell: (row, index) => {
            const renderTableCell = renderCell({ row, fieldName, renderCallback });

            return onClick ? (
                <Typography
                    component="div"
                    style={tableStyles.title}
                    sx={tableStyles.titleState}
                    onClick={() => onClick(row, index)}
                >
                    {renderTableCell}
                </Typography>
            ) : (
                <Typography component="div" style={tableStyles.description}>
                    {renderTableCell}
                </Typography>
            );
        },
        ...props,
    };
};

/**
 * Description table column data.
 * @param {Object} args
 * @param {String} args.fieldName
 * @returns {Object} Description table column data
 */
export const descriptionTableColumnData = ({
    fieldName = 'description',
    componentStyle = {},
    renderCallback,
    tooltipProps = {},
    ...props
} = {}) => {
    const style = { ...tableStyles.description, ...componentStyle };

    const renderDescription = (row) =>
        !tooltipProps || row?.[fieldName] === '' || row?.[fieldName] === undefined || row?.[fieldName] === null ? (
            renderCell({ row, fieldName, renderCallback })
        ) : (
            <DescriptionTooltip text={renderCell({ row, fieldName, renderCallback })} style={style} {...tooltipProps} />
        );

    return {
        name: 'Description',
        fieldName,
        selector: (row) => row[fieldName],
        grow: 3,
        sortable: false,
        cell: renderDescription,
        ...props,
    };
};

/**
 * Category table column data.
 * @param {Object} args
 * @param {String} args.fieldName
 * @returns {Object} Category table column data
 */
export const categoryTableColumnData = ({ fieldName = 'category', ...props } = {}) => ({
    name: 'Category',
    fieldName,
    selector: (row) => row[fieldName],
    grow: 2,
    sortable: true,
    cell: (row) => <Typography style={tableStyles.description}>{row[fieldName]}</Typography>,
    ...props,
});

/**
 * Boolean table column data.
 * @param {Object} args
 * @param {String} args.fieldName
 * @returns {Object} Boolean table column data
 */
export const booleanTableColumnData = ({
    fieldName = 'isEnabled',
    labelTrue = 'Yes',
    labelFalse = 'No',
    ...props
} = {}) => ({
    name: 'Enabled',
    fieldName,
    selector: (row) => row[fieldName],
    sortable: false,
    center: false,
    grow: 0.2,
    // maxWidth: '50px',
    cell: (row) => <Typography style={tableStyles.description}>{row[fieldName] ? labelTrue : labelFalse}</Typography>,
    ...props,
});

/**
 * Toggle table content button table column data.
 * @param {Object} args
 * @param {Object} args.toggleTableContentBtnRef
 * @param {Object} args.expandTableContentBtnRef
 * @returns {Object} Toggle table content button column data
 */
export const toggleTableContentButtonColumnData = ({
    toggleTableContentBtnRef,
    expandTableContentBtnRef,
    ...props
}) => {
    // Don't add toggle button if ref buttons are not defined
    if (!toggleTableContentBtnRef || !expandTableContentBtnRef)
        return {
            omit: true,
        };

    return {
        name: (
            <ToggleTableContentButton
                toggleTableContentBtnRef={toggleTableContentBtnRef}
                expandTableContentBtnRef={expandTableContentBtnRef}
            />
        ),
        selector: () => '',
        // grow: 0.2,
        ignoreRowClick: true,
        cell: () => <></>,
        style: tableStyles.toggleTableBtn,
        ...props,
    };
};

/**
 * Action button table column data.
 * @param {Object} args
 * @param {Function} args.onEdit
 * @param {Function} args.onInfo
 * @param {Function} args.onDelete
 * @param {Boolean} args.isDataEmpty
 * @param {Object} args.rootStyle
 * @param {Array<{sx: object, iconStyle: object, Icon: React.Component, render: ({ icon, index }: {icon: React.ReactElement, index: number}) => React.ReactElement, onClick: function}>} args.customButtons
 * @returns {Object} Action button table column data
 */
export const actionButtonTableColumnData = ({
    onDelete,
    onInfo,
    onEdit,
    toggleTableContentBtnRef,
    expandTableContentBtnRef,
    isDataEmpty = false,
    rootStyle = {},
    ...props
}) => {
    if (isDataEmpty) {
        return { omit: true };
    }

    const actionButtonWrapperStyle = { ...tableStyles.actionButtonWrapper, ...rootStyle };

    const hasToggleButton = toggleTableContentBtnRef && expandTableContentBtnRef;

    const renderToggleButton = hasToggleButton ? (
        <ToggleTableContentButton
            toggleTableContentBtnRef={toggleTableContentBtnRef}
            expandTableContentBtnRef={expandTableContentBtnRef}
        />
    ) : (
        ''
    );

    const hasCustomButtons = props?.customButtons?.length,
        editIconStyle = {
            ...tableStyles.iconButton,
            marginRight: onDelete || onInfo || hasCustomButtons ? '5px' : '0px',
        },
        deleteIconStyle = { ...tableStyles.iconButton, marginRight: onInfo || hasCustomButtons ? '5px' : '0px' },
        infoIconStyle = { ...tableStyles.iconButton, marginRight: onInfo || hasCustomButtons ? '5px' : '0px' };

    return {
        name: renderToggleButton,
        selector: (row) => row._id || '',
        ignoreRowClick: true,
        grow: 1.2,
        // minWidth: 'fit-content',
        cell: (row, index) => (
            <div style={actionButtonWrapperStyle}>
                {onEdit && (
                    <IconButton style={editIconStyle} onClick={() => onEdit(row, index)}>
                        <EditIcon style={tableStyles.icon} />
                    </IconButton>
                )}

                {onDelete && (
                    <IconButton style={deleteIconStyle} onClick={() => onDelete(row, index)}>
                        <DeleteIcon style={tableStyles.icon} />
                    </IconButton>
                )}

                {onInfo && (
                    <IconButton style={infoIconStyle} onClick={() => onInfo(row, index)}>
                        <InfoIcon style={tableStyles.icon} />
                    </IconButton>
                )}

                {hasCustomButtons
                    ? props?.customButtons?.map((customButton, i) => {
                          const customIconStyle = { ...tableStyles.icon, ...(customButton.iconStyle || {}) },
                              customIcon = (
                                  <IconButton
                                      key={i}
                                      style={infoIconStyle}
                                      sx={customButton.sx}
                                      onClick={() => customButton?.onClick(row, index)}
                                  >
                                      <customButton.Icon style={customIconStyle} />
                                  </IconButton>
                              );

                          return customButton?.render
                              ? customButton?.render({ icon: customIcon, index: i })
                              : customIcon;
                      })
                    : null}
            </div>
        ),
        ...props,
    };
};
