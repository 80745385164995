import EditOrganizationUserModal from '../Modals/EditOrganizationUserModal';
import InviteOrganizationUserModal from '../Modals/InviteOrganizationUserModal';

const OrganizationUsersModals = () => (
    <>
        <EditOrganizationUserModal />
        <InviteOrganizationUserModal />
    </>
);

export default OrganizationUsersModals;
