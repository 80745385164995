import { logError } from './envUtils';
import { authorizedAxiosInstance } from '../axiosInstances';
import { getCurrentOrganizationId } from './loginUtils';
import { showSnackBarErrorNotification, showSnackBarSuccessNotification } from './snackBarNotificationUtils';

const BASE_HEADER = {
    'Current-Organization-Id': getCurrentOrganizationId(),
};

/**
 * Get the request base URL.
 * Default: process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST.
 * Pass an absolute URL to override base URL. Must be `https`.
 * @param {String} url
 * @return {String} Request URL
 */
export const getRequestBaseUrl = (url) => {
    if (url.indexOf('https:') !== -1) return url;
    const normalizeUrlSlash = url.indexOf('/') === 0 ? '' : '/';
    return `${process.env.REACT_APP_INTELY_GATEWAY_EXTERNAL_HOST}${normalizeUrlSlash}${url}`;
};

/**
 * Request  headers
 * @param {Object} config
 * @return {Object} Request headers
 */
export const getRequestHeaders = (config = {}) => ({ headers: BASE_HEADER, ...config });

/**
 * Check if request was successful.
 * @param {{ status: Number }} request Request.
 * @return {Boolean} true if request was successful, false otherwise.
 */
export const isResponseSuccessful = (res) => String(res?.status || '')?.indexOf('20') === 0;

/**
 * Send get request
 * @param {Object} requestArgs
 * @param {String} requestType Request Type. 'get' | 'delete' | 'head' | 'options'.
 * @returns {Promise<any>} Promise
 */
export const sendGetRequest = (
    { url, successCallback, errorCallback, finallyCallback, data = {}, config = {} },
    requestType = 'get',
) => {
    const requestConfig = {
        params: data,
        ...config,
    };

    return authorizedAxiosInstance[requestType](getRequestBaseUrl(url), getRequestHeaders(requestConfig))
        .then((res) => successCallback(res))
        .catch((err) => errorCallback(err))
        .finally(finallyCallback);
};

/**
 * Send delete request
 * @param {Object} requestArgs
 * @returns {Promise<any>} Promise
 */
export const sendDeleteRequest = (requestArgs) => sendGetRequest(requestArgs, 'delete');

/**
 * Send post request
 * @param {Object} requestArgs
 * @param {String} requestType Request Type. 'post' | 'put' | 'patch'.
 * @returns {Promise<any>} Promise
 */
export const sendPostRequest = (
    { url, successCallback, errorCallback, finallyCallback, data = {}, config = {} },
    requestType = 'post',
) => {
    return authorizedAxiosInstance[requestType](getRequestBaseUrl(url), data, getRequestHeaders(config))
        .then((res) => successCallback(res))
        .catch((err) => errorCallback(err))
        .finally(finallyCallback);
};

/**
 * Send patch request
 * @param {Object} requestArgs
 * @returns {Promise<any>} Promise
 */
export const sendPatchRequest = (requestArgs) => sendPostRequest(requestArgs, 'patch');

/**
 * Send put request
 * @param {Object} requestArgs
 * @returns {Promise<any>} Promise
 */
export const sendPutRequest = (requestArgs) => sendPostRequest(requestArgs, 'put');

/**
 * Check if error code can be ignored
 * @param {Error} error
 * @param {Array<integer>} ignoreErrorCodes
 * @return {Boolean}
 */
export const canIgnoreErrorCodes = (error, ignoreErrorCodes = []) => {
    if (ignoreErrorCodes) {
        return ignoreErrorCodes.includes(error?.response?.status);
    }
    return false;
};

/**
 * Handle success response
 * @param {Object} args
 * @return {any}
 */
export const handleSuccessResponse = ({ response, successCallback, successMessage = null, errorMessage = null }) => {
    if (!isResponseSuccessful(response)) {
        errorMessage && showSnackBarErrorNotification(errorMessage);
        return null;
    }

    successMessage && showSnackBarSuccessNotification(successMessage);
    successCallback && successCallback(response);

    return response.data;
};

/**
 * Handle error response
 * @param {Object} args
 * @return {any}
 */
export const handleErrorResponse = ({ error, errorCallback, errorMessage = null, ignoreErrorCodes }) => {
    logError('handleErrorResponse: ', error, errorMessage);
    if (!canIgnoreErrorCodes(error, ignoreErrorCodes)) {
        // Use the error message from server if available
        const responseError = error?.response?.data;
        if (responseError && responseError?.isError && responseError?.error) {
            showSnackBarErrorNotification(responseError.message);
        } else {
            errorMessage && showSnackBarErrorNotification(errorMessage);
        }
    }
    errorCallback && errorCallback(error);
    return error;
};

/**
 * Process request response
 * @param {Object} args
 * @return {Object} Response success/error callbacks
 */
export const processResponse = ({
    errorMessage,
    successMessage,
    successCallback,
    errorCallback,
    finallyCallback,
    ignoreErrorCodes,
}) => {
    return {
        finallyCallback,
        successCallback: (response) =>
            handleSuccessResponse({
                response,
                errorMessage,
                successMessage,
                successCallback,
                ignoreErrorCodes,
            }),
        errorCallback: (error) =>
            handleErrorResponse({
                error,
                errorMessage,
                errorCallback,
                ignoreErrorCodes,
            }),
    };
};
