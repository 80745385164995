import PropTypes from 'prop-types';
import styles from './styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { getSelectedLogNameItem } from 'hooks/useOrganizationLoggingHook';

const RenderResponse = ({ view, data }) => {
    const selectedLogNameItem = getSelectedLogNameItem(),
        selectedLogType = selectedLogNameItem?.name,
        isApiLog = selectedLogType === 'api',
        isAppRequestLog = selectedLogType === 'app-request',
        isResponseView = view === 'response',
        isComparisonLog = selectedLogType === 'comparison';

    let responseFields = null,
        content = null;

    // Response
    if (isResponseView) {
        // Response fields for `app request log`
        if (isAppRequestLog) {
            responseFields = (
                <>
                    <Grid item xs={6} md={3}>
                        <Typography style={styles.heading}>App Name</Typography>
                        <Typography style={styles.data}>{data?.appName}</Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography style={styles.heading}>App Instance Name</Typography>
                        <Typography style={styles.data}>{data?.appInstanceName}</Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography style={styles.heading}>Interface Name</Typography>
                        <Typography style={styles.data}>{data?.interfaceName}</Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography style={styles.heading}>Resource Name</Typography>
                        <Typography style={styles.data}>{data?.resourceName}</Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography style={styles.heading}>Action Name</Typography>
                        <Typography style={styles.data}>{data?.actionName}</Typography>
                    </Grid>
                </>
            );
        } else if (isApiLog) {
            responseFields = (
                <>
                    <Grid item xs={6} md={3}>
                        <Typography style={styles.heading}>IP Address</Typography>
                        <Typography style={styles.data}>{data?.ipAddress}</Typography>
                    </Grid>
                </>
            );
        }
    }

    // Response
    if (view === 'response') {
        content = (
            <>
                {isApiLog && (
                    <>
                        <Grid item xs={12} md={12}>
                            <Typography style={styles.heading}>Route</Typography>
                            <Typography style={styles.data}>{data?.route}</Typography>
                        </Grid>
                    </>
                )}

                <Grid item xs={6} md={3}>
                    <Typography style={styles.heading}>Log ID</Typography>
                    <Typography style={styles.data}>{data?._id}</Typography>
                </Grid>

                <Grid item xs={6} md={3}>
                    <Typography style={styles.heading}>Organization ID</Typography>
                    <Typography style={styles.data}>{data?.organizationId}</Typography>
                </Grid>

                {data?.errorMessage && (
                    <Grid item xs={6} md={3}>
                        <Typography style={styles.heading}>Error Message</Typography>
                        <Typography style={styles.data}>{data?.errorMessage}</Typography>
                    </Grid>
                )}

                {responseFields}

                <Grid item padding={0} margin={0} xs={12} md={12}></Grid>

                {isComparisonLog && (
                    <>
                        <Grid item xs={6} md={3}>
                            <Typography style={styles.heading}>Comparison ID</Typography>
                            <Typography style={styles.data}>{data?.comparisonId}</Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Typography style={styles.heading}>Executed At</Typography>
                            <Typography style={styles.data}>{data?.executedAt}</Typography>
                        </Grid>
                    </>
                )}

                {(isApiLog || isAppRequestLog) && (
                    <>
                        <Grid item xs={6} md={3}>
                            <Typography style={styles.heading}>Start Time</Typography>
                            <Typography style={styles.data}>{data?.startTime}</Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Typography style={styles.heading}>End Time</Typography>
                            <Typography style={styles.data}>{data?.endTime}</Typography>
                        </Grid>
                    </>
                )}
            </>
        );
    }

    // Request
    if (view === 'request') {
    }

    return content;
};

RenderResponse.propTypes = {
    view: PropTypes.string,
    data: PropTypes.object,
};

export default RenderResponse;
