import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography } from '@mui/material';

import styles from './styles';
import AppUsageContainer from 'components/Containers/AppUsageContainer';
import { useOutletContext } from 'react-router-dom';

const DashboardContainer = () => {
    const { applicationAccess = {} } = useOutletContext(),
        { hasConnectAccess } = applicationAccess;

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div style={styles.contentWrapper}>{hasConnectAccess ? <AppUsageContainer /> : <></>}</div>

                <div style={{ ...styles.containerWrapper, zIndex: '1' }}>
                    <Box style={styles.dashboardComingSoonWrapper}></Box>
                    <Box style={styles.dashboardHelpWrapper}>
                        <Card style={styles.dashboardHelpContent}>
                            <Typography variant="h5" component="div" sx={styles.helpTitle}>
                                Need some help? Have an idea?
                            </Typography>
                            <Box sx={{ paddingTop: '11px' }}>
                                <Typography variant="body2" component="span" sx={styles.helpSubTitle}>
                                    Talk to a member of our Product Team
                                </Typography>
                            </Box>

                            <Box style={{ paddingTop: '13px' }}>
                                <LoadingButton
                                    type="button"
                                    variant="contained"
                                    sx={styles.bookMeetingButton}
                                    className="secondary"
                                    onClick={() =>
                                        window.location.assign(
                                            'https://calendly.com/intely-team/learn-how-intely-can-help-you?month=2022-11',
                                        )
                                    }
                                >
                                    {'Book a 1:1 Meeting'}
                                </LoadingButton>
                            </Box>
                        </Card>
                    </Box>
                </div>

                <Box sx={{ lineHeight: '0px', bottom: 0, position: 'absolute' }}>
                    <img
                        src={require('../../../assets/images/dashboard-bottom.png')}
                        alt="dashboard-bottom"
                        style={styles.dashboardBottomImage}
                    />
                    <Box sx={styles.footerBgColor}></Box>
                </Box>
            </div>
        </>
    );
};

export default DashboardContainer;
