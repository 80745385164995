import { useState, useRef, memo } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import FlexEndGrid from '../../Grid/FlexEndGrid';
import LoadingButton from '../../Buttons/LoadingButton';
import PasswordField from '../../InputFields/PasswordField';
import PasswordConfirmationField from '../../InputFields/PasswordConfirmationField';

import { updateUserPassword } from '../../../utils/userProfileUpdateUtils';
import { refreshUserToken } from '../../../utils/loginUtils';

import {
    resetDataRefObj,
    isInputFieldsReady,
    getInputFieldValues,
    setInputFieldValueCallback,
    clearInputFieldValueCallback,
} from '../../../utils/inputFieldUtils';

import {
    showSnackBarErrorNotification,
    showSnackBarSuccessNotification,
} from '../../../utils/snackBarNotificationUtils';
import { getCurrentUserId } from 'hooks/useUserHook';

const EditUserPassword = () => {
    const currentPasswordRef = useRef(''),
        newPasswordRef = useRef(''),
        confirmNewPasswordRef = useRef(''),
        passwordDataRefObj = useRef({
            newPassword: null,
            currentPassword: null,
            confirmPassword: null,
        }).current,
        [isEditingPassword, setIsEditingPassword] = useState(false),
        [isUpdatingPassword, setIsUpdatingPassword] = useState(false),
        inputFieldProps = {
            disabled: isUpdatingPassword,
            isSubmittingForm: isUpdatingPassword,
            actionCallback: getInputFieldValues(passwordDataRefObj, isEditingPassword),
            validationErrorCallback: clearInputFieldValueCallback(passwordDataRefObj),
            validationSuccessCallback: setInputFieldValueCallback(passwordDataRefObj),
        };

    /**
     * Handle password update
     */
    const handlePasswordUpdate = async () => {
        if (!isEditingPassword) {
            setIsEditingPassword(true);
            return;
        }

        setIsUpdatingPassword(true);

        // Lookup errors
        const isPasswordFieldValid = await isInputFieldsReady(passwordDataRefObj)();
        if (!isPasswordFieldValid) {
            setIsUpdatingPassword(false);
            return;
        }

        const newPassword = newPasswordRef.current.value,
            currentPassword = currentPasswordRef.current.value;

        // Update password
        updateUserPassword(newPassword, currentPassword)
            .then(async () => {
                setIsEditingPassword(false);
                resetDataRefObj(passwordDataRefObj);
                showSnackBarSuccessNotification('Password updated successfully');

                await refreshUserToken(getCurrentUserId(), { success: false, error: false });
            })
            .catch((error) => {
                showSnackBarErrorNotification(
                    error?.data?.message
                        ? error.data.message
                        : 'An error occurred attempting to change your password. Please verify you entered your current password correctly.',
                );
            })
            .finally(() => {
                setIsUpdatingPassword(false);
            });
    };

    /**
     * Validate new password uniqueness against current password.
     */
    const validateNewPasswordUniqueness = (value) => {
        if (value === currentPasswordRef.current.value) {
            return {
                error: true,
                helperText: 'New password should not be same as current password.',
            };
        }
        return true;
    };

    // Validation props
    const validateNewPasswordProp = {
        customValidator: validateNewPasswordUniqueness,
    };

    const renderEditPasswordFields = isEditingPassword && (
        <>
            <PasswordField
                {...inputFieldProps}
                name="currentPassword"
                inputRef={currentPasswordRef}
                passwordRegex={false}
                placeholder="Current Password"
            />

            <Grid item xs={12} sm={6}></Grid>

            <PasswordField
                {...inputFieldProps}
                name="newPassword"
                inputRef={newPasswordRef}
                label="New Password"
                placeholder="New Password"
                validate={validateNewPasswordProp}
            />

            <PasswordConfirmationField
                {...inputFieldProps}
                inputRef={confirmNewPasswordRef}
                passwordRef={newPasswordRef}
            />
        </>
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
                {isEditingPassword ? (
                    <Typography component="h1" variant="h5">
                        Change Password
                    </Typography>
                ) : (
                    <Box>
                        <Typography component="h1" variant="body1">
                            Change Password
                        </Typography>
                        <Typography component="h4" variant="body2">
                            {`It's a good idea to use a strong password that you're not using
                            elsewhere`}
                        </Typography>
                    </Box>
                )}
            </Grid>

            <FlexEndGrid gridProps={{ sm: 4 }}>
                <LoadingButton
                    onClick={handlePasswordUpdate}
                    loading={isUpdatingPassword}
                    label={isEditingPassword ? 'Update Password' : 'Change Password'}
                />
            </FlexEndGrid>

            {renderEditPasswordFields}
        </Grid>
    );
};

export default memo(EditUserPassword);
