import { useMemo, memo } from 'react';
import { PropTypes } from 'prop-types';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import CloseIcon from '@mui/icons-material/Close';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import VisibilityIcon from '@mui/icons-material/Visibility';

import styles from './styles';

import { convertScopeText } from '../../../utils/convertScopeUtils';
import { getDateString, isDateAfterToday } from '../../../utils/dateUtils';

const PersonalAccessTokensTable = (props) => {
    const { tokens, handleEditAccessToken, handleDeleteAccessToken } = props;

    const renderTableHeader = (
        <TableRow>
            <TableCell
                sx={{
                    ...styles.tableCellStyle.head,
                    width: '20%',
                }}
                align="left"
            >
                Token Name
            </TableCell>
            <TableCell
                sx={{
                    ...styles.tableCellStyle.head,
                    width: '40%',
                }}
                align="left"
            >
                Scopes
            </TableCell>
            <TableCell
                sx={{
                    ...styles.tableCellStyle.head,
                    width: '20%',
                }}
                align="left"
            >
                Expiration Date
            </TableCell>
            <TableCell
                sx={{
                    ...styles.tableCellStyle.head,
                    width: '20%',
                }}
                align="center"
            >
                Actions
            </TableCell>
        </TableRow>
    );

    /**
     * Tokens
     */
    const renderTokenData = useMemo(() => {
        return tokens.map((token) => {
            const hasDatePassed = isDateAfterToday(token.expiration),
                tableCellDateStyle = {
                    ...styles.tableCellStyle.body,
                    width: '20%',
                    color: hasDatePassed ? 'green' : 'red',
                    textDecoration: hasDatePassed ? 'none' : 'line-through',
                },
                tableCellScopeStyle = { ...styles.tableCellStyle.body, paddingBottom: 1 };

            return (
                <TableRow key={token._id} sx={styles.tableDataRow}>
                    <TableCell align="left" sx={styles.tableCellStyle.body}>
                        {token.note}
                    </TableCell>

                    <TableCell align="left" sx={tableCellScopeStyle}>
                        <Box sx={styles.scopeChipContainer}>
                            {token.scopes.map((scope) => (
                                <Chip sx={styles.scopeChips} key={scope} label={convertScopeText(scope)} />
                            ))}
                        </Box>
                    </TableCell>

                    <TableCell align="left" sx={tableCellDateStyle}>
                        {getDateString(token.expiration)}
                    </TableCell>

                    <TableCell align="center" sx={styles.tableCellStyle.body}>
                        <Box>
                            <IconButton onClick={handleEditAccessToken(token)}>
                                <VisibilityIcon />
                            </IconButton>
                            <IconButton onClick={handleDeleteAccessToken(token)}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </TableCell>
                </TableRow>
            );
        });
        /* eslint-disable */
    }, [tokens]);

    return (
        <TableContainer sx={styles.root}>
            <Table aria-label="personal access tokens">
                <TableHead>{renderTableHeader}</TableHead>
                <TableBody>{renderTokenData}</TableBody>
            </Table>
        </TableContainer>
    );
};

PersonalAccessTokensTable.propTypes = {
    tokens: PropTypes.array.isRequired,
    handleEditAccessToken: PropTypes.func.isRequired,
    handleDeleteAccessToken: PropTypes.func.isRequired,
};

export default memo(PersonalAccessTokensTable);
