export const ENVIRONMENT_LOCAL = 'local';
export const ENVIRONMENT_TEST = 'test';
export const ENVIRONMENT_DEVELOPMENT = 'dev';
export const ENVIRONMENT_STAGING = 'staging';
export const ENVIRONMENT_PRODUCTION = 'production';

export const DOMAIN_INTELY = 'intely.io';

export const IS_PRODUCTION = process.env.REACT_APP_CURRENT_ENVIRONMENT === ENVIRONMENT_PRODUCTION;
export const IS_DEVELOPMENT =
    process.env.REACT_APP_CURRENT_ENVIRONMENT === ENVIRONMENT_DEVELOPMENT ||
    process.env.REACT_APP_CURRENT_ENVIRONMENT === ENVIRONMENT_LOCAL;

let domainSuffix;
switch (process.env.REACT_APP_CURRENT_ENVIRONMENT) {
    case ENVIRONMENT_PRODUCTION:
        domainSuffix = DOMAIN_INTELY;
        break;
    case ENVIRONMENT_STAGING:
        domainSuffix = 'stg.' + DOMAIN_INTELY;
        break;
    case ENVIRONMENT_DEVELOPMENT:
        domainSuffix = 'dev.' + DOMAIN_INTELY;
        break;
    case ENVIRONMENT_TEST:
        domainSuffix = 'test.' + DOMAIN_INTELY;
        break;
    case ENVIRONMENT_LOCAL:
    default:
        domainSuffix = 'local.' + DOMAIN_INTELY;
        break;
}
export const CURRENT_DOMAIN_SUFFIX = domainSuffix;

/**
 * Determines how often(in ms) the front-end will validate user information with the back-end.
 * @type {number}
 */
export const SESSION_TIMEOUT_CHECK_INTERVAL = 30000;

/**
 * Determines how long at a minimum to wait before refreshing the user's session based on activity. If set to 600,000,
 * then the first activity after ten minutes will trigger a session refresh.
 * @type {number}
 */
export const SESSION_TOKEN_REFRESH_MINIMUM_WAIT_THRESHOLD = 600000; // 10 minutes

/**
 * Determines how close to session expiration the refresh popup will be displayed to a user. If set to 360,000, then user
 * will receive the popup six minutes before their session is terminated.
 * @type {number}
 */
export const SESSION_TOKEN_REFRESH_DISPLAY_POPUP_THRESHOLD = 360000; // 6 minutes

/**
 * Set to true if we want to refresh the users session based on activity.
 * @type {boolean}
 */
export const SESSION_TOKEN_REFRESH_ENABLE_ON_USER_ACTIVITY = true;

export const ORGANIZATION_USER_STATUS_ACTIVE = true;
export const ORGANIZATION_USER_STATUS_DISABLED = false;

export const ORGANIZATION_USER_STATUS_ADMIN = 1;
export const ORGANIZATION_USER_STATUS_VIEWER = 2;
export const ORGANIZATION_USER_STATUS_USER = 3;
/**
 * User profile image max file size in kilobytes
 */
export const USER_PROFILE_IMAGE_MAX_FILE_SIZE_KB = 1048;

/**
 * Regex pattern for values validation
 */
export const REGEX_PATTERNS = {
    name: /^[a-zA-Z ]+$/,
    phone: /^[0-9+]+$/i,
    password: /^(?=.*[\d])(?=.*[!@#$%^&*-.])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&.*-_]{8,}$/,

    /* eslint-disable-next-line */
    email: /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
};

/**
 * Auth form tabs
 */
export const AUTH_FORM_TABS = {
    login: 0,
    register: 1,
    resetPassword: 2,
    resendVerificationCode: 3,
    verifyCode: 4,
    'resetPassword:id:token': 5,
    mfa: 6,
    onboard: 7,
    resetExpiredPassword: 8,
};

/**
 * Months
 */
export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

/**
 * Access Token Scopes
 */
export const ACCESS_TOKEN_SCOPES = ['org.read', 'app.read', 'app.execute', 'integration.read', 'integration.execute'];

/**
 * Setting Menus
 */
export const ORGANIZATION = 'Organization';
export const ORGANIZATION_USER = 'Organization Users';
export const MY_PROFILE = 'My Profile';

export const SIDEBAR_WIDTH = 215;
export const SIDEBAR_COLLAPSE_WIDTH = 70;

export const HEADER_HEIGHT = 64;

export const DATE_FORMAT_WITH_TIME = 'MM/DD/YYYY h:mm:ss a';
