import { memo } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';

import styles from './styles';
import sidebarButtonStyles from './SidebarButton/styles';
import BlockMenus from './BlockMenus';
import ApplicationsMenu from './ApplicationsMenu';
import FeedbackSidebarButton from './FeedbackSidebarButton';
import useSidebarMenu, { setMenuStateCache } from 'hooks/useSidebarMenuHook';
import DescriptionTooltip from 'components/Tooltips/DescriptionTooltip';

const DashboardMainSidebar = () => {
    const sidebarMenu = useSidebarMenu(),
        collapseMenu = sidebarMenu.state.collapseMenu,
        menuButtonIconStyle = {
            ...styles.menuButtonIcon,
            fontSize: collapseMenu ? '24px' : '23px',
        },
        buttonStyle = {
            ...sidebarButtonStyles.root,
            padding: '5px 10px',
            height: '30px',
            '&:hover': { backgroundColor: 'inherit' },
        };

    const handleToggleMenu = () => {
        const isCollapsed = !sidebarMenu.state.collapseMenuState;
        setMenuStateCache(isCollapsed);
        sidebarMenu.setSidebarMenu({
            ...sidebarMenu.state,
            collapseMenuState: isCollapsed,
        });
    };

    const MenuIconType = collapseMenu ? KeyboardDoubleArrowRight : KeyboardDoubleArrowLeft;

    return (
        <div style={styles.root(!collapseMenu)}>
            <div style={styles.menuButton(collapseMenu)}>
                <DescriptionTooltip
                    text={
                        <IconButton onClick={handleToggleMenu}>
                            <MenuIconType sx={menuButtonIconStyle} />
                        </IconButton>
                    }
                    title={
                        <Button sx={buttonStyle} onClick={handleToggleMenu}>
                            <Typography component="span" sx={{ ...sidebarButtonStyles.text, fontSize: '14px' }}>
                                {collapseMenu ? 'Expand Menu' : 'Collapse Menu'}
                            </Typography>
                        </Button>
                    }
                    tooltipProps={{
                        placement: 'right',
                    }}
                    rootStyle={{
                        marginTop: 0,
                    }}
                />
            </div>

            <div style={{ flexGrow: 1, overflowY: 'auto', minHeight: '160px' }}>
                <BlockMenus collapseMenu={sidebarMenu.state.collapseMenu} />
            </div>
            <ApplicationsMenu sidebarMenu={sidebarMenu.state} />
            <FeedbackSidebarButton sidebarMenu={sidebarMenu.state} />
        </div>
    );
};

export default memo(DashboardMainSidebar);
