import { useRef, useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormHelperText from '@mui/material/FormHelperText';
import { MoonLoader } from 'react-spinners';

import CustomBootstrapDialog, { BootstrapModalTitle } from '../../BootstrapModal';

import styles from '../styles';

import {
    useNewFolderModalState,
    closeNewFolderModal,
    isNewFolderNameValid,
    getCurrentSelectedFolderPath,
    refreshSelectedFolder,
} from 'hooks/useMyFilesHook';
import { addNewFolder } from 'utils/intelyStorageUtils';
import { getCurrentOrganizationId } from 'utils/loginUtils';

const NewFolderModal = () => {
    const { open } = useNewFolderModalState();
    const inputRef = useRef();
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (open) {
            setError('');
            inputRef.current.value = '';
        }
    }, [open]);

    const handleClose = () => {
        closeNewFolderModal();
    };

    const handleCreate = () => {
        if (error) {
            return;
        }

        setIsLoading(true);
        const name = inputRef.current.value;

        if (!name?.length || name.includes('/')) {
            setError('Folder name cannot be empty or contain special characters');
            setIsLoading(false);
            return;
        }

        if (!isNewFolderNameValid(name)) {
            setError('Folder name already exists');
            setIsLoading(false);
            return;
        }

        const path = getCurrentSelectedFolderPath();
        addNewFolder(getCurrentOrganizationId(), path[0] === '/' ? path.slice(1) : path, name)
            .then(() => {
                refreshSelectedFolder();
                setIsLoading(false);
                closeNewFolderModal();
            })
            .catch((error) => {
                setError(error.response?.data?.error || 'An error occurred while creating the folder');
                setIsLoading(false);
            });
    };

    return (
        <CustomBootstrapDialog open={open} sx={styles.dialogRoot(500)}>
            <BootstrapModalTitle sx={styles.title} onClose={handleClose}>
                New Folder
            </BootstrapModalTitle>
            <DialogContent>
                <Typography sx={styles.inputLabel(error)}>
                    Enter your folder name <span style={{ color: 'red' }}>*</span>{' '}
                </Typography>
                <TextField
                    inputRef={inputRef}
                    autoFocus
                    id="name"
                    type="text"
                    fullWidth
                    sx={styles.input}
                    onChange={() => {
                        if (error) setError('');
                    }}
                    placeholder="Folder Name"
                    error={Boolean(error)}
                />
                {Boolean(error) && <FormHelperText error={Boolean(error)}>{error}</FormHelperText>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCreate} sx={styles.submit}>
                    {isLoading ? <MoonLoader size={15} color="white" /> : 'Create'}
                </Button>
            </DialogActions>
        </CustomBootstrapDialog>
    );
};

export default NewFolderModal;
