import store from '../redux/store';
import { useSelector } from 'react-redux';

/**
 * Set the active banner organization
 * @param {Object} organization
 */
export const setCurrentSelectedOrganization = (organization) => {
    store.dispatch({
        type: 'SET_CURRENT_SELECTED_ORGANIZATION',
        payload: organization,
    });
};

/**
 * Get selected banner organization. Also provides a function to update
 * the current selected organization.
 * @return {Array<object, Function>}
 */
const useSelectedOrganization = () => {
    const currentSelectedOrganization = useSelector((state) => state.currentOrganization);
    return [currentSelectedOrganization, setCurrentSelectedOrganization];
};

export default useSelectedOrganization;
