import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

const CommentIcon = (props) => {
    return (
        <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.59375 9.5H12.5V8.375H7.71875L6.59375 9.5ZM3.5 9.5H4.98125L9.70625 4.83125C9.78125 4.75625 9.81875 4.6625 9.81875 4.55C9.81875 4.4375 9.78125 4.34375 9.70625 4.26875L8.675 3.33125C8.6125 3.26875 8.53437 3.2375 8.44062 3.2375C8.34687 3.2375 8.26875 3.26875 8.20625 3.33125L3.5 8.1125V9.5ZM0.5 15.5V1.625C0.5 1.3375 0.6125 1.07813 0.8375 0.846875C1.0625 0.615625 1.325 0.5 1.625 0.5H14.375C14.6625 0.5 14.9219 0.615625 15.1531 0.846875C15.3844 1.07813 15.5 1.3375 15.5 1.625V11.375C15.5 11.6625 15.3844 11.9219 15.1531 12.1531C14.9219 12.3844 14.6625 12.5 14.375 12.5H3.5L0.5 15.5Z"
                fill="white"
            />
        </SvgIcon>
    );
};

CommentIcon.propTypes = {
    fill: PropTypes.string,
};

export default CommentIcon;
