import axios from 'axios';
import { logout } from './utils/loginUtils';
import { logError } from './utils/envUtils';

/**
 *
 * @param config
 * @returns {boolean}
 */
const isHandlerEnabled = (config = {}) => {
    return !(Object.prototype.hasOwnProperty.call(config, 'handlerEnabled') && !config.handlerEnabled);
};

/**
 * Global error handler.
 *
 * @param error
 * @returns {Promise<never>}
 */
const errorHandlerResponse = (error) => {
    if (isHandlerEnabled(error.config)) {
        logError(error);

        if (error.response && (error.response.status === 401 || error.response.status === 411)) {
            logout();
        }
    }

    return Promise.reject({ ...error });
};

/**
 * Global success handler.
 *
 * @param response
 * @returns {*}
 */
const successHandler = (response) => {
    if (isHandlerEnabled(response.config)) {
        // Handle responses
    }

    return response;
};

// TODO should be able to merge these now as the session cookie is automatically passed

/**
 *
 * Authorized axios setup - used for requests which required authenticated user
 *
 */
const authorizedAxiosInstance = axios.create({
    baseURL: '',
});

authorizedAxiosInstance.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandlerResponse(error),
);

authorizedAxiosInstance.defaults.withCredentials = true;

authorizedAxiosInstance.interceptors.request.use(function (config) {
    return config;
});

/**
 *
 * Unauthorized axios setup - used for requests which do not reuqire an authenticated user
 *
 */
const unauthorizedAxiosInstance = axios.create({
    baseURL: '',
});

unauthorizedAxiosInstance.defaults.withCredentials = true;

unauthorizedAxiosInstance.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandlerResponse(error),
);

export { unauthorizedAxiosInstance, authorizedAxiosInstance };
